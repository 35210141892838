import styled from "@emotion/styled";
import MKBox from "components/MKBox";

export const StyledIndexIcon = styled(MKBox)(({ theme }) => ({
    marginRight: "2rem",
    height: "5rem",
    width: "5rem",
    backgroundColor: theme.palette.white.main,
    borderRadius: "50%",
    display: 'flex',  
    alignItems: 'center',  
    justifyContent: 'center',
    borderTop: `1px solid ${theme.palette.white.main}`,
    borderLeft: `1px solid ${theme.palette.white.main}`,
    borderBottom: `3px solid ${theme.palette.accent.main}`,
    borderRight: `3px solid ${theme.palette.accent.main}`,
    boxShadow: `2px 2px 2px ${theme.palette.accent.main} !important`
}));