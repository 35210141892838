import React, { useContext } from "react";
// Components
import { Container } from "@mui/material";
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";
import CBLoading from "components/CBLoading";
import PageHeader from "components/CBPageHeader";
import SearchBarComponent from "components/CBSearchBar";
import Result from "./components/Result";

// Styled Components
import { StyledMainPageContainer, StyledSectionBackgroundWrapper } from "index.styles";
// Data
import blogSchemaData from "./schema.organization.json";
import seoData from "./seo.data.json";
import blogData from "./components/Result/blogsData.config.json";
// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";
import { SearchBar, SearchBarProvider } from "utils/context/searchBar.context";
import { filterBlogs } from "utils/helpers/searchBarFilters/blog.filter";

const Blog = () => {
  return (
    <SearchBarProvider initialData={blogData} filterFunction={filterBlogs}>
      <BlogContent />
    </SearchBarProvider>
  );
};

const BlogContent = () => {
  const { filteredData } = useContext(SearchBar);
  const schemaData = constructSchemaData(blogSchemaData.url, blogSchemaData.breadcrumbs);

  return (
    <StyledMainPageContainer>
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <StyledSectionBackgroundWrapper>
        <PageHeader
          header="The Psychedelic Therapy & Mental Health Blog"
          headerTagline="Discover and Learn More About the Power of Psychedelic Therapy"
          headerAccent={["Psychedelic Therapy", "Mental Health"]}
          lightText="true"
        />
      </StyledSectionBackgroundWrapper>
      <Container sx={{ marginTop: "2rem" }}>
        <SearchBarComponent placeholder="Search for blogs here..." color="#000" />
      </Container>
      {filteredData ? <Result filteredData={filteredData} /> : <CBLoading />}
    </StyledMainPageContainer>
  );
};

export default Blog;
