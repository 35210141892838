// Major
import React from "react";

// Components
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";
// Styled Components
import { StyledMainPageContainer } from "index.styles";
// Data
import informedConsentSchemaData from "./schema.organization.json";
import PageHeader from "components/CBPageHeader";
import PolicyContent from "components/CBPolicyContent";
import informedConsentData from "./informedConsent.data.json";
import seoData from "./seo.data.json";

// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";

const InformedConsentPage = () => {
  const schemaData = constructSchemaData(informedConsentSchemaData.url, informedConsentSchemaData.breadcrumbs);

  return (
    <StyledMainPageContainer id="informed-consent-page">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <PageHeader
        headerType="policy"
        section="informed-consent-policy"
        header="Informed Consent"
        headerAccent="Consent"
        dateModified="17 July, 2024"
      />
      <PolicyContent data={informedConsentData} section={"informed-consent-policy"} />
    </StyledMainPageContainer>
  );
};

export default InformedConsentPage;
