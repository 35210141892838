import { Stack } from "@mui/material";
import theme from "assets/theme";
import MKTypography from "components/MKTypography";
import React from "react";
import HeaderContainer from "styles/components/headerContainer";

const DefaultHeader = ({ lightText, header, headerAccent, headerTagline, headerTaglineFontSize }) => {
  return (
    <>
      <HeaderContainer
        textColor={lightText ? "#fff" : "#092860"}
        highlightColor="#75CDC5"
        element="h1"
        variant="h1"
        title={header}
        highlightText={headerAccent}
        cssProps={{ margin: "0rem 0rem 1rem 0rem !important" }}
        newLine={true}
      />
      <MKTypography
        sx={{
          minWidth: "800px",
          maxWidth: "800px",
          margin: "auto",
          color: lightText ? "#fff !important" : `${theme.palette.primary.main} !important`,
          fontSize: headerTaglineFontSize || "16px",
        }}
      >
        <em>{headerTagline}</em>
      </MKTypography>
    </>
  );
};

export default DefaultHeader;
