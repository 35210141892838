import React from "react";
import { StyledMainPageContainer } from "index.styles";
import Slider from "./components/Slider";
import ProductSection from "./components/productSection";
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";
// Data
import seoData from "./seo.data.json";
import shopSchemaData from "./schema.organization.json";
import { constructSchemaData } from "utils/helpers/constructSchemaData";

const Shop = () => {
  const schemaData = constructSchemaData(shopSchemaData.url, shopSchemaData.breadcrumbs);
  return (
    <StyledMainPageContainer id="shop-page">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <Slider />
      <ProductSection productId="prod_QhjbTCZqe6jIXZ" />
      <ProductSection productId="prod_Qhjdsp4kFmoJc6" reverse={true} darkTheme={true} />
    </StyledMainPageContainer>
  );
};

export default Shop;
