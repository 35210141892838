import React from "react";
import theme from "assets/theme";
// Styled Components
import { StyledPlanningContentSectionContainer } from "./index.styles";
import { StyledImage } from "index.styles";
import HeaderContainer from "styles/components/headerContainer";

// Components
import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";

const PlanningContent = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledPlanningContentSectionContainer>
      <Grid container spacing={isLg ? 8 : 0}>
        <Grid item xs={12} lg={6} order={0} mb={isLg ? 0 : 4}>
          <StyledImage
            height="416"
            width="514"
            src={"https://d3a0rs3velfrz.cloudfront.net/planningAhead/planning-ahead-section/planningAhead1.webp"}
            alt="Man on the cell phone planning his ketamine session."
          />
        </Grid>
        <Grid item xs={12} lg={6} order={1}>
          <HeaderContainer
            textColor={theme.palette.primary.main}
            highlightColor={theme.palette.accent.main}
            component="h2"
            variant="h2"
            title="Planning Ahead Is Key To A Successful Therapy Session"
            highlightText="Planning Ahead Is Key"
            cssProps={{ margin: "0 0 1rem 0" }}
          />
          <MKTypography component="p" sx={{ marginBottom: "2rem" }} variant={isLg ? "body1" : "body2"}>
            Ketamine-assisted psychotherapy is a potent treatment option that holds the potential for significant personal growth and healing in
            individuals grappling with mental health issues. If you are contemplating this therapeutic approach, taking proactive steps to prepare
            both mentally and physically for your ketamine-assisted psychotherapy sessions can greatly enhance their success and benefits. Here are
            some recommendations to help you feel at ease and ready for the transformative journey ahead.
          </MKTypography>
        </Grid>

        {/* Section 2 */}
        <Grid item xs={12} lg={6} order={isLg ? 3 : 2} mb={isLg ? 0 : 4}>
          <StyledImage
            height="410"
            width="514"
            src={"https://d3a0rs3velfrz.cloudfront.net/planningAhead/planning-ahead-section/planningAhead2.webp"}
            alt="Image of very colorful and healthy looking salads."
          />
        </Grid>
        <Grid item xs={12} lg={6} order={isLg ? 2 : 3}>
          <HeaderContainer
            textColor={theme.palette.primary.main}
            highlightColor={theme.palette.accent.main}
            component="h2"
            variant="h2"
            title="Health And Nutrition"
            highlightText="Health"
            cssProps={{ margin: "0 0 1rem 0" }}
          />
          <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
            Prioritize a clean, wholesome diet, steering clear of processed foods to enhance your overall well-being and amplify the effectiveness of
            ketamine treatment. Adopting healthy eating habits and maintaining physical activity in the days leading up to your session can contribute
            to your general wellness and positively influence the outcome of the therapy. Additionally, refrain from consuming alcohol or other
            stimulants at least 48 hours before your session, including other psychoactive substances like mushrooms or psilocybin, as well as
            stimulants such as marijuana/cannabis.
          </MKTypography>
        </Grid>
      </Grid>
    </StyledPlanningContentSectionContainer>
  );
};

export default PlanningContent;
