import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const fetchTherapistByName = async (therapistName) => {
  if (!therapistName) {
    return null;
  }
  const body = {"query": therapistName};
  
  const response = await axios.post(`https://1br067lqe8.execute-api.us-east-1.amazonaws.com/corsEnabled/ghl-contact-by-name`, body);
  const data = JSON.parse(response.data.body);
  return data;
}

const useFetchTherapistByName = (therapistName) => {
  
  
  return useQuery({
    queryKey: ['therapistBio', therapistName],
    queryFn: () => fetchTherapistByName(therapistName),
    staleTime: 30 * 60 * 1000, // 30 minutes
    cacheTime: 60 * 60 * 1000, // 1 hour
    refetchOnWindowFocus: false, // Do not refetch on window focus
  });

};

export default useFetchTherapistByName;