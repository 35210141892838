import React, { useState } from "react";
// Styled components
import { StyledMusicAndTherapyCardsContainer, StyledMusicAndTherapyPageHeaderSectionContainer, StyledMusicImage } from "./index.styles";
import { StyledMusicCard } from "index.styles";
// Components
import MKTypography from "components/MKTypography";
import { Link, List, ListItem, Stack } from "@mui/material";
import MKBox from "components/MKBox";
import CBBulletPoint from "components/CBBulletPoint";
import MKButton from "components/MKButton";
// Theme
import theme from "assets/theme";
// Data
import musicPlaylistData from "./musicPlaylistData.config.json";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
import rgba from "assets/theme/functions/rgba";
// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import HeaderContainer from "styles/components/headerContainer";
const Music = () => {
  const { isLg } = useMediaQueries();
  const [showAllPlaylists, setShowAllPlaylists] = useState(false);

  const handleShowMore = () => {
    setShowAllPlaylists(!showAllPlaylists);
  };

  return (
    <StyledMusicAndTherapyPageHeaderSectionContainer>
      <HeaderContainer
        textColor={theme.palette.primary.main}
        highlightColor={theme.palette.accent.main}
        component="h2"
        variant="h3"
        title="Here are some musical compilations \n that can be explored to use During a session."
        highlightText={["musical compilations", "during a session"]}
        cssProps={{
          textAlign: "center",
          borderBottom: `2px solid ${theme.palette.accent.main}`,
          paddingBottom: "1rem",
          height: "80px",
          marginBottom: "2rem",
        }}
      />
      {/*  */}
      <StyledMusicAndTherapyCardsContainer>
        {musicPlaylistData?.map(({ title, playlists, imageUrl }, index) => {
          const visiblePlaylists = showAllPlaylists ? playlists : playlists.slice(0, 2);
          return (
            <StyledMusicCard key={index} sx={{ display: "flex", flexWrap: "wrap" }}>
              <MKBox
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: "1rem",
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                }}
              >
                <StyledMusicImage component="img" height="100" width="100" src={imageUrl} alt={`${title} icon`} />
                <Stack>
                  <MKTypography component="h3" variant="h6" gutterBottom>
                    {title}
                  </MKTypography>
                  <List>
                    {visiblePlaylists?.map((playlist, playlistIndex) => {
                      const playlistTitle = Object.keys(playlist)[0];
                      const playlistLink = Object.values(playlist)[0];

                      return (
                        <ListItem>
                          <Stack key={playlistIndex} direction={"row"} alignItems={"center"}>
                            <MKBox>
                              <CBBulletPoint med="true" />
                            </MKBox>
                            <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
                              <Link
                                sx={{
                                  textDecoration: "none",
                                  "&:hover": {
                                    cursor: "pointer",
                                    transition: "all .3s ease-in-out",
                                    transform: "translateY(-4px)",
                                    color: `${rgba(theme.palette.accent.main, 1)} `,
                                  },
                                }}
                                href={playlistLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label={`${playlistTitle} - ${title} playlist`}
                              >
                                {playlistTitle}
                              </Link>
                            </MKTypography>
                          </Stack>
                        </ListItem>
                      );
                    })}
                  </List>
                </Stack>
              </MKBox>
              {playlists.length > 2 && (
                <MKButton
                  sx={{
                    margin: "auto",
                    fontSize: "0.875rem",
                    maxWidth: "150px",
                  }}
                  startIcon={showAllPlaylists ? <KeyboardArrowUpIcon></KeyboardArrowUpIcon> : <KeyboardArrowDownIcon></KeyboardArrowDownIcon>}
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={handleShowMore}
                  aria-label={showAllPlaylists ? "Show Less" : "Show More"}
                >
                  {showAllPlaylists ? "Show Less" : "Show More"}
                </MKButton>
              )}
            </StyledMusicCard>
          );
        })}
      </StyledMusicAndTherapyCardsContainer>
    </StyledMusicAndTherapyPageHeaderSectionContainer>
  );
};

export default Music;
