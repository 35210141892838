import { useState } from "react";
import axios from "axios";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

const useCreateStripeAccount = () => {
  const [stripeAccountLoading, setStripeAccountLoading] = useState(false);
  const [stripeAccountError, setStripeAccountError] = useState(null);
  const [stripeCustomerId, setStripeCustomerId] = useState(null); // this state isn't updating in time for sending to constructPurchaseData
  const stripe = useStripe();
  const elements = useElements();

  const createStripeAccount = async (payload) => {
    setStripeAccountLoading(true);
    setStripeAccountError(null);

    try {
      const cardElement = elements.getElement(CardElement);
      const { paymentMethod, error: paymentMethodError } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: payload.name,
          email: payload.email,
          address: payload.address,
        },
      });

      if (paymentMethodError) {
        throw new Error(paymentMethodError.message);
      }

      const response = await axios.post("https://lbealjhg80.execute-api.us-east-1.amazonaws.com/dev2/account", {
        ...payload,
        paymentMethodId: paymentMethod.id,
      });

      const parsedResponse = JSON.parse(response.data.body);
      setStripeCustomerId(parsedResponse.customerId);
      return parsedResponse.customerId;
    } catch (error) {
      setStripeAccountError(error.response?.data?.error?.message || error.message || "Failed to create Stripe account");
    } finally {
      setStripeAccountLoading(false);
    }
  };

  return {
    stripeAccountLoading,
    stripeAccountError,
    stripeCustomerId,
    createStripeAccount,
  };
};

export default useCreateStripeAccount;
