
import React from "react";
import { StyledThreeStackCard } from "./index.styles";


const ThreeStackCard = ({topComponent, midComponent, botComponent}) => {
  return (
    <StyledThreeStackCard>
            {topComponent}
            {midComponent}
            {botComponent}
    </StyledThreeStackCard>
  );
}

export default ThreeStackCard;