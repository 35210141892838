import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import theme from "assets/theme";
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import HeaderContainer from "styles/components/headerContainer";
import TAUSAMap from "components/TAUSAMap";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import useMediaQueries from "utils/mediaQueries.utils";

const StateMap = ({ ...props }) => {
  const { title, mapConfig, description } = props;
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useState(window.innerWidth - "2rem");

  const { isLg, isMd } = useMediaQueries();

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth - "2rem");
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowSize]);

  const stateCustomConfig = Object.entries(mapConfig).reduce((acc, [state, config]) => {
    acc[state] = {
      ...config,
      clickHandler: () => navigate(config.route),
    };
    return acc;
  }, {});

  return (
    <StyledSectionContainer sx={{ textAlign: "center" }}>
      <HeaderContainer
        title={title}
        textColor={theme.palette.primary.main}
        component="h2"
        variant="h1"
        highlightText="in Your State"
        highlightColor={theme.palette.accent.main}
      />
      <MKBox>
        <MKTypography variant="body1" element="p" sx={{ textAlign: "center" }}>
          {description}
        </MKTypography>
      </MKBox>
      <TAUSAMap stateCustomConfig={stateCustomConfig} height={isLg ? null : isMd ? 500 : 400} />
    </StyledSectionContainer>
  );
};

export default StateMap;
