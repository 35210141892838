import defaultOrganizationData from "../../components/CBSchema/schema.data.json";

export const constructSchemaData = (url, breadcrumbs, articleData = null, person, product = null) => {
  const organizationSchema = {
    type: "Organization",
    data: {
      ...defaultOrganizationData,
      url: url,
    },
  };

  const breadcrumbSchema = {
    type: "BreadcrumbList",
    data: breadcrumbs,
  };

  const personSchema = {
    type: "Person",
    data: {
      name: person?.name,
      url: `https://www.almadelic.com/therapist-bio/${person?.name?.replace(/\s+/g, "_")}_${person?.city?.replace(
        /\s+/g,
        "_"
      )}_${person?.state?.replace(/\s+/g, "_")}/${person?.id}`,
      address: {
        state: person?.state,
        city: person?.city,
        country: "US",
      },
      email: `mailto:${person?.email}`,
      telephone: person?.phone,
    },
  };

  const productSchema = {
    type: "Product",
    data: product,
  };

  const schemas = [organizationSchema, breadcrumbSchema];

  if (articleData) {
    schemas.push({ type: "Article", data: articleData });
  }
  if (person) {
    schemas.push(personSchema);
  }
  if (product) {
    schemas.push(productSchema);
  }

  return schemas;
};
