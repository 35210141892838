import React from 'react';
import {Grid} from "@mui/material";
import {FourSquareGridContainer} from "./index.styles";
import useMediaQueries from 'utils/mediaQueries.utils';
import { ImageContainer } from '../imageContainer';
// //////////////////////////////////////////////////
// pass in 4 grid items, divider color, and image url
// //////////////////////////////////////////////////
const FourSquareComponent = (children) => {
    const {isLg} = useMediaQueries();
    
    const VerticalDivider = () => {
        if(!isLg){
            return(
            <Grid container item xs={12} columnGap={20} sx={{justifyContent: "center", margin: "2rem 0rem"}}>
                <Grid item xs={10} sx={{border: `2px solid ${children.dividerColor}`}} />
            </Grid>
            )
        }
        return(
            <Grid item xs={0} sx={{border: `2px solid ${children.dividerColor}`}} />
        )
    }

    const HorizontalDivider = () => {
        if(!isLg){
            return(
            <Grid container item xs={12} columnGap={20} sx={{justifyContent: "center", margin: "2rem 0rem"}}>
                <Grid item xs={10} sx={{border: `2px solid ${children.dividerColor}`}} />
            </Grid>
            )
        }
        return(
            <Grid container item xs={12} columnGap={0} sx={{justifyContent: "center", alignItems:"center"}}>
                <Grid item xs={3} sx={{border: `2px solid ${children.dividerColor}`, height:"0"}} />
                <Grid item xs={3}>
                    <ImageContainer image={children.imageURL} alt="Almadelic Logo" cssProps={{height: "10rem", width:"auto"}} />
                </Grid>
                <Grid item xs={3} sx={{border: `2px solid ${children.dividerColor}`}} />
            </Grid>
        )
    }

    return(
        <FourSquareGridContainer container isLg={isLg}  columnGap={2} rowGap={0}>
                {children.component1}
            <VerticalDivider />
                {children.component2}
            <HorizontalDivider />
                {children.component3}
            <VerticalDivider />
                {children.component4}
        </FourSquareGridContainer>
    )
}


export default FourSquareComponent;