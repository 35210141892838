import { Container } from "@mui/material";
import MKButton from "components/MKButton";
import TAFilterMenu from "../TAFilterMenu";
import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
// Pass these values into the search filter
const filterMenuOptions = [
  {
    name: "state",
    label: "Sort by State",
    type: "select",
    options: [
      { value: "All", label: "All" },
      { value: "Alabama", label: "Alabama" },
      { value: "Alaska", label: "Alaska" },
      { value: "Arizona", label: "Arizona" },
      { value: "Arkansas", label: "Arkansas" },
      { value: "California", label: "California" },
      { value: "Colorado", label: "Colorado" },
      { value: "Connecticut", label: "Connecticut" },
      { value: "Delaware", label: "Delaware" },
      { value: "Florida", label: "Florida" },
      { value: "Georgia", label: "Georgia" },
      { value: "Hawaii", label: "Hawaii" },
      { value: "Idaho", label: "Idaho" },
      { value: "Illinois", label: "Illinois" },
      { value: "Indiana", label: "Indiana" },
      { value: "Iowa", label: "Iowa" },
      { value: "Kansas", label: "Kansas" },
      { value: "Kentucky", label: "Kentucky" },
      { value: "Louisiana", label: "Louisiana" },
      { value: "Maine", label: "Maine" },
      { value: "Maryland", label: "Maryland" },
      { value: "Massachusetts", label: "Massachusetts" },
      { value: "Michigan", label: "Michigan" },
      { value: "Minnesota", label: "Minnesota" },
      { value: "Mississippi", label: "Mississippi" },
      { value: "Missouri", label: "Missouri" },
      { value: "Montana", label: "Montana" },
      { value: "Nebraska", label: "Nebraska" },
      { value: "Nevada", label: "Nevada" },
      { value: "New Hampshire", label: "New Hampshire" },
      { value: "New Jersey", label: "New Jersey" },
      { value: "New Mexico", label: "New Mexico" },
      { value: "New York", label: "New York" },
      { value: "North Carolina", label: "North Carolina" },
      { value: "North Dakota", label: "North Dakota" },
      { value: "Ohio", label: "Ohio" },
      { value: "Oklahoma", label: "Oklahoma" },
      { value: "Oregon", label: "Oregon" },
      { value: "Pennsylvania", label: "Pennsylvania" },
      { value: "Rhode Island", label: "Rhode Island" },
      { value: "South Carolina", label: "South Carolina" },
      { value: "South Dakota", label: "South Dakota" },
      { value: "Tennessee", label: "Tennessee" },
      { value: "Texas", label: "Texas" },
      { value: "Utah", label: "Utah" },
      { value: "Vermont", label: "Vermont" },
      { value: "Virginia", label: "Virginia" },
      { value: "Washington", label: "Washington" },
      { value: "West Virginia", label: "West Virginia" },
      { value: "Wisconsin", label: "Wisconsin" },
      { value: "Wyoming", label: "Wyoming" },
    ],
    multi: true,
  },
  {
    name: "Specialties",
    label: "Sort by Specialty",
    type: "select",
    options: [
      { value: "All", label: "All" },
      { value: "Anxiety", label: "Anxiety" },
      { value: "PTSD", label: "PTSD" },
      { value: "Depression", label: "Depression" },
      { value: "OCD", label: "OCD" },
      { value: "Bi-polar", label: "Bi-polar" },
      { value: "Phobias", label: "Phobias" },
      { value: "Panic Disorders", label: "Panic Disorders" },
      { value: "Addictions", label: "Addictions" },
      { value: "Eating Disorders", label: "Eating Disorders" },
      { value: "Personality Disorders", label: "Personality Disorders" },
      { value: "Sexual Disorders", label: "Sexual Disorders" },
    ],
    multi: true,
  },
  {
    name: "Types Of Therapy",
    label: "Sort by Therapy Options",
    type: "select",
    multi: true,
    options: [
      { value: "All", label: "All" },
      { value: "Humanistic", label: "Humanistic" },
      { value: "Psychodynamic", label: "Psychodynamic" },
      { value: "Cognitive", label: "Cognitive" },
      { value: "Integrative/Holistic", label: "Integrative/Holistic" },
      { value: "Group", label: "Group" },
      { value: "Mindfulness", label: "Mindfulness" },
      { value: "Existential", label: "Existential" },
      { value: "Christian-Based", label: "Christian-Based" },
      { value: "Adlerian", label: "Adlerian" },
      { value: "Biodynamic", label: "Biodynamic" },
      { value: "Mentalization", label: "Mentalization" },
      { value: "Interpersonal", label: "Interpersonal" },
      { value: "Acceptance and Commitment", label: "Acceptance and Commitment" },
      { value: "Family", label: "Family" },
      { value: "Couples", label: "Couples" },
      { value: "Attachment", label: "Attachment" },
      { value: "Exposure", label: "Exposure" },
      { value: "Coherence", label: "Coherence" },
      { value: "Eye Movement Desensitization", label: "Eye Movement Desensitization" },
      { value: "Art", label: "Art" },
      { value: "Behavioral", label: "Behavioral" },
      { value: "Brain Stimulation", label: "Brain Stimulation" },
      { value: "Psychoanalysis", label: "Psychoanalysis" },
      { value: "Bibliotherapy", label: "Bibliotherapy" },
      { value: "Play", label: "Play" },
      { value: "Culturally Sensitive", label: "Culturally Sensitive" },
    ],
  },

  {
    name: "Treatment Methods",
    label: "Sort by Treatment Methods",
    type: "select",
    multi: true,
    options: [
      { value: "All", label: "All" },
      { value: "ACT", label: "ACT" },
      { value: "CBT", label: "CBT" },
      { value: "DBT", label: "DBT" },
      { value: "Ego State/Parts Work", label: "Ego State/Parts Work" },
      { value: "Supportive Psychotherapy", label: "Supportive Psychotherapy" },
      { value: "Psychodynamic Psychotherapy", label: "Psychodynamic Psychotherapy" },
      { value: "IFS", label: "IFS" },
      { value: "Other", label: "Other" },
    ],
  },
  {
    name: "languages",
    label: "Sort by Language",
    type: "select",
    options: [
      { value: "All", label: "All" },
      { value: "English", label: "English" },
      { value: "Spanish", label: "Spanish" },
      { value: "French", label: "French" },
    ],
    multi: true,
  },
  {
    name: "Visit Types",
    label: "Sort by Visit Types",
    type: "select",
    multi: true,
    options: [
      { value: "All", label: "All" },
      { value: "In Person", label: "In Person" },
      { value: "Virtual", label: "Virtual" },
    ],
  },
  // {
  //     name: "verified",
  //     label: "Verified Therapists",
  //     type: "select",
  //     options: [
  //         { value: "All", label: "All" },
  //         { value: "active directory therapist", label: "Verified" },
  //     ],
  //     multi: false,

  // },
];

const SearchFilter = () => {
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  return (
    <>
      <Container sx={{ width: "auto !important", margin: "0 !important", padding: "0 !important", position: "absolute", right: 0, top: 0 }}>
        <MKButton
          variant="contained"
          color="secondary"
          onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
          boxShadow="3"
          sx={{ height: "44.13px", borderRadius: ".2rem", padding: ".5rem 1rem!important" }}
        >
          {showAdvancedSearch ? (
            <>
              Filter By
              <ArrowDropUpIcon sx={{ height: "2rem", width: "2rem" }} />
            </>
          ) : (
            <>
              Filter By
              <ArrowDropDownIcon sx={{ height: "2rem", width: "2rem" }} />
            </>
          )}
        </MKButton>
      </Container>
      {showAdvancedSearch ? <TAFilterMenu setShowAdvancedSearch={setShowAdvancedSearch} filterMenuOptions={filterMenuOptions} /> : null}
    </>
  );
};

export default SearchFilter;
