import React from "react";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { ImageContainer } from "styles/components/imageContainer";




const SubtitleSection = ({ content, imageURL, imageAlt, cssProps }) => {
    return(
      <MKBox sx={{...cssProps}}> 
        {imageURL ? <ImageContainer image={imageURL} alt={imageAlt} /> : null}
        <MKTypography variant="h4" component="h4" sx={{ color: "#75CDC5", marginBottom: "1rem" }}>
          {content}
        </MKTypography>
      </MKBox>
    )
}

export default SubtitleSection;