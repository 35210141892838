import React from "react";
import { Grid, Stack, Container } from "@mui/material";

// Components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import ThreeStackCard from "styles/components/threeStackCard";
import { ImageContainer } from "styles/components/imageContainer";
import HeaderContainer from "styles/components/headerContainer";

// Utils
import useMediaQueries from "utils/mediaQueries.utils";

// Styles
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import theme from "assets/theme";
import { Link } from "react-router-dom";

const StartYourJourney = ({ ...props }) => {
  const { cards, cta } = props;
  const { isLg, isMd, isSm, isSmUp } = useMediaQueries();
  const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const Banner = () => {
    const tealOpaque = hexToRgba(theme.palette.accent.main, 1);
    const tealTransparent = hexToRgba(theme.palette.accent.main, 0);
    return (
      <Stack
        direction="row"
        sx={{
          background: `linear-gradient(to right, ${tealOpaque} 30%, ${tealTransparent} 90%)`,
          padding: "4rem 0rem 4rem 2rem",
        }}
      >
        {isSmUp ? (
          <MKBox sx={{ width: "75px", marginTop: "-15px" }}>
            <ImageContainer image="https://almadelic-assets.s3.us-east-2.amazonaws.com/logos/white_butterfly.webp" alt="Image of logo" />
          </MKBox>
        ) : null}
        <HeaderContainer
          title="Steps to Start Your Journey"
          component={"h2"}
          variant={"h1"}
          textColor={theme.palette.white.main}
          cssProps={{ margin: "0 !important" }}
        />
      </Stack>
    );
  };

  const ButtonContainer = () => {
    const { text, link } = cta;
    return (
      <MKBox sx={{ display: "flex" }}>
        <MKButton color="secondary" sx={{ padding: "0rem 2rem", margin: "2rem auto 0rem" }} component={Link} to={link}>
          {text}
        </MKButton>
      </MKBox>
    );
  };

  //   const cardInfo = [
  //     {
  //       description: "Schedula A Free Consultation",
  //       imageUrl: "https://almadelic-assets.s3.us-east-2.amazonaws.com/planningAhead/planning-ahead-section/new+image.webp",
  //       alt: "Image of a person scheduling a consultation",
  //     },
  //     {
  //       description: "Purchase Your Program. Your kit Quickly Ships Right To Your Door.",
  //       imageUrl: "https://d3a0rs3velfrz.cloudfront.net/ourProgram/whatToExpect2.webp",
  //       alt: "Image of a person receiving a package",
  //     },
  //     {
  //       description: "Expierence Ketamine Therapy",
  //       imageUrl: "https://d3a0rs3velfrz.cloudfront.net/ourProgram/whatToExpect3.webp",
  //       alt: "Image of a person receiving ketamine therapy",
  //     },
  //   ];

  const InfoCards = () => {
    const topComponent = (item) => {
      return (
        <Container sx={{ padding: "0rem !important", margin: "0rem !important" }}>
          <ImageContainer image={item.imageUrl} alt={item.alt} />
        </Container>
      );
    };

    const middleComponent = (item, index) => {
      return (
        <MKBox sx={{ margin: "0.5rem" }}>
          <MKTypography variant="h4" element="h4" sx={{ fontSize: "4rem" }}>
            {index + 1}
          </MKTypography>
        </MKBox>
      );
    };

    const bottomComponent = (item) => {
      return (
        <MKBox sx={{ margin: "0.5rem" }}>
          <MKTypography variant="h4" element="h4" sx={{ textAlign: "center" }}>
            {item.description}
          </MKTypography>
        </MKBox>
      );
    };

    return (
      <Grid
        container
        gap={4}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {cards.map((card, index) => (
          <Grid item xs={12} sm={7} md={5} lg={3} key={index} sx={{ minWidth: "250px !important", maxWidth: "350px !important" }}>
            <ThreeStackCard topComponent={topComponent(card)} midComponent={middleComponent(card, index)} botComponent={bottomComponent(card)} />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <>
      <Banner />
      <StyledSectionContainer id="ketamin-therapist-hub-start-your-journey-cards-container">
        <InfoCards />
        <ButtonContainer />
      </StyledSectionContainer>
    </>
  );
};

export default StartYourJourney;
