import styled from "@emotion/styled";
import { Container } from "@mui/material";
import theme from "assets/theme";
import MKBox from "components/MKBox";


export const StyledPageContainer = styled("main")(({ theme }) => ({
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    margin: "0 !important",
    padding: "0 !important",
}));