import React from "react";
import { useNavigate } from "react-router-dom";
// Components
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import ListComponent from "components/CBList";
import { Grid } from "@mui/material";
// Styled Components
import { StyledButtonContainer } from "index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
import { capitalizeFirstLetterOfEachWord } from "utils/helpers/capitalizeFirstLetterOfEachWord";
import { ImageContainer } from "styles/components/imageContainer";
import { TwoColumnSection } from "styles/components/twoColumnSection";
import HeaderContainer from "styles/components/headerContainer";
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";

const renderListItemData = (state) => {
  return [
    `Increase visibility with patients looking for ketamine providers in ${capitalizeFirstLetterOfEachWord(state)} & beyond`,
    "Share your background, specialties, and treatment approaches",
    "Control your availability for virtual appointments",
    "Receive inquiries directly from prospective patients",
  ];
};
const LeftComponent = () => {
  const { isLg, isMd } = useMediaQueries();
  return (
    <Grid item xs={12} lg={4} order={isLg ? 1 : 0}>
      <ImageContainer
        cssProps={{ height: isMd ? "455px" : "100%" }}
        image="https://d3a0rs3velfrz.cloudfront.net/findATherapist/areYouATherapist1.webp"
        alt="Licensed Therapist"
      />
    </Grid>
  );
};
const RightComponent = ({ currentState }) => {
  const { isLg } = useMediaQueries();
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/faq");
  };
  return (
    <Grid item xs={12} lg={7} order={isLg ? 0 : 1}>
      <HeaderContainer
        textColor="#1D3A6D"
        highlightColor="#75CDC5"
        component="h2"
        variant="h2"
        // Are you a...
        title={`Licensed Therapist in ${currentState}? Expand Your Practice with Almadelic.`}
        highlightText={"Expand Your Practice with Almadelic."}
        cssProps={{ margin: "1rem 0rem" }}
      />
      <MKTypography component="p" variant={isLg ? "body1" : "body2"} mb="1rem">
        Are you a {currentState} licensed mental health provider certified to administer ketamine therapy? If so, you can create a free profile on
        almadelic.com to reach more patients seeking ketamine treatments in {currentState}.
      </MKTypography>
      <ListComponent listItems={renderListItemData(currentState)} />
      <StyledButtonContainer>
        <MKButton onClick={handleNavigate} variant="contained" color="secondary">
          Learn More
        </MKButton>
      </StyledButtonContainer>
    </Grid>
  );
};

const ExpandYourPractice = ({ currentState }) => {
  const capitalizedState = capitalizeFirstLetterOfEachWord(currentState);

  return (
    <StyledSectionContainer id="expand-your-practice-section">
      <TwoColumnSection
        rowSpacing={0}
        columnSpacing={0}
        rowGap={6}
        columnGap={6}
        leftComponent={<LeftComponent />}
        rightComponent={<RightComponent currentState={capitalizedState} />}
      />
    </StyledSectionContainer>
  );
};

export default ExpandYourPractice;
