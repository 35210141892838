import React from "react";
import { Container, Grid } from "@mui/material";

import { TwoColumnSection } from "styles/components/twoColumnSection";
import HeaderContainer from "styles/components/headerContainer";
import { ImageContainer } from "styles/components/imageContainer";
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import ThreeStackCard from "styles/components/threeStackCard";
import CBDivider from "components/CBDivider";

import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import theme from "assets/theme";
import { Link } from "react-router-dom";
import useMediaQueries from "utils/mediaQueries.utils";


const WhyChooseKetamine = ({ content }) => {
  
  const { isLg } = useMediaQueries();
  if (!content) return null;
  const leftComponent = () => {
    return (
      <Grid item xs={12} md={12} lg={5} order={2}>
        <HeaderContainer
          component="h2"
          variant="h1"
          title={content.titleH2}
          textColor={theme.palette.white.main}
          highlightText={"Ketamine Therapy"}
          highlightColor={theme.palette.accent.main}
        />
        <MKTypography color="white" variant="body1" sx={{ margin: "2rem 0rem" }} element="p">
          {content.description}
        </MKTypography>
        {content.cta ? <>
          <MKButton variant="contained" color="secondary" size="large" component={Link} to={content.cta.link}>
            {content.cta.text}
          </MKButton>
          <MKTypography color="white" variant="body2" element="p" sx={{marginTop: "1rem"}}>
            (Just takes 2 minutes)
          </MKTypography>
        </> : null}
      </Grid>
    );
  };

  const rightComponent = () => {
    return (
      <Grid item xs={12} md={12} lg={6} order={1}>
        <ImageContainer image={content.image.src} alt={content.image.alt} cssProps={{
          height: isLg ? "483px" : "100%",
        }}/>
      </Grid>
    );
  };

  const Cards = () => {
    return content.titleH3.map((card, index) => {
      return (
        <Grid item xs={12} md={4} lg={3} key={index} sx={{
            padding: "1rem",
            textAlign: "center",
        }}>

          <ImageContainer image={card.image?.src} alt="alt-image" cssProps={{
            height: "100px",
            width: "100px",
            margin: "0 auto",
          }}/>
          <HeaderContainer component="h3" variant="h3" title={card.title} textColor={theme.palette.white.main} />
          <MKTypography color="white" variant="body1" element="p">
            {card.description}
          </MKTypography>
        </Grid>
      );
    });
  };

  return (
    <StyledSectionContainer id="ketamine-therapy-state-why-choose-us">
      <TwoColumnSection direction="row-reverse" leftComponent={leftComponent()} rightComponent={rightComponent()} columnGap={6} rowGap={4} />
      <Container sx={{ margin: "6rem 0rem", padding: "0rem" }}>
        <CBDivider />
      </Container>
      <Grid container columnGap={6} rowGap={4} sx={{ justifyContent: "center" }}>
        <Cards />
      </Grid>
    </StyledSectionContainer>
  );
};

export default WhyChooseKetamine;
