import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Components
import SEO from "components/CBSEO";
import PageHeader from "components/CBPageHeader";
import FAQContent from "components/CBFAQ";
import SchemaComponent from "components/CBSchema";
import PageFooter from "components/CBPageFooter";
import MoreAboutFaq from "./components/moreAboutFaqs";
import CBDivider from "components/CBDivider";
import CBLoading from "components/CBLoading";
// Styled Components
import { StyledMainPageContainer } from "index.styles";
import { StyledSectionBackgroundWrapper } from "index.styles";
import { StyledFaqContainer } from "index.styles";
// Data
import ketamineStateFaqContent from "./data/ketamineFaqContent.data.json";
import psilocybinStateFaqContent from "./data/psilocybinFaqContent.data.json";
// Utils
import { capitalizeFirstLetterOfEachWord } from "utils/helpers/capitalizeFirstLetterOfEachWord";
import { constructSchemaData } from "utils/helpers/constructSchemaData";
import Page404 from "pages/404";

const TherapyStateFaq = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [faqs, setFaqs] = useState("");
  const [state, setState] = useState("");
  const [containerId, setContainerId] = useState("ketamine-therapy-state-faq-container");
  const [schemaData, setSchemaData] = useState([]);
  const [stateInfo, setStateInfo] = useState(null);
  const capitalizedState = capitalizeFirstLetterOfEachWord(state);
  const [modalityName, setModalityName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // const foundState = stateFaqContent.find((stateObj) => location.pathname.includes(stateObj.state));
    const modalityData = location.pathname.includes("ketamine") ? ketamineStateFaqContent : psilocybinStateFaqContent;
    const modalityType = location.pathname.includes("ketamine") ? "Ketamine" : "Psilocybin";
    setModalityName(modalityType);
    const foundState = modalityData.find((stateObj) => location.pathname.includes(stateObj.state));
    if (foundState) {
      const stateName = foundState.state;
      
      const dynamicSchema = constructSchemaData(`https://www.almadelic.com${location.pathname}`, [
        { name: "Home", url: "/" },
        { name: `${modalityType} Therapy`, url: `/${modalityType.toLowerCase()}-therapy` },
        {
          name: `${modalityType} Therapy For ${capitalizedState}`,
          url: `/${modalityType.toLowerCase()}-therapy/${stateName}`,
        },
        {
          name: `Frequently Asked Questions About ${modalityType} Therapy For ${capitalizedState}`,
          url: `/${modalityType.toLowerCase()}-therapy/${stateName}/faq`,
        },
      ]);

      setFaqs(foundState);
      setContainerId(`${modalityType}-therapy-${stateName}-container`);
      setState(stateName);
      setSchemaData(dynamicSchema);
      setStateInfo(foundState);
      setIsLoading(false);
    } else {
      setFaqs(["No Faq for this state yet..."]);
      setStateInfo(null);
      setIsLoading(false);
    }
  }, [location.pathname]);
  if (isLoading) return <CBLoading />;
  if (!stateInfo) return <Page404 />;
  return (
    <StyledMainPageContainer id={containerId}>
      <SchemaComponent schemas={schemaData} />
      <SEO
        title={`FAQs on ${modalityName} Therapy in ${capitalizedState} | Almadelic `}
        description={`Get answers to common questions about ${modalityName}-assisted therapy in ${capitalizedState}. Learn about the process, benefits, ${capitalizedState} specific regulations, and how to start your at home treatment with Almadelic.`}
        canonicalUrl={`https://www.almadelic.com/${modalityName.toLowerCase()}-therapy/${state}/faq`}
      />
      {/* Page Header - section #1 */}
      <StyledSectionBackgroundWrapper>
        <PageHeader
          section={`${capitalizedState}-faq`}
          header={`Frequently Asked Questions About \\n ${modalityName} Therapy in ${capitalizedState}`}
          headerAccent={`${modalityName} Therapy in ${capitalizedState}`}
          br="true"
          lightText="true"
          headerTagline={`FAQs for ${modalityName} therapy in ${capitalizedState}. Here, you'll find answers to common questions about at-home ${modalityName}-assisted therapy. 
`}
        />
      </StyledSectionBackgroundWrapper>
      {/* Faq Section - section #2 */}
      <StyledFaqContainer>
        <FAQContent items={faqs} />
      </StyledFaqContainer>
      <CBDivider />
      {/* Section #3 */}
      <MoreAboutFaq />
      {/* Section #4 */}
      <PageFooter content="Didn't find what you were looking for?" to="/contact-us" />
    </StyledMainPageContainer>
  );
};

export default TherapyStateFaq;
