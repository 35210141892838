import React from "react";
// Components
import { List, ListItem, Stack } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import CBBulletPoint from "components/CBBulletPoint";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
import { replaceKeywordWithLink } from "utils/helpers/replaceKeywordWithLink";

const ListComponentContent = ({ children, index, listItemSpacing, listType }) => {
  const { isLg } = useMediaQueries();
  return (
    <MKBox
      key={index}
      component={ListItem}
      disableGutters
      width={"auto"}
      padding={0}
      sx={{ alignItems: "start", marginBottom: listItemSpacing ? listItemSpacing : ".5rem" }}
    >
      {!listType && <MKBox sx={{ marginTop: ".4rem" }}>{isLg ? <CBBulletPoint med="true" /> : <CBBulletPoint small="true" />}</MKBox>}
      {children}
    </MKBox>
  );
};
const ListItemContent = ({ item, color }) => {
  return (
    <MKTypography component="p" variant={"body2"} sx={{ color: color ? `${color} !important` : "" }}>
      {item}
    </MKTypography>
  );
};
const ListComponent = ({ listItems, color, listItemSpacing, titleColor, listType }) => {
  const { isLg } = useMediaQueries();
  return (
    <List>
      {listItems.map((item, index) => {
        if (typeof item === "string") {
          return (
            <ListComponentContent index={index} listItemSpacing={listItemSpacing} listType={listType}>
              <ListItemContent item={item} color={color} index={index} listItemSpacing={listItemSpacing} />
            </ListComponentContent>
          );
        } else if (typeof item === "object" && item?.content) {
          let updatedContent = item?.content;
          if (item?.embeddedLink) {
            const { url, keyWord } = item?.embeddedLink;
            updatedContent = replaceKeywordWithLink(item.content, keyWord, url);
          }
          return (
            <ListComponentContent index={index} listItemSpacing={listItemSpacing} listType={listType}>
              {item?.title ? (
                <Stack mb="1rem">
                  <MKTypography sx={{ color: titleColor && `${titleColor} !important` }} component="h6" variant="h6" gutterBottom>
                    {item?.title}
                  </MKTypography>
                  {Array.isArray(updatedContent) && listType === "faq" ? (
                    updatedContent.map((contentItem, idx) => (
                      <Stack direction="row">
                        <MKBox sx={{ marginTop: ".4rem" }}>{isLg ? <CBBulletPoint med="true" /> : <CBBulletPoint small="true" />}</MKBox>
                        <ListItemContent key={idx} item={contentItem} color={color} />
                      </Stack>
                    ))
                  ) : (
                    <ListItemContent item={updatedContent || item} color={color} />
                  )}
                </Stack>
              ) : (
                <ListItemContent item={updatedContent || item} color={color} />
              )}
            </ListComponentContent>
          );
        }
        return null;
      })}
    </List>
  );
};

export default ListComponent;
