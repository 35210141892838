// Major
import React from "react";
import { Container, Grid } from "@mui/material";

// Components
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import HeaderContainer from "styles/components/headerContainer";

// Styles
import theme from "assets/theme";
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import { StyledGridContainer, StyledItemBox } from "./index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";

const MutualBenefits = ({ ...props }) => {
  const { title, cards } = props;
  const { isLg } = useMediaQueries();

  //   const cardsz = [
  //     {
  //       title: "How Ketamine Assist's Mental Health",
  //       descriptionCard: ["Rapid Symtom Relief", "Treatment-Resistant Depression", "Neuroplasticity Enhancement"],
  //     },
  //     {
  //       title: "The Mutual Benefits",
  //       descriptionCard: [
  //         "Faster Symtom Relief with Long-Term Coping Skills",
  //         "Address Both Symptoms and Root Causes",
  //         "Personalized Care with Emotional Support",
  //       ],
  //     },
  //     {
  //       title: "How Traditional Therapy Assist's In Mental Health",
  //       descriptionCard: ["Long-Term Skill Building", "Addressing Root Causes", "Emotional Support & Guidance"],
  //     },
  //   ];

  const Sections = ({ card, index }) => {
    return (
      <Container
        sx={{
          borderRight: index === 1 ? "1px solid white" : null,
          borderLeft: index === 1 ? "1px solid white" : null,
        }}
      >
        <MKBox>
          <MKTypography variant="h4" element="h4" align="center" sx={{ color: `${theme.palette.white.main} !important` }}>
            {card.title}
          </MKTypography>
        </MKBox>
        {card.descriptionCard.map((description, index) => {
          return <IndividualItem key={index} description={description} orange={false} />;
        })}
      </Container>
    );
  };

  const generateRBGColor = (hexColor) => {
    let r = parseInt(hexColor.slice(1, 3), 16);
    let g = parseInt(hexColor.slice(3, 5), 16);
    let b = parseInt(hexColor.slice(5, 7), 16);
    return `rgb(${r}, ${g}, ${b}, 1.0)`;
  };

  const IndividualItem = ({ description }) => {
    return (
      <StyledItemBox
        sx={{
          backgroundColor: `${generateRBGColor(theme.palette.primary.main)}`,
        }}
      >
        <MKTypography
          variant="p"
          element="h3"
          align="center"
          sx={{
            color: `${theme.palette.white.main} !important`,
          }}
        >
          {description}
        </MKTypography>
      </StyledItemBox>
    );
  };

  return (
    <>
      <StyledSectionContainer id="ketamine-therapy-hub-mutual-benefits">
        <HeaderContainer
          component="h2"
          variant="h1"
          cssProps={{ textAlign: "center", marginTop: "0rem !important" }}
          title={title}
          textColor={theme.palette.white.main}
          highlightText="Benefits"
          highlightColor={theme.palette.accent.main}
        />
        <StyledGridContainer
          container
          sx={{
            display: isLg ? "flex" : "block",
          }}
        >
          {cards.map((card, index) => {
            return (
              <Grid item container xs={12} sm={12} md={12} lg={4}>
                <Sections card={card} index={index} />
              </Grid>
            );
          })}
        </StyledGridContainer>
      </StyledSectionContainer>
    </>
  );
};

export default MutualBenefits;
