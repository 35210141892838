export const capitalizeFirstLetterOfEachWord = (arr) => {
  if (typeof arr === "string") {
    arr = [arr];
  }
  return arr.map((string) =>
    string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
  );
};
