import React from "react";
import { Grid, Stack, Container } from "@mui/material";

import HeaderContainer from "styles/components/headerContainer";
import { ImageContainer } from "styles/components/imageContainer";
import { TwoColumnSection } from "styles/components/twoColumnSection";
import { StyledSectionContainer, StyledSectionContainerWhiteWrapper } from "styles/components/SectionContainer/index.styles";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import theme from "assets/theme";
import { Link } from "react-router-dom";
import useMediaQueries from "utils/mediaQueries.utils";

const LearnMore = ({ content }) => {

  const { isLg, isMd, isSm, isSmUp } = useMediaQueries();


  const rightComponent = () => {
    return (
      <Grid item xs={12} md={12} lg={6}>
        <HeaderContainer title={content.titleH2} textColor={theme.palette.primary.main} variant="h1" component="h2" />
        <MKTypography variant="body1" color="textPrimary" paragraph>
          {content.description}
        </MKTypography>
        {content.cta ? <>
          <MKButton variant="contained" color="secondary" component={Link}  to={content.cta.link} >
            {content.cta.text}
          </MKButton>
        </> : null}
      </Grid>
    );
  };

  const leftComponent = () => {
    return (
      <Grid item xs={12} md={12} lg={5}>
        <ImageContainer image={content.image.src} alt={content.image.alt} />
      </Grid>
    );
  };

  return (
    <StyledSectionContainerWhiteWrapper>
      <StyledSectionContainer id="ketamine-therapy-hub-learn-more">
        <TwoColumnSection columnGap={6} rowGap={2} rightComponent={rightComponent()} leftComponent={leftComponent()} direction="row" />
      </StyledSectionContainer>
    </StyledSectionContainerWhiteWrapper>
  );
};

export default LearnMore;
