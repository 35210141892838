import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import React from "react";
import { Link } from "react-router-dom";

import { StyledAllCoursesCardGrid, StyledAllCoursesDivider, StyledAllCoursesSectionContainer } from "./index.style";
import { CourseCardsData } from "./CourseCards/courseCards.data";
import CourseCard from "./CourseCards/courseCard";

export default function AllCourses() {
  const CourseCardsDataMid = Math.ceil(CourseCardsData.length / 2);
  return (
    <StyledAllCoursesSectionContainer>
      <Grid>
        <Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <MKTypography variant={"h2"} component="h2" gutterBottom>
              All Courses
            </MKTypography>
            <MKButton
              component={Link}
              to={"must-fill-in"}
              variant="contained"
              color="secondary"
              size="large"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click to Learn more about these courses
            </MKButton>
          </Grid>
          <StyledAllCoursesDivider />
        </Grid>
        <StyledAllCoursesCardGrid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            {CourseCardsData.slice(0, CourseCardsDataMid).map(({ title, content }, index) => {
              return <CourseCard key={index} title={title} content={content} />;
            })}
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            {CourseCardsData.slice(CourseCardsDataMid).map(({ title, content }, index) => {
              return <CourseCard key={index} title={title} content={content} />;
            })}
          </Grid>
        </StyledAllCoursesCardGrid>
      </Grid>
    </StyledAllCoursesSectionContainer>
  );
}
