import styled from "@emotion/styled";
import { Grid } from "@mui/material";


export const StyledGridContainer = styled(Grid)(({ theme, isLg }) => ({
    position: "relative",
    flexDirection: "row",
    justifyContent: "center",
    ...(isLg ? {
        '&::before': {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url("https://almadelic-assets.s3.us-east-2.amazonaws.com/logos/almadelicButterFlyTransparent.webp")`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "20%",
            zIndex: 1,
        }
    } : {}),
}));

