import React from "react";
import { StyledSectionContainer } from "styles/components";
import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { ImageContainer } from "styles/components/imageContainer";
import { TwoColumnSection } from "styles/components/twoColumnSection";
import theme from "assets/theme";
import HeaderContainer from "styles/components/headerContainer";
import { Link } from "react-router-dom";

const Packages = ({ ...props }) => {
  const { leftColumn, rightColumn } = props;

  const rightContent = () => {
    const { image } = rightColumn;
    return (
      <Grid item xs={12} md={12} lg={5} order={1}>
        <ImageContainer image={image.imageUrl} alt={image.alt} />
      </Grid>
    );
  };

  const leftContent = () => {
    const { title, description, cta } = leftColumn;
    return (
      <Grid item xs={12} md={12} lg={6} order={2}>
        <HeaderContainer title={title} variant="h2" component="h1" textColor={theme.palette.primary.main} />
        <MKTypography variant="body1" color="black" fontWeight={400} gutterBottom sx={{ padding: "1rem 0rem !important" }}>
          {description}
        </MKTypography>
        <MKButton variant="contained" color="secondary" size="large" component={Link} to={cta.link}>
          {cta.text}
        </MKButton>
      </Grid>
    );
  };

  return (
    <StyledSectionContainer id="ketamine-therapy-packages">
      <TwoColumnSection leftComponent={leftContent()} rightComponent={rightContent()} columnGap={6} rowGap={2} direction={"row-reverse"} />
    </StyledSectionContainer>
  );
};

export default Packages;
