// Major
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

//  Components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import PageHeader from "components/CBPageHeader";

// Utils
import { signInAuthWithEmailAndPassword } from "utils/firebase/authentication.firebase";
import useMediaQueries from "utils/mediaQueries.utils";

// Styles
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import theme from "assets/theme";
import { StyledButtonContainer } from "index.styles";
import { StyledSectionContainer } from "styles/components";

const SignInForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .trim()
      .email("Please enter a valid email address")
      .matches(
        /^[^\s@]+@[^\s@]+\.(com|org|net|edu|gov|mil|info|biz|co|me|io|tech|online|us|uk|ca|au|ac|health|med|doctor|academy|institute|college|law|consulting|clinic|usp\.br)$/,
        "Email must end with valid domain suffixes."
      )
      .required("Email is required"),
    password: yup.string().trim().min(7, "Password must be at least 7 characters long").required("Password is required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    try {
      await signInAuthWithEmailAndPassword(values.email, values.password);
      resetForm();
      navigate("/therapist-bio-editable");
    } catch (error) {
      switch (error.code) {
        case "auth/wrong-password":
          alert("Incorrect Password!");
          break;
        case "auth/user-not-found":
          alert("Sorry, no user found!");
          break;
        case "auth/invalid-login-credentials":
          alert("Please recheck your email/password!");
          break;
        default:
          alert(error);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const { isLg } = useMediaQueries();
  return (
    <StyledSectionContainer sx={{ paddingTop: "0 !important" }}>
      <PageHeader
        textAlign={"center"}
        textColor={"primary"}
        header={"Almadelic Therapist Profile Log In"}
        headerAccent={"Profile Log In"}
        headerTagline={
          "Welcome back! After signing in, you'll be directed to your profile bio page. There, you can update your information for our therapist directory. This ensures potential clients see your most current details and expertise."
        }
      />
      <MKBox
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{
          "& .MuiOutlinedInput-input": {
            color: "black !important",
            background: theme.palette.background.paper,
            padding: 2,
          },
          background: "rgba(255 ,255, 255, .3)",
          boxShadow: `rgba(0,0,0, .1) 0px 6px 12px`,
          backdropFilter: "blur(8px)",
          borderRadius: "8px",
          padding: "32px 16px",
        }}
      >
        <MKTypography component="label" for="email" variant="h6" color="text.primary" fontWeight={700} gutterBottom>
          E-mail
        </MKTypography>
        <TextField
          id="email"
          placeholder="Enter Your email address..."
          variant="outlined"
          size="medium"
          name="email"
          fullWidth
          type="email"
          aria-label="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          sx={{ marginBottom: "1rem" }}
        />
        <MKTypography component="label" for="password" variant="h6" color="text.primary" fontWeight={700} gutterBottom>
          Password
        </MKTypography>
        <TextField
          id="password"
          placeholder="Enter your password"
          variant="outlined"
          size="medium"
          name="password"
          aria-label="password"
          fullWidth
          type={showPassword ? "text" : "password"}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            '& input[type="password"]::-ms-reveal': {
              display: "none",
            },
          }}
        />
        <Stack direction={isLg ? "row" : "column"} alignItems={"center"} justifyContent={"space-between"} mt="3rem">
          <MKBox>
            <MKTypography component="p" variant={"body2"}>
              Don't Have An Account?{" "}
              <Link style={{ color: theme.palette.accent.main, textDecoration: "none" }} to="/partners/prospect-survey" aria-label="Sign up link">
                Sign Up Here
              </Link>
            </MKTypography>
            <MKTypography component="p" variant={"body2"}>
              Forgot Your Password?{" "}
              <Link style={{ color: theme.palette.accent.main, textDecoration: "none" }} to="/reset-password" aria-label="Reset password link">
                Reset Here
              </Link>
            </MKTypography>
          </MKBox>
          <StyledButtonContainer>
            <MKButton variant="contained" type="submit" color="secondary" size="large" aria-label="Log in button">
              Log In
            </MKButton>
          </StyledButtonContainer>
        </Stack>
      </MKBox>
    </StyledSectionContainer>
  );
};

export default SignInForm;
