import React, { useContext, useEffect, useState } from "react";

import CBLoading from "components/CBLoading";

import { StyledBioBodyContainer } from "./index.styles";
import useFetchTherapistByID from "utils/hooks/useFetchTherapistByID";
import { UserContext } from "utils/context/user.context";
import AboutMeEdit from "./components/AboutMeEdit";
import HeaderEdit from "./components/HeaderEdit";
import TherapyServicesEdit from "./components/TherapistServicesEdit";
import CBDivider from "components/CBDivider";
import ProfessionalInfoEdit from "./components/ProfessionalInfo";
import CostAndInsuranceEdit from "./components/CostAndInsurance";
import { StyledMainPageContainer } from "index.styles";
import SEO from "components/CBSEO";

const TherapistBioEditable = () => {
  const { currentUserProfile, setCurrentUserProfile } = useContext(UserContext);
  const therapistID = currentUserProfile.crmContactId;
  const { data: therapistBio, isLoading, isError, error } = useFetchTherapistByID(therapistID);
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    if (therapistBio) {
      setUserData(therapistBio.contact);
    }
  }, [therapistBio]);

  const handleUpdateUserData = (newUserData) => {
    if (!newUserData) return;
    setUserData(newUserData);
  };

  const PageBody = () => {
    return (
      <>
        <SEO title={userData?.name} description={`Learn more about ${userData?.name} on their Bio page Here!`} />
        <HeaderEdit userData={userData} handleUpdateUserData={handleUpdateUserData} />
        <StyledBioBodyContainer>
          <AboutMeEdit userData={userData} handleUpdateUserData={handleUpdateUserData} />
          <CBDivider />
          <TherapyServicesEdit userData={userData} handleUpdateUserData={handleUpdateUserData} />
          <CBDivider />
          <ProfessionalInfoEdit userData={userData} handleUpdateUserData={handleUpdateUserData} />
          <CBDivider />
          <CostAndInsuranceEdit userData={userData} handleUpdateUserData={handleUpdateUserData} />
        </StyledBioBodyContainer>
      </>
    );
  };

  return <StyledMainPageContainer id="therapist-bio-editable-page">{isLoading ? <CBLoading /> : <PageBody />}</StyledMainPageContainer>;
};

export default TherapistBioEditable;
