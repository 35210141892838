import React, { useContext, useState, useMemo, useEffect } from "react";
import { Alert, Grid, List, ListItem, Stack } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import { StyledProductDetailsSectionContainer } from "./index.styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { StyledButtonContainer } from "index.styles";
import BreadCrumbs from "../bread-crumbs";
import { CartContext } from "utils/context/cart.context";
import EllipsisText from "components/CBEllipsisText";
import { parseIncludes } from "utils/helpers/parseIncludesMetadata";

const ProductDetails = ({ products }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const { addItemToCart, cartItems } = useContext(CartContext);
  const productId = location.state?.productId;
  
  const product = useMemo(() => {
    if (!products) return null;

    if (productId) {
      return products.find((product) => product.id === productId);
    } else {
      const urlSlug = location.pathname.split("/").pop();
      const slugWords = urlSlug.split("-").map((word) => word.toLowerCase());
      let bestMatch = null;
      let highestMatchPercentage = 0;
      products.forEach((product) => {
        const productNameWords = product.name
          .toLowerCase()
          .split(/\W+/)
          .filter((word) => word.length > 0);

        const matchCount = slugWords.reduce((count, slugWord) => {
          return productNameWords.some((nameWord) => nameWord.includes(slugWord) || slugWord.includes(nameWord)) ? count + 1 : count;
        }, 0);

        const matchPercentage = (matchCount / slugWords.length) * 100;

        if (matchPercentage > highestMatchPercentage) {
          highestMatchPercentage = matchPercentage;
          bestMatch = product;
        }
      });

      return highestMatchPercentage > 50 ? bestMatch : null;
    }
  }, [products, productId, location.pathname]);

  const includes = useMemo(() => {
    if (!product?.metadata) return [];
    return parseIncludes(product.metadata);
  }, [product]);

  const packageInCart = useMemo(() => {
    return cartItems.find((cartItem) => cartItem.metadata?.type === "package");
  }, [cartItems]);

  const addOnInCart = useMemo(() => {
    return cartItems.find((cartItem) => cartItem.metadata?.type === "addon" && cartItem.id === product.id);
  }, [cartItems, product]);

  useEffect(() => {
    if (product?.metadata?.type === "package" && packageInCart) {
      setAlertMessage("This item or another package is already added to your cart.");
      setShowAlert(true);
    } else if (product?.metadata?.type === "addon" && addOnInCart) {
      setAlertMessage("This add-on is already added to your cart.");
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [packageInCart, addOnInCart, product?.metadata?.type]);

  const displayedItems = showMore ? includes : includes.slice(0, 3);

  const toggleShowMore = () => {
    setShowMore((prevShowMore) => !prevShowMore);
  };

  const handleAddToCart = () => {
    if (product.metadata?.type === "package" && packageInCart) {
      setAlertMessage("This item or another package is already added to your cart.");
      setShowAlert(true);
    } else if (product.metadata?.type === "addon" && addOnInCart) {
      setAlertMessage("This add-on is already added to your cart.");
      setShowAlert(true);
    } else {
      addItemToCart(product);
      // !!!!!!!!!!!!!!!!!!!!!
      // navigate("/cart", { state: { previousUrl: `${location.pathname}?productId=${productId}` } });
      navigate("/cart", { state: { previousUrl: `${location.pathname}`, productId: productId } });
      // /product?productId=${e.currentTarget.id}
    }
  };

  if (!product) return <Alert severity="warning">Product not found</Alert>;
  
  return (
    <StyledProductDetailsSectionContainer id="product-details-page">
      <BreadCrumbs packageName={`${product?.name}`} />
      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <MKBox
            component="img"
            sx={{ width: "100%", marginTop: ".5rem !important" }}
            src={`${product?.images[0]}`}
            alt={`${product?.name} product image`}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <MKTypography component="h1" variant="h3" gutterBottom>
            {product.name}
          </MKTypography>
          <MKTypography component="p" variant="body2" sx={{ fontWeight: "bold" }} gutterBottom>
            {product.metadata.priceValue}
          </MKTypography>
          <MKTypography component="p" variant="body2" sx={{ marginBottom: "2rem" }}>
            {product.metadata.long_description}
          </MKTypography>
          <MKTypography component="h3" variant="h6" gutterBottom>
            {product.metadata.includesTitle}
          </MKTypography>
          {displayedItems && (
            <List>
              {displayedItems.map((item, index) => (
                <ListItem key={index}>
                  <Stack direction={"row"} alignItems={"start"} spacing={2} sx={{ marginBottom: 1 }}>
                    <MKBox
                      component="img"
                      sx={{ width: "24px", marginTop: ".5rem !important" }}
                      src="https://shop.almadelic.com/wp-content/uploads/2024/03/wbsite-icon.png"
                      alt="Almadelic butterfly bullet point icon"
                    />
                    {displayedItems.length === 1 || index !== displayedItems.length - 1 ? (
                      <MKTypography component="p" variant="body2">
                        {item}
                      </MKTypography>
                    ) : (
                      <EllipsisText text={item} productDetails={"true"} onReadMore={toggleShowMore} showMore={showMore} />
                    )}
                  </Stack>
                </ListItem>
              ))}
            </List>
          )}

          <StyledButtonContainer>
            {showAlert && (
              <Alert severity="warning" onClose={() => setShowAlert(false)} sx={{ marginBottom: "1rem" }}>
                <MKTypography component="span" sx={{ fontSize: "13px" }}>
                  {alertMessage}
                  <br />
                  <Link to={"/cart"} state={{ productId: product.id, previousUrl: location.pathname }}>
                    {" "}
                    View Cart
                  </Link>
                </MKTypography>
              </Alert>
            )}
            <MKButton onClick={handleAddToCart} variant="contained" color="secondary" sx={{ margin: "auto" }}>
              Add To Cart
            </MKButton>
          </StyledButtonContainer>
        </Grid>
      </Grid>
    </StyledProductDetailsSectionContainer>
  );
};

export default ProductDetails;
