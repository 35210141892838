import React from "react";
import { useLocation } from "react-router-dom";

// Components
import SEO from "components/CBSEO";
import SchemaComponent from "components/CBSchema";
import StartYourJourney from "./components/startYourJourney";
import ResourcesSlider from "./components/resourcesSlider";
import Packages from "./components/packages";
import CBDivider from "components/CBDivider";
import ScienceBehind from "./components/ScienceBehind";
import StateMap from "./components/StateMap";
import KeyMechanics from "./components/KeyMechanics";
import MutualBenefits from "./components/MutualBenefits";
import ClinicalResearch from "./components/ClinicalResearch";
import GetStartedToday from "./components/GetStartedToday";
import Header from "./components/Header";
import WaitList from "./components/Waitlist";
// Styled Components
import { StyledSectionContainerColorWrapper } from "styles/components/SectionContainer/index.styles";
// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";
// Data
// a. Schema
import ketamineTherapySeoData from "./data/seoData/ketamineTherapySeo.data.json";
import psilocybinTherapySeoData from "./data/seoData/psilocybinTherapySeo.data.json";
// a. Seo
import ketamineSchemaData from "./data/schemaData/ketamineTherapySchema.data.json";
import psilocybinSchemaData from "./data/schemaData/psilocybinSchema.data.json";
// a. page content
import ketamineTherapyContent from "./data/pageContentData/ketamineTherapyContent.data.json";
import MOCK_psilocybinTherapyContent from "./data/pageContentData/psilocybinTherapyContent.data.json";
import { StyledPageContainer } from "styles/components/PageContainer/index.styles";

// Mapping data to modalityType to render the content, seo, and schema data or components for each modality
const therapyHubContentMap = {
  "ketamine-therapy": ketamineTherapyContent,
  "psilocybin-therapy": MOCK_psilocybinTherapyContent,
};
const therapyHubSeoDataMap = {
  "ketamine-therapy": ketamineTherapySeoData,
  "psilocybin-therapy": psilocybinTherapySeoData,
};

const therapyHubSchemaDataMap = {
  "ketamine-therapy": ketamineSchemaData,
  "psilocybin-therapy": psilocybinSchemaData,
};

const componentMap = {
  "Header": Header,
  "StartYourJourney": StartYourJourney,
  "ResourcesSlider": ResourcesSlider,
  "Packages": Packages,
  "Divider": CBDivider,
  "ScienceBehind": ScienceBehind,
  "StateMap": StateMap,
  "KeyMechanics": KeyMechanics,
  "MutualBenefits": MutualBenefits,
  "ClinicalResearch": ClinicalResearch,
  "GetStartedToday": GetStartedToday,
  "Waitlist": WaitList,
};

const TherapyHub = () => {
  const location = useLocation();
  const modalityType = location.pathname.split("/")[1];
  const schemaData = constructSchemaData(therapyHubSchemaDataMap[modalityType].url, therapyHubSchemaDataMap[modalityType].breadcrumbs);

  const content = therapyHubContentMap[modalityType];

  const groupedComponents = [];
  let backgroundComponents = [];

  content.forEach(({ section }, index) => {
    const Component = componentMap[section?.componentType];
    const props = { ...section };
    const componentElement = <Component key={index} {...props} />;

    if (section.colorWrapper) {
      backgroundComponents.push(componentElement);
    } else {
      if (backgroundComponents.length > 0) {
        groupedComponents.push(<StyledSectionContainerColorWrapper key={`bg-${index}`}>{backgroundComponents}</StyledSectionContainerColorWrapper>);
        backgroundComponents = [];
      }
      groupedComponents.push(componentElement);
    }
  });

  if (backgroundComponents.length > 0) {
    groupedComponents.push(<StyledSectionContainerColorWrapper key="bg-end">{backgroundComponents}</StyledSectionContainerColorWrapper>);
  }

  return (
    <StyledPageContainer id={`${modalityType}-therapy-hub-page-container`}>
      <SEO
        title={therapyHubSeoDataMap[modalityType].title}
        description={therapyHubSeoDataMap[modalityType].description}
        canonicalUrl={therapyHubSeoDataMap[modalityType].canonicalUrl}
      />
      <SchemaComponent schemas={schemaData} />
      
      {groupedComponents}
    </StyledPageContainer>
  );
};

export default TherapyHub;
