import * as Yup from "yup";

export const initialValues = {
  billingCountry: "US",
  shippingCountry: "US",
  shippingState: "",
  email: "",
  emailStripe: "",
  billingFirstName: "",
  billingLastName: "",
  billingAddress: "",
  billingCity: "",
  billingState: "",
  billingZipCode: "",
  billingPhone: "",
  shippingFirstName: "",
  shippingLastName: "",
  shippingAddress: "",
  shippingCity: "",
  shippingZipCode: "",
  shippingPhone: "",
};

export const validationSchema = Yup.object({
  showBillingAddressFormFields: Yup.boolean(),
  email: Yup.string()
    .required("Email is required")
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Email is not valid"),
  shippingFirstName: Yup.string().required("First name is required"),
  shippingLastName: Yup.string().required("Last name is required"),
  shippingAddress: Yup.string().required("Shipping address is required"),
  shippingCountry: Yup.string().required("Shipping country is required"),
  shippingCity: Yup.string().required("Shipping city is required"),
  shippingState: Yup.string()
    .oneOf(["Colorado", "Ohio", "Florida"], "We are currently only able to serve CO, OH, and FL")
    .required("Shipping state is required"),
  shippingZipCode: Yup.string()
    .required("Shipping zip code is required")
    .matches(/^\d{5}(-\d{4})?$/, "Zip code is not valid"),
  shippingPhone: Yup.string()
    .required("Phone number is required")
    .matches(/^(\+?1[-\s]?)?(\(?\d{3}\)?[-\s]?)?\d{3}[-\s]?\d{4}$/, "Phone number is not valid"),

  billingFirstName: Yup.string(),
  billingLastName: Yup.string(),
  billingAddress: Yup.string(),
  billingCountry: Yup.string(),
  billingCity: Yup.string(),
  billingState: Yup.string(),
  billingZipCode: Yup.string().matches(/^\d{5}(-\d{4})?$/, "Zip code is not valid"),
  billingPhone: Yup.string().matches(/^(\+?1[-\s]?)?(\(?\d{3}\)?[-\s]?)?\d{3}[-\s]?\d{4}$/, "Phone number is not valid"),
});

export const validationSchema2 = Yup.object({
  showBillingAddressFormFields: Yup.boolean(),
  email: Yup.string()
    .required("Required")
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Email is not valid"),
  shippingFirstName: Yup.string().required("Required"),
  shippingLastName: Yup.string().required("Required"),
  shippingAddress: Yup.string().required("Required"),
  shippingAddress: Yup.string().required("Required"),
  shippingCountry: Yup.string().required("Required"),
  shippingCity: Yup.string().required("Required"),
  shippingState: Yup.string()
    .oneOf(["Colorado", "Ohio", "Florida"], "We are currently only able to serve CO, OH, and FL")
    .required("Please select a state."),
  shippingZipCode: Yup.string()
    .required("Required")
    .matches(/^\d{5}(-\d{4})?$/, "Zip code is not valid"),
  shippingPhone: Yup.string()
    .required("Required")
    .matches(/^(\+?1[-\s]?)?(\(?\d{3}\)?[-\s]?)?\d{3}[-\s]?\d{4}$/, "Phone number is not valid"),

  billingFirstName: Yup.string().required("Required"),
  billingLastName: Yup.string().required("Required"),
  billingAddress: Yup.string().required("Required"),
  billingCountry: Yup.string().required("Required"),
  billingCity: Yup.string().required("Required"),
  billingState: Yup.string().required("Please select a state."),
  billingZipCode: Yup.string()
    .matches(/^\d{5}(-\d{4})?$/, "Zip code is not valid")
    .required("Zip code is required"),
  billingPhone: Yup.string()
    .required("Required")
    .matches(/^(\+?1[-\s]?)?(\(?\d{3}\)?[-\s]?)?\d{3}[-\s]?\d{4}$/, "Phone number is not valid"),
});
