import React from "react";
// Components
import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
// Styled Components
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import HeaderContainer from "styles/components/headerContainer";
import { ImageContainer } from "styles/components/imageContainer";
import { TwoColumnSection } from "styles/components/twoColumnSection";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Theme
import theme from "assets/theme";

const ChaperoneContent = () => {
  const { isLg } = useMediaQueries();


  const cardInfo = [
    {
      image: "https://d3a0rs3velfrz.cloudfront.net/chaperone/chaperone1.webp",
      alt: "Two women sittting on a couch laughing and talking",
      title: "Chaperoning for\\n At-Home Ketamine Experiences",
      textColor: theme.palette.primary.main,
      highlightText: "Chaperoning for",
      highlightColor: theme.palette.accent.main,
      text: "At Almadelic, ensuring patient safety and comfort during ketamine therapy is our top priority. For patients undergoing ketamine treatment from the comfort of their own home, we require the presence of a chaperone. This trusted individual plays a vital role in providing support and assistance throughout the experience."
    },
    {
      image: "https://d3a0rs3velfrz.cloudfront.net/chaperone/chaperone2.webp",
      alt: "Group of friends laughing and talking",
      title: "What is a Chaperone?",
      textColor: theme.palette.primary.main,
      highlightText: "Chaperone?",
      highlightColor: theme.palette.accent.main,
      text: "A chaperone is a responsible adult, at least 18 years old, who the patient trusts and feels at ease with. This could be a spouse, partner, close friend, family member, or roommate. It should be someone with whom the patient has a positive, uncomplicated relationship. The chaperone will be present during the at-home ketamine session to offer support and assistance as needed."
    }
  ]

  const component1 = ({image, alt}) => {
    return(
      <Grid item xs={12} md={12} lg={5}>
        <ImageContainer 
          image={image} 
          alt={alt} 
          />
      </Grid>
    )
  }

  const component2 = ({text, title, textColor, highlightColor, highlightText}) => {
    return(
      <Grid item xs={12} md={12} lg={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <HeaderContainer
            textColor={textColor}
            highlightColor={highlightColor}
            element="h2"
            variant="h2"
            title={title}
            highlightText={highlightText}
            cssProps={{ margin: "0 0 1rem 0" }}
          />
          <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
            {text}
          </MKTypography>
        </Grid>
    )
  }

  return (
    <StyledSectionContainer>
      {cardInfo.map((card, index) => {
        return(
          <TwoColumnSection
              key={index}
              columnGap={6}
              rowGap={6}
              rightComponent={component2(card)}
              leftComponent={component1(card)}
              direction={index % 2 === 0 ? "row" : "row-reverse"}
              cssProps={{margin: "0rem 0rem 2rem 0rem !important", justifyContent: "space-between !important"}}
            />
        )
      })}
    </StyledSectionContainer>
  );
};

export default ChaperoneContent;
