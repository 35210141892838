import { Stack } from "@mui/material";
import theme from "assets/theme";
import MKTypography from "components/MKTypography";
import React, { useContext } from "react";
import { SearchBar } from "utils/context/searchBar.context";

const FilterAppliedCount = ({ filtersAppliedCount, handleClearSearchTerms, color }) => {
  return (
    <>
      {filtersAppliedCount > 0 ? (
        <Stack direction={"row"} gap=".5rem" alignItems={"center"}>
          <MKTypography variant="p" component="p" gutterBottom sx={{ fontSize: "1rem", color: color ? color : theme.palette.white.main }}>
            You have {filtersAppliedCount} filter{filtersAppliedCount !== 1 && "s"} applied
          </MKTypography>
          <MKTypography
            onClick={handleClearSearchTerms}
            variant="span"
            component={"span"}
            gutterBottom
            sx={{ fontSize: "14px", color: theme.palette.warning.main, textDecoration: "underline", cursor: "pointer" }}
          >
            Clear All
          </MKTypography>
        </Stack>
      ) : null}
    </>
  );
};

const ResultsFoundCount = ({ resultsFoundCount, searchTerms, filterOptions, color }) => {
  const hasActiveFilters = Object.values(filterOptions).some((filter) => filter.length > 0);
  const hasSearchTerm = searchTerms !== "" && searchTerms[0]?.length > 0;

  if (!hasSearchTerm && !hasActiveFilters) {
    return null;
  }

  return (
    <MKTypography variant="p" component="p" gutterBottom sx={{ fontSize: "1rem", color: color ? color : theme.palette.white.main }}>
      {resultsFoundCount} result{resultsFoundCount !== 1 && "s"} found
    </MKTypography>
  );
};

const SearchResultsSummary = ({ color }) => {
  const { filtersAppliedCount, resultsFoundCount, searchTerms, filterOptions, handleClearSearchTerms } = useContext(SearchBar);

  return (
    <Stack justifyContent="center" alignItems="center" mt="1rem">
      <FilterAppliedCount filtersAppliedCount={filtersAppliedCount} handleClearSearchTerms={handleClearSearchTerms} color={color} />
      <ResultsFoundCount searchTerms={searchTerms} resultsFoundCount={resultsFoundCount} filterOptions={filterOptions} color={color} />
    </Stack>
  );
};

export default SearchResultsSummary;
