import React from "react";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";

import HeaderContainer from "styles/components/headerContainer";
import { TwoColumnSection } from "styles/components/twoColumnSection";
import { StyledSectionContainer, StyledSectionContainerWhiteWrapper } from "styles/components/SectionContainer/index.styles";
import { ImageContainer } from "styles/components/imageContainer";
import HeroTreeImageComponent from "styles/components/heroTreeComponent";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import theme from "assets/theme";
import MKButton from "components/MKButton";

import { StyledGridTextItem, StyledHeaderSection } from "./index.styles";

import { StyledGridContainer } from "index.styles";


const StateHeader = ({content}) => {
    

    const leftComponent = () => {
        if(!content) return null;
        return(
            <StyledGridTextItem item xs={12} md={8} lg={6} order={2}>
                <MKBox>
                    <HeaderContainer
                        component="h1" 
                        variant="h1"
                        title={content.titleH1} 
                        textColor={theme.palette.primary.main} 
                        highlightText={"At-Home & Virtual Care"}
                        highlightColor={theme.palette.accent.main} 
                    />
                    <MKTypography variant="body1" sx={{margin: "2rem 0rem"}} component="p">{content.description}</MKTypography>
                    {content.cta ? <>
                        <MKButton variant="contained" color="secondary" component={Link} to={content.cta.link}>{content.cta.text}</MKButton>
                        <MKTypography variant="body2" component="p"sx={{marginTop: "1rem"}}>(Just takes 2 minutes)</MKTypography>
                    </> : null}
                </MKBox>
            </StyledGridTextItem>
        )
    }

    const rightComponent = () => {
        return(<HeroTreeImageComponent />)
    }



    return (
        <>
            <StyledHeaderSection component="section" id='ketamine-therapy-state-header'>
                <StyledGridContainer container justifyContent={"space-between"}>
                    {leftComponent()}
                    {rightComponent()}
                </StyledGridContainer>
            </StyledHeaderSection>
        </>
    );
}

export default StateHeader;