import React, { useEffect, useState } from "react";
import { StyledShopSliderContainer, StyledShopSliderContent, StyledShopSliderContentImage, StyledPaginationDots } from "./index.styles";
import { Stack } from "@mui/material";
import MKTypography from "components/MKTypography";
import theme from "assets/theme";
import useMediaQueries from "utils/mediaQueries.utils";
import sliderSteps from "./sliderSteps.config.json";
import { useNavigate } from "react-router-dom";
import HeaderContainer from "styles/components/headerContainer";

const ShopSlider = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();
  const { isLg } = useMediaQueries();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentStep((prevStep) => (prevStep + 1) % sliderSteps.length);
    }, 7000);
    return () => clearInterval(timer);
  }, []);

  const handleNavigate = () => {
    navigate(`${sliderSteps[currentStep].url}`, { state: { productId: sliderSteps[currentStep].productId } });
  };

  const handlePaginationDotClick = (event, index) => {
    event.stopPropagation();
    setCurrentStep(index);
  };

  return (
    <StyledShopSliderContainer onClick={handleNavigate} currentStep={currentStep}>
      <HeaderContainer
        component="h1"
        variant="h1"
        title="Choose The Right \n Ketamine Treatment For You"
        textColor={theme.palette.white.main}
        highlightText={["Ketamine", "Treatment", "For You"]}
        highlightColor={currentStep === 0 ? theme.palette.accent.main : theme.palette.primary.main}
        cssProps={{ textAlign: "center", marginBottom: "1rem" }}
      />
      <StyledShopSliderContent>
        <Stack sx={{ width: isLg ? "75%" : "100%", margin: isLg && "auto" }}>
          <MKTypography component="span" variant="p" sx={{ color: `${theme.palette.white.main} !important`, fontStyle: "italic" }} gutterBottom>
            {sliderSteps[currentStep].title}
          </MKTypography>
          <MKTypography component="p" sx={{ color: `${theme.palette.white.main}`, fontSize: isLg ? "18px" : "16px" }}>
            {sliderSteps[currentStep].subtitle}
          </MKTypography>
        </Stack>
        <StyledShopSliderContentImage component="img" src={sliderSteps[currentStep].imageSource} />
      </StyledShopSliderContent>
      <Stack
        justifyContent={"center"}
        flexDirection={"row"}
        sx={{
          position: isLg && "absolute",
          bottom: isLg && "25px",
          marginTop: !isLg && "2rem",
          width: "100%",
        }}
      >
        {sliderSteps.map((_, index) => (
          <StyledPaginationDots
            onClick={(event) => handlePaginationDotClick(event, index)}
            key={index}
            index={index}
            currentindex={currentStep}
            small="true"
          />
        ))}
      </Stack>
    </StyledShopSliderContainer>
  );
};

export default ShopSlider;
