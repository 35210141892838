import styled from "@emotion/styled";
import MKBox from "components/MKBox";

export const StyledAboutKetamineImageBox = styled(MKBox)(({ theme }) => ({
  position: "relative",
  height: "300px",
  width: "100%",
  overflow: "hidden",
  borderRadius: "1rem",
  "::before": {
    zIndex: "2",
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    boxShadow: " 0px 4px 30px 6px #FFF inset",
    pointerEvents: "none",
  },
  img: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: "1",
  },
  [theme.breakpoints.up("md")]: {
    height: "350px",
  },
  [theme.breakpoints.up("lg")]: {
    height: "475px",
  },
}));
