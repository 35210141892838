import React from "react";
import theme from "assets/theme";
// Components
import ListComponent from "components/CBList";
// Styled Components
import { StyledIdealSpaceSectionContainer, StyledIdealSpaceHeader, StyledIdealSpaceGrid } from "./index.style";
import HeaderContainer from "styles/components/headerContainer";
// Data
import data from "./idealSpaceListItems.data.json";

export default function IdealSpace() {
  return (
    <StyledIdealSpaceSectionContainer component={"section"} id="ideal-space-section">
      <StyledIdealSpaceGrid container>
        <HeaderContainer
          textColor={theme.palette.primary.main}
          highlightColor={theme.palette.accent.main}
          component="h2"
          variant="h2"
          title="Tips For An Ideal Space"
          highlightText="Ideal Space"
          cssProps={{ margin: "0 0 2rem 0", borderBottom: `2px solid ${theme.palette.accent.main}`, height: "60px" }}
        />
        <ListComponent listItems={data} />
      </StyledIdealSpaceGrid>
    </StyledIdealSpaceSectionContainer>
  );
}
