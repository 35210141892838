import React, { useContext } from "react";
// Styled Components
import { StyledMainPageContainer } from "index.styles";
import { StyledSectionBackgroundWrapper } from "index.styles";
// Components
import PageHeader from "../../components/CBPageHeader";
import ProfileCardContainer from "./components/profileCardContainer";
import AreYouATherapist from "./components/areYouATherapist";
import CBDivider from "components/CBDivider";
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";
import CBLoading from "components/CBLoading";
// Utils
import { GHLUsersContext } from "utils/context/ghlUsers.context";
import { constructSchemaData } from "utils/helpers/constructSchemaData";
// Data
import findATherapistSchemaData from "./schema.organization.json";
import seoData from "./seo.data.json";
import { SearchBarProvider } from "utils/context/searchBar.context";
import { therapistFilterFunction } from "utils/helpers/searchBarFilters/therapist.filter";
import { SearchBar } from "utils/context/searchBar.context";

const FindATherapist = () => {
  const { users, isLoading, isError, error } = useContext(GHLUsersContext);

  return (
    <SearchBarProvider initialData={users} filterFunction={therapistFilterFunction}>
      <FindATherapistContent isLoading={isLoading} isError={isError} error={error} />
    </SearchBarProvider>
  );
};

const FindATherapistContent = ({ isLoading, isError, error }) => {
  const schemaData = constructSchemaData(findATherapistSchemaData.url, findATherapistSchemaData.breadcrumbs);
  const { filteredData } = useContext(SearchBar);
  //! I want to build a filterCuratedTherapist that will check the quality of the therapist object by checking for undefined values like - image, description, modalities, etc. And then display the profiles that are more complete first then incomplete ones last....
  //! maybe create a scoring system / count to +1 if value is present -1 if value is missing and then sort from highest to lowest count/score???

  return (
    <StyledMainPageContainer>
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <StyledSectionBackgroundWrapper>
        <PageHeader
          br="true"
          lightText="true"
          header="Find the Right Therapist for Your \n"
          headerAccent={[`Mental Wellness Journey`]}
          headerContent={
            "At Almadelic, we understand the importance of mental health support and the transformative power of therapy. That's why we have created our 'Find a Therapist' directory, a resource designed to help you connect with licensed therapists who can support you on your journey to improved mental wellness."
          }
          headerType="directory"
          link={{
            linkText: "Are You A Therapist? Join Our Directory!",
            linkUrl: "/partners/prospect-survey",
            linkAriaLabel: "Are you are therapist? Join our directory",
          }}
        />
      </StyledSectionBackgroundWrapper>
      {filteredData ? <ProfileCardContainer filteredResults={filteredData} isLoading={isLoading} isError={isError} error={error} /> : <CBLoading />}
      <CBDivider />
      <AreYouATherapist />
    </StyledMainPageContainer>
  );
};

export default FindATherapist;
