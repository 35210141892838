import styled from "@emotion/styled";
import { Container } from "@mui/material";

export const StyledCarasolContainer = styled(Container)(({ theme }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0rem 0rem 4rem !important",
    margin: "0rem !important" 
}));