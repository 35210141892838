import React from "react";
// Components
import MKTypography from "components/MKTypography";
import { Stack } from "@mui/material";
// Styled Components
import { StyledCardsContainer, StyledPrecautionsCardImage, StyledPrecautionsSectionContainer } from "./index.styles";
import { StyledPrecautionsCards } from "index.styles";
import HeaderContainer from "styles/components/headerContainer";
// Theme
import theme from "assets/theme";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Data
import precautionsData from "./precautionsData.config.json";
const Precautions = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledPrecautionsSectionContainer component="section" id="precautions-section">
      <HeaderContainer
        textColor={theme.palette.primary.main}
        highlightColor={theme.palette.accent.main}
        component="h2"
        variant="h2"
        title="Important Precautions"
        highlightText="Precautions"
        cssProps={{ margin: "0 0 2rem 0" }}
      />

      <StyledCardsContainer>
        {precautionsData.map(({ content, title }, index) => {
          return (
            <StyledPrecautionsCards key={index}>
              <Stack mb={isLg ? 4 : 2} direction={isLg ? "column" : "row"} alignItems={isLg ? "center" : "end"} spacing="1rem">
                <StyledPrecautionsCardImage
                  height="55"
                  width="55"
                  // component="img"
                  alt={`Precautions icons for ${title}`}
                  src={
                    index === 0
                      ? "https://d3a0rs3velfrz.cloudfront.net/planningAhead/precautions-section/ImageOne.webp"
                      : index === 1
                      ? "https://d3a0rs3velfrz.cloudfront.net/planningAhead/precautions-section/ImageTwo.webp"
                      : "https://d3a0rs3velfrz.cloudfront.net/planningAhead/precautions-section/ImageThree.webp"
                  }
                ></StyledPrecautionsCardImage>
                <MKTypography component="h3" variant="h6">
                  {title}
                </MKTypography>
              </Stack>
              <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
                {content}
              </MKTypography>
            </StyledPrecautionsCards>
          );
        })}
      </StyledCardsContainer>
    </StyledPrecautionsSectionContainer>
  );
};

export default Precautions;
