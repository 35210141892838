import styled from "@emotion/styled";
import { Container } from "@mui/material";

export const StyledSectionContainer = styled(Container)(({ theme }) => ({
  paddingBlock: "4rem !important",
  alignSelf: "center",

  [theme.breakpoints.up("lg")]: {
    paddingBlock: "6rem !important",
    alignItems: "center",
  },
}));

export const StyledSectionContainerColorWrapper = styled("section")(({ theme, flipBackground }) => ({
  padding: "0 !important",
  margin: "0 !important",
  backgroundSize: "cover",
  backgroundImage: `url(${"https://d3a0rs3velfrz.cloudfront.net/bg-gradient.svg"})`,
  position: "relative",
  zIndex: 1,
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `url(${"https://d3a0rs3velfrz.cloudfront.net/bg-gradient.svg"})`,
    backgroundSize: "cover",
    transform: flipBackground ? "scale(-1, -1)" : "none",

    zIndex: -1,
    opacity: 1,
  },
}));

export const StyledSectionContainerWhiteWrapper = styled("section")(({ theme }) => ({
  padding: "0 !important",
  margin: "0 !important",
  backgroundColor: theme.palette.background.default,
}));
