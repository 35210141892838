import React from "react";
import { StyledPageHeaderSectionContainer } from "./index.styles";
import PolicyHeader from "./components/policyHeader";
import DefaultHeader from "./components/defaultHeader";
import DirectoryHeader from "./components/directoryHeader";

const PageHeader = ({
  headerType = "default",
  section,
  header,
  headerAccent,
  headerTagline,
  lightText,
  br,
  dateModified,
  headerContent,
  link,
  headerTaglineFontSize,
}) => {
  const renderHeader = () => {
    switch (headerType) {
      case "policy":
        return <PolicyHeader section={section} header={header} headerAccent={headerAccent} dateModified={dateModified} />;
      case "directory":
        return (
          <DirectoryHeader
            section={section}
            header={header}
            headerAccent={headerAccent}
            dateModified={dateModified}
            lightText={lightText}
            headerContent={headerContent}
            br={br}
            link={link}
          />
        );
      default:
        return (
          <DefaultHeader
            header={header}
            headerAccent={headerAccent}
            lightText={lightText}
            headerTagline={headerTagline}
            headerTaglineFontSize={headerTaglineFontSize}
          />
        );
    }
  };

  return (
    <StyledPageHeaderSectionContainer component="section" id={`${section}-page-header-section`}>
      {renderHeader()}
    </StyledPageHeaderSectionContainer>
  );
};

export default PageHeader;
