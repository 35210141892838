import React, { createContext, useEffect, useRef, useState } from "react";

export const SearchBar = createContext({
  setSearchTerms: () => {},
  searchTerms: [],
  combinedSearchTerms: [],
  filteredData: [],
  inputRef: null,
  filterOptions: [],
  setFilterOptions: () => {},
  filtersAppliedCount: 0,
  handleClearSearchTerms: () => {},
});

export const SearchBarProvider = ({ children, initialData = [], filterFunction }) => {
  const inputRef = useRef(null);
  const [searchTerms, setSearchTerms] = useState("");
  const [combinedSearchTerms, setCombinedSearchTerms] = useState(new Set());
  const [filterOptions, setFilterOptions] = useState([]);
  const [filteredData, setFilteredData] = useState(initialData);
  const [filtersAppliedCount, setFiltersAppliedCount] = useState(0);
  const [resultsFoundCount, setResultsFoundCount] = useState(0);

  useEffect(() => {
    setFilteredData(initialData);
  }, [initialData]);
  // Creating a new set anytime the search terms or filter options state changes
  useEffect(() => {
    const searchTermsArray = searchTerms.toLowerCase().split(" ").filter(Boolean);
    const filterTerms = Object.values(filterOptions)
      .flat()
      .map((term) => term?.toLowerCase())
      .filter(Boolean);

    const newCombinedSearchTerms = new Set([...searchTermsArray, ...filterTerms]);

    // Only update combinedSearchTerms if the value has actually changed
    if ([...newCombinedSearchTerms].toString() !== [...combinedSearchTerms].toString()) {
      setCombinedSearchTerms(newCombinedSearchTerms);
    }
  }, [searchTerms, filterOptions]);

  // Run the filter function to update the filterData state anytime the combinedSearchTerms state changes
  useEffect(() => {
    if (filterFunction && initialData.length > 0) {
      const filtered = filterFunction(initialData, combinedSearchTerms);
      setFilteredData(filtered);
    }
  }, [combinedSearchTerms, initialData, filterFunction]);

  // Filter count for searchTerms and filterOptions
  useEffect(() => {
    const countFromFilters = Object.entries(filterOptions).reduce((count, [key, value]) => {
      if (Array.isArray(value)) {
        return count + value.length;
      } else if (value !== "All" && value !== "") {
        return count + 1;
      }
      return count;
    }, 0);

    const searchTermsArray = searchTerms.trim().split(" ").filter(Boolean);
    const countFromSearchTerms = searchTermsArray.length;

    const newFiltersAppliedCount = countFromFilters + countFromSearchTerms;

    if (newFiltersAppliedCount !== filtersAppliedCount) {
      setFiltersAppliedCount(newFiltersAppliedCount);
    }
  }, [filterOptions, searchTerms]);

  // Results found count
  useEffect(() => {
    setResultsFoundCount(filteredData?.length);
  }, [filteredData]);

  // Handlers
  const handleSetSearchTerms = (searchTerm) => {
    setSearchTerms(searchTerm);
  };

  const handleClearSearchTerms = () => {
    setSearchTerms("");
    setFilterOptions({});
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const value = {
    setSearchTerms: handleSetSearchTerms,
    searchTerms,
    filteredData,
    inputRef,
    filterOptions,
    setFilterOptions,
    combinedSearchTerms,
    filtersAppliedCount,
    resultsFoundCount,
    handleClearSearchTerms,
  };

  return <SearchBar.Provider value={value}>{children}</SearchBar.Provider>;
};
