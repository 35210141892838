import React from "react";
// Styled Components
import { StyledAboutKetamineCards } from "index.styles";
import ThreeStackCard from "styles/components/threeStackCard";
import HeaderContainer from "styles/components/headerContainer";
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import { ImageContainer } from "styles/components/imageContainer";
import HorizontalInfoCard from "styles/components/horizontalInfoCard";
// Components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// Data
import chaperoneScenariosData from "./chaperoneScenarios.data.config.json";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Theme
import theme from "assets/theme";
const ChaperoneScenarios = () => {
  const { isLg } = useMediaQueries();

  const topComponent = (title) => {
    return(
      <MKTypography component="h3" variant="h6" gutterBottom>
        {title}
      </MKTypography>
    )
  }

  const bottomComponent = (content) => {
    return(
      <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
        {content}
      </MKTypography>
    )
  }


  return (
    <StyledSectionContainer id="chaperone-scenarios-section">
      <MKBox sx={{ maxWidth: "600px", margin: "0 auto 2rem auto" }}>
        <ImageContainer image="https://d3a0rs3velfrz.cloudfront.net/chaperone/chaperone3.webp" alt="Two women sitting next to each other having a conversation" />
      </MKBox>
      <HeaderContainer
        textColor={theme.palette.primary.main}
        highlightColor={theme.palette.accent.main}
        component="h2"
        variant="h2"
        title="Potential Scenarios Requiring Chaperone Assistance"
        highlightText="Potential Scenarios"
        cssProps={{ margin: "0 0 2rem 0", textAlign: "center" }}
      />
      <MKBox sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
        {chaperoneScenariosData.map(({ title, content }, index) => {
          return (
            <HorizontalInfoCard 
              key={index}
              topComponent={topComponent(title)} 
              botComponent={bottomComponent(content)} 
            />
          );
        })}
      </MKBox>
    </StyledSectionContainer>
  );
};

export default ChaperoneScenarios;
