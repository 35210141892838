import React from "react";
// Components
import MKTypography from "components/MKTypography";
import { Grid } from "@mui/material";
import HeaderContainer from "styles/components/headerContainer";
// Theme
import theme from "assets/theme";
// Styled Components
import { StyledImage } from "index.styles";
import { StyledPreparationTextBox, StyledPreparationsPageHeaderSectionContainer } from "./index.styles";
import { StyledSectionBackgroundWrapper } from "index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
const PreparationPageHeader = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledSectionBackgroundWrapper id="session-preparation-page-header-wrapper">
      <StyledPreparationsPageHeaderSectionContainer component="section" id="session-preparations-page-header-section">
        <Grid container spacing={isLg ? 8 : 0} alignItems={"center"}>
          <Grid item xs={12} lg={5} order={isLg ? 1 : 0}>
            <StyledImage
              height="400"
              width="418"
              preparation={true}
              src={"https://d3a0rs3velfrz.cloudfront.net/preparation/pageHeader-section/pageHeaderOne.webp"}
              alt="Man with eyes closed and head titled towards the sky embracing his relaxation "
            ></StyledImage>
          </Grid>
          <Grid item xs={12} lg={7} order={isLg ? 0 : 1}>
            <StyledPreparationTextBox>
              <HeaderContainer
                textColor={theme.palette.white.main}
                highlightColor={theme.palette.accent.main}
                element="h1"
                variant="h1"
                title="Preparing for your At-Home \n Ketamine Therapy Sessions"
                highlightText="Ketamine Therapy Sessions"
                cssProps={{ margin: "0 0 1rem 0" }}
              />
              <MKTypography component="p" variant={isLg ? "body1" : "body2"} sx={{ color: "#fff" }}>
                Eating a clean, healthy diet and avoiding processed foods can help improve your overall health and well-being, making ketamine
                treatment more effective. We recommend you to fast 4-6 hours before your KAP session and limit your water intake. Note that water is
                still encouraged in this time window; however, you should avoid caffeinated drinks like coffee on the day of your session.
              </MKTypography>
            </StyledPreparationTextBox>
          </Grid>
        </Grid>
      </StyledPreparationsPageHeaderSectionContainer>
    </StyledSectionBackgroundWrapper>
  );
};

export default PreparationPageHeader;
