/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Link } from "react-router-dom";
import theme from "assets/theme";
// Components
import { Grid, CardContent } from "@mui/material";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import HeaderContainer from "styles/components/headerContainer";
// Styled Components
import { StyledAboutKetamineSectionContainer, StyledWhyChooseKetamineTextGridItem, StyledWhyChooseKetamineWrapper } from "./index.styles";
import { StyledButtonContainer, StyledAboutKetamineCards } from "index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Data
import aboutKetamineData from "./aboutKetamine.data";

const Features = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledWhyChooseKetamineWrapper id="why-choose-ketamine-wrapper">
      <StyledAboutKetamineSectionContainer component="section" id="why-choose-ketamine-section">
        <Grid container columnSpacing={4} alignItems={"center"}>
          <StyledWhyChooseKetamineTextGridItem item xs={12} lg={6}>
            <HeaderContainer
              title="Why Choose \n Psychedelic Therapy"
              highlightText={["Psychedelic Therapy"]}
              textColor={theme.palette.white.main}
              highlightColor={theme.palette.accent.main}
              element="h2"
              variant="h2"
              cssProps={{ textAlign: { xs: "left" }, margin: "0rem 0rem 1rem 0rem !important" }}
            />
            <MKTypography
              component={"p"}
              variant={isLg ? "body1" : "body2"}
              sx={{
                maxWidth: "800px",
                color: `${theme.palette.white.main} !important`,
              }}
            >
              We understand that the path to mental wellness is unique for each individual. Traditional treatments don't always provide the relief and
              breakthroughs needed to overcome the challenges of depression, anxiety, and other mental health conditions. This is where
              psychedelic-assisted therapy such as KPT (Ketamine Psychedelic Therapy) can make a profound difference.
            </MKTypography>
            {isLg && (
              <StyledButtonContainer>
                <MKButton component={Link} to="/candidate" color="secondary" variant="contained" size="large">
                  Is this right for me?
                </MKButton>
              </StyledButtonContainer>
            )}
          </StyledWhyChooseKetamineTextGridItem>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={2}>
              {aboutKetamineData.map(({ title, subtitle }, i) => {
                return (
                  <Grid item xs={12} key={i + "title"}>
                    <StyledAboutKetamineCards>
                      <CardContent sx={{ padding: "0 !important" }}>
                        <MKTypography
                          variant={"h6"}
                          component={"h3"}
                          sx={{
                            color: `${theme.palette.primary.main} !important`,
                          }}
                          gutterBottom
                        >
                          {title}
                        </MKTypography>

                        <MKTypography
                          variant="body2"
                          fontSize={"16px"}
                          sx={{
                            color: `${theme.palette.primary.main} !important`,
                          }}
                        >
                          {subtitle}
                        </MKTypography>
                      </CardContent>
                    </StyledAboutKetamineCards>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>

          {!isLg && (
            <Grid item xs={12}>
              <StyledButtonContainer
              // maxWidth={404}
              >
                <MKButton
                  component={Link}
                  to="/candidate"
                  color="secondary"
                  variant="contained"
                  size="large"
                  // fullWidth={isMdDown ? false : true}
                >
                  Is this right for me?
                </MKButton>
              </StyledButtonContainer>
            </Grid>
          )}
        </Grid>
      </StyledAboutKetamineSectionContainer>
    </StyledWhyChooseKetamineWrapper>
  );
};

export default Features;
