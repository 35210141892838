import theme from "assets/theme";
import MKTypography from "components/MKTypography";
import React from "react";
import HeaderContainer from "styles/components/headerContainer";

const PolicyHeader = ({ header, headerAccent, dateModified }) => {
  return (
    <>
      <HeaderContainer
        textColor="#092860"
        highlightColor="#75CDC5"
        element="h1"
        variant="h1"
        title={header}
        highlightText={headerAccent}
        cssProps={{ margin: "0rem 0rem 1rem 0rem !important" }}
      />
      <MKTypography
        align="center"
        component="p"
        gutterBottom
        sx={{
          fontSize: 14,
          color: theme.palette.common.black,
        }}
      >
        Last modified on <strong>{dateModified}</strong>
      </MKTypography>
    </>
  );
};

export default PolicyHeader;
