import React from "react";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { ImageContainer } from "styles/components/imageContainer";

import theme from "assets/theme";



const QuoteSection = ({ content, author, authorTitle, imageURL, imageAlt, cssProps }) => {
    return(
      <MKBox sx={{...cssProps}}>
        {imageURL ? <ImageContainer image={imageURL} alt={imageAlt} /> : null}
        <MKTypography variant="h4" component="h4" color={theme.palette.primary.main} sx={{ marginBottom: "1rem" }}>
          "{content}" -{author}: {authorTitle}
        </MKTypography>
      </MKBox>
    )
  }

export default QuoteSection;