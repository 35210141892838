import React from "react";
// Styled Components
import HeaderContainer from "styles/components/headerContainer";
import { StyledSectionContainerColorWrapper, StyledSectionContainer } from "styles/components/SectionContainer/index.styles";

// Components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import CBDivider from "components/CBDivider";
import ListComponent from "components/CBList";
// theme
import theme from "assets/theme";
// Data
import chaperoneResponsibilitiesData from "./chaperoneResponsibilities.data.config.json";
const ChaperoneResponsibilities = () => {
  return (
    <StyledSectionContainerColorWrapper flipBackground={true}>
      <StyledSectionContainer id="chaperone-responsibilities-section">
        <HeaderContainer
          textColor={theme.palette.white.main}
          highlightColor={theme.palette.accent.main}
          component="h2"
          variant="h2"
          title="Responsibilities of the Chaperone"
          highlightText="Responsibilities"
          cssProps={{ margin: "0 0 2rem 0" }}
        />
        {chaperoneResponsibilitiesData.map(({ title, bullets }, index) => {
          return (
            <MKBox key={index}>
              <MKTypography component="h3" variant="h5" sx={{ color: "#fff !important", marginBlock: "1.5rem" }} gutterBottom>
                {title}
              </MKTypography>
              <ListComponent listItems={bullets} color="#fff" />
              {index !== chaperoneResponsibilitiesData.length - 1 && (
                <MKBox sx={{ paddingBlock: "2rem" }}>
                  <CBDivider color={theme.palette.accent.main} />
                </MKBox>
              )}
            </MKBox>
          );
        })}
      </StyledSectionContainer>
    </StyledSectionContainerColorWrapper>
  );
};

export default ChaperoneResponsibilities;
