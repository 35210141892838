export const therapistFilterFunction = (data = [], searchTermsSet) => {
  if (searchTermsSet.size === 0) {
    return data;
  }

  const normalizeAndLowerCase = (value) => String(value).trim().toLowerCase();

  const fieldContainsExactTerm = (field, term) => {
    if (!field) return false;
    const normalizedField = normalizeAndLowerCase(field);
    const normalizedTerm = normalizeAndLowerCase(term);
    return normalizedField.includes(normalizedTerm);
  };
  
  return data.filter((profile) => {
    const { name, city, state, customFields = [] } = profile;
    const profileFields = [name, city, state];
    const customFieldValues = customFields.filter(Boolean).flatMap(({ value }) => (Array.isArray(value) ? value : [value]));
    
    return Array.from(searchTermsSet).every((term) => {
      return (
        profileFields.some((field) => fieldContainsExactTerm(field, term)) || customFieldValues.some((field) => fieldContainsExactTerm(field, term))
      );
    });
  });
};
