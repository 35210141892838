import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const fetchTherapistByID = async (therapistID) => {
  if (!therapistID) {
    return null;
  }
  const body = {"contactID": therapistID};
  const response = await axios.post(`https://1br067lqe8.execute-api.us-east-1.amazonaws.com/corsEnabled/ghl-contact-by-id`, body);
  const data = JSON.parse(response.data.body);
  return data;
}

const useFetchTherapistByID = (therapistID) => {
  
  return useQuery({
    queryKey: ['therapistBio', therapistID],
    queryFn: () => fetchTherapistByID(therapistID),
    staleTime: 30 * 60 * 1000, // 30 minutes
    cacheTime: 60 * 60 * 1000, // 1 hour
    refetchOnWindowFocus: false, // Do not refetch on window focus
  });
  

};

export default useFetchTherapistByID;