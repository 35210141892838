import React from "react";
import { Container, Grid, Stack } from "@mui/material";
import MKTypography from "components/MKTypography";
import theme from "assets/theme";
import {StyledHorizontalCardContainer} from "./index.styles";

const HorizontalInfoCard = ({ topComponent, midComponent, botComponent, handleClick=null }) => {
    return(
        <StyledHorizontalCardContainer onClick={handleClick}>
            <Stack direction="column" spacing={2}>
                {topComponent}
                {midComponent}
                {botComponent}
            </Stack>
        </StyledHorizontalCardContainer>
    )
}

export default HorizontalInfoCard;