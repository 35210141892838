import styled from "@emotion/styled";
import { Grid, Container, Stack } from "@mui/material";


export const StyledGridTextItem = styled(Grid)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
        marginTop: "12rem",
    },
    [theme.breakpoints.up("lg")]: {
        marginTop: "8rem",
    },
}));


export const StyledHeaderSection = styled(Container)(({theme}) => ({
    display: "flex",
  overflow: "visible",
  paddingBlock: "4rem",
  [theme.breakpoints.up("md")]: {
    minHeight: "625px",
  },
  [theme.breakpoints.up("lg")]: {
    paddingBlock: "4rem",
    minHeight: "650px",
    alignItems: "center",
  },
  [theme.breakpoints.up("xl")]: {
    minHeight: "650px",
  },
  [theme.breakpoints.up("xxl")]: {
    minHeight: "850px",
  },
}));