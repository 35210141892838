import React from "react";
import { Link } from "react-router-dom";
// Styled Components
import { StyledSectionBackgroundWrapper } from "index.styles";
import { StyledInsuranceFaqSectionContainer } from "./index.styles";
import { StyledDivider } from "index.styles";
// Components
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
// Theme
import theme from "assets/theme";

// Data
import InsuranceFaqData from "../../faq-insurance.data.json";
import FAQContent from "components/CBFAQ";
import { StyledButtonContainer } from "index.styles";

const InsuranceFaqContainer = () => {
  return (
    <StyledSectionBackgroundWrapper sx={{ transform: "rotate(180deg)" }} id="insurance-faq-wrapper">
      <StyledInsuranceFaqSectionContainer sx={{ transform: "rotate(180deg)" }} component="section" id="insurance-faq-section">
        <MKTypography
          component={"h2"}
          variant={"h2"}
          sx={{
            color: `${theme.palette.white.main} !important`,
          }}
          gutterBottom
        >
          <span style={{ color: theme.palette.accent.main }}>FAQs</span> You May Find Helpful
        </MKTypography>
        <StyledDivider />
        <FAQContent items={InsuranceFaqData} />
        <StyledButtonContainer sx={{ justifyContent: "center", display: "flex" }}>
          <MKButton
            aria-label="See all Faqs"
            sx={{ minWidth: "175px", fontSize: "0.875rem" }}
            component={Link}
            to={`/faq`}
            variant="contained"
            color="secondary"
          >
            See all Faqs
          </MKButton>
        </StyledButtonContainer>
      </StyledInsuranceFaqSectionContainer>
    </StyledSectionBackgroundWrapper>
  );
};

export default InsuranceFaqContainer;
