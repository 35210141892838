export const filterBlogs = (initialData = [], searchTermsSet) => {
  if (searchTermsSet.size === 0) {
    return initialData;
  }

  const normalizeAndLowerCase = (value) => String(value).trim().toLowerCase();

  const normalizedSearchTerms = Array.from(searchTermsSet).map(normalizeAndLowerCase);

  return initialData.filter((blog) => {
    const blogTitle = normalizeAndLowerCase(blog.title);
    const blogDescription = normalizeAndLowerCase(blog.description);

    return normalizedSearchTerms.every((term) => blogTitle.includes(term) || blogDescription.includes(term));
  });
};
