import React from "react";
// Styled Components
import { StyledMainPageContainer } from "index.styles";
// Components
import RefundPolicy from "./RefundPolicy";
import SchemaComponent from "components/CBSchema";
import PageHeader from "components/CBPageHeader";
import SEO from "components/CBSEO";
import { StyledSectionBackgroundWrapper } from "index.styles";
// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";
// Data
import refundPolicySchemaData from "./schema.organization.json";
import seoData from "./seo.data.json";

const RefundPage = () => {
  const schemaData = constructSchemaData(refundPolicySchemaData.url, refundPolicySchemaData.breadcrumbs);
  return (
    <StyledMainPageContainer id="refund-policy">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <StyledSectionBackgroundWrapper>
        <PageHeader lightText="true" header="Cancellation and Refund Policy" headerAccent="Refund Policy" />
      </StyledSectionBackgroundWrapper>
      <RefundPolicy />
    </StyledMainPageContainer>
  );
};

export default RefundPage;
