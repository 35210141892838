import React from "react";
import { Grid, Stack, Container } from "@mui/material";

import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import HeaderContainer from "styles/components/headerContainer";
import { ImageContainer } from "styles/components/imageContainer";
import { TwoColumnSection } from "styles/components/twoColumnSection";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { Link } from "react-router-dom";
import theme from "assets/theme";




const FindATherapistInState = ({content}) => {
    if(!content) return null;

    const rightComponent = () => {
        return(
            <Grid item xs={12} md={12} lg={5}>
                <HeaderContainer component={"h2"} variant="h1" title={content.titleH2} highlightColor={theme.palette.accent.main} highlightText={'Find A Ketamine Therapist'} />
                <MKTypography variant="body1" component="p" color="white" paragraph={true}>
                    {content.description}
                </MKTypography>
                <MKButton variant="contained" component={Link} color="secondary" size="large" to={content.cta.link}>
                    {content.cta.text}
                </MKButton>
            </Grid>
        )
    }

    const leftComponenet = () => {
        return(
            <Grid item xs={12} md={12} lg={4}>
                <ImageContainer image={content.image.src}  alt={content.image.alt} />
            </Grid>
        )
    }


    return (
        <StyledSectionContainer id="ketemaine-therapy-state-find-a-therapist-section">
                <TwoColumnSection rightComponent={rightComponent()} leftComponent={leftComponenet()} columnGap={6} rowGap={4} />
        </StyledSectionContainer>
    );
}

export default FindATherapistInState;