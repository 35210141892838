import React, { useEffect, useState } from "react";
import theme from "assets/theme";

// Styled Components
import { StyledTestimonialsHeaderBox, StyledTestimonialsSectionContainer, StyledTestimonialsTextBox } from "./index.styles";
import { StyledPaginationDots, StyledTestimonialsImage } from "index.styles";
// Components
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import HeaderContainer from "styles/components/headerContainer";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Data
import mockReviewData from "./mockReviewData.json";

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { isLg } = useMediaQueries();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % mockReviewData.length);
    }, 6000);

    return () => clearInterval(timer);
  }, [currentIndex]);

  return (
    <StyledTestimonialsSectionContainer component="section" id="testimonials-section">
      <StyledTestimonialsHeaderBox>
        <HeaderContainer
          title="Real People, Real Results"
          highlightText={["Real Results"]}
          textColor={theme.palette.primary.main}
          highlightColor={theme.palette.accent.main}
          element="h3"
          variant="h3"
          cssProps={{ margin: "0rem 0rem 1rem 0rem !important" }}
        />
        <MKTypography sx={{ fontWeight: "300", color: "#000 !important", fontSize: "90%" }} variant="p">
          <em>
            Transforming Lives Through the Power of Ketamine Psychedelic Therapy.
            <br />
            Hear from Our Community
          </em>
        </MKTypography>
      </StyledTestimonialsHeaderBox>
      <StyledTestimonialsTextBox>
        <StyledTestimonialsImage
          alt={"Begining Quote Symbol For Testimony"}
          src={"https://d3a0rs3velfrz.cloudfront.net/quote1.png"}
          height="70"
          width="70"
          top={"true"}
        ></StyledTestimonialsImage>
        <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
          {mockReviewData[currentIndex].review}
        </MKTypography>
        <MKTypography component="p" variant="h6">
          {mockReviewData[currentIndex].name}
        </MKTypography>
        <StyledTestimonialsImage
          alt={"Ending Quote Symbol For Testimony"}
          src={"https://d3a0rs3velfrz.cloudfront.net/quote2.png"}
          height="70"
          width="70"
          bottom={"true"}
        ></StyledTestimonialsImage>
      </StyledTestimonialsTextBox>
      <MKBox display="flex" justifyContent="center" mt={4}>
        {mockReviewData.map((_, index) => (
          <StyledPaginationDots key={index} onClick={() => setCurrentIndex(index)} index={index} currentindex={currentIndex} />
        ))}
      </MKBox>
    </StyledTestimonialsSectionContainer>
  );
};

export default Testimonials;
