import { unixToDateConversion } from "./unixToDateConversion";

// Helper function to check if one date is before another (ignores time components)
const isBeforeDate = (date1, date2) => {
  if (date1.getFullYear() < date2.getFullYear()) return true;
  if (date1.getFullYear() > date2.getFullYear()) return false;
  if (date1.getMonth() < date2.getMonth()) return true;
  if (date1.getMonth() > date2.getMonth()) return false;
  if (date1.getDate() < date2.getDate()) return true;
  if (date1.getDate() > date2.getDate()) return false;
  return false; // dates are equal
};

// Helper function to generate recurring payment dates
const generateRecurringPaymentDates = (startDate, cancelAtDate) => {
  let recurringPaymentDates = [];
  let paymentDate = new Date(startDate);

  while (isBeforeDate(paymentDate, cancelAtDate)) {
    recurringPaymentDates.push(unixToDateConversion(Math.floor(paymentDate.getTime() / 1000)));
    paymentDate.setMonth(paymentDate.getMonth() + 1);
  }

  return recurringPaymentDates;
};

export const constructPurchaseMadeData = (values, cartItems, cartTotal, originalSubtotal, paymentResults, subscription) => {
  const {
    email,
    shippingAddress,
    shippingFirstName,
    shippingLastName,
    shippingCity,
    shippingCountry,
    shippingZipCode,
    shippingState,
    shippingPhone,
    billingAddress,
    billingFirstName,
    billingLastName,
    billingCity,
    billingCountry,
    billingZipCode,
    billingState,
    billingPhone,
  } = values;

  const cartItemsData = cartItems.map((item) => {
    const { default_price, id, name, price, quantity, images } = item;
    return {
      default_price,
      id,
      name,
      price,
      quantity,
      image: images[0],
    };
  });

  const finalBillingAddress = billingAddress || shippingAddress;
  const finalBillingFirstName = billingFirstName || shippingFirstName;
  const finalBillingLastName = billingLastName || shippingLastName;
  const finalBillingCity = billingCity || shippingCity;
  const finalBillingCountry = billingCountry || shippingCountry;
  const finalBillingZipCode = billingZipCode || shippingZipCode;
  const finalBillingState = billingState || shippingState;
  const finalBillingPhone = billingPhone || shippingPhone;

  const totalDetails = {
    subtotal: (originalSubtotal / 100).toFixed(2),
    total: (cartTotal / 100).toFixed(2),
  };

  let automationDetails;

  if (subscription && !paymentResults) {
    const startDate = new Date(subscription.current_period_start * 1000);
    const cancelAtDate = new Date(subscription.cancel_at * 1000);

    const recurringPaymentDates = generateRecurringPaymentDates(startDate, cancelAtDate);

    automationDetails = {
      promoCodeId: subscription.discount?.coupon?.id,
      promoCodeName: subscription.discount?.coupon?.name,
      datePurchased: unixToDateConversion(subscription.created),
      numberOfPayments: recurringPaymentDates.length,
      initialPaymentAmount: (subscription.latest_invoice.amount_paid / 100).toFixed(2),
      initialPaymentDate: unixToDateConversion(subscription.latest_invoice.created),
      recurringPaymentAmount: (subscription.plan.amount / 100).toFixed(2),
      recurringPaymentDates: recurringPaymentDates.join(", "),
    };
  }

  if (paymentResults && subscription) {
    const startDate = new Date(subscription.current_period_start * 1000);
    const cancelAtDate = new Date(subscription.cancel_at * 1000);

    const recurringPaymentDates = generateRecurringPaymentDates(startDate, cancelAtDate);

    automationDetails = {
      promoCodeId: subscription.discount.coupon.id,
      promoCodeName: subscription.discount.coupon.name,
      datePurchased: unixToDateConversion(subscription.created) || unixToDateConversion(paymentResults.created),
      numberOfPayments: recurringPaymentDates.length,
      initialPaymentAmount: ((subscription.latest_invoice.amount_paid + paymentResults.amount) / 100).toFixed(2),
      initialPaymentDate: unixToDateConversion(subscription.latest_invoice.created) || unixToDateConversion(paymentResults.created),
      recurringPaymentAmount: (subscription.latest_invoice.amount_due / 100).toFixed(2),
      recurringPaymentDates: recurringPaymentDates.join(", "),
    };
  }

  if (paymentResults && !subscription) {
    automationDetails = {
      datePurchased: unixToDateConversion(paymentResults.created),
      numberOfPayments: 1,
      initialPaymentAmount: (paymentResults.amount / 100).toFixed(2),
      initialPaymentDate: unixToDateConversion(paymentResults.created),
      recurringPaymentAmount: "N/A",
      recurringPaymentDates: "N/A",
    };
  }

  const purchaseData = {
    line_items: cartItemsData,
    billing: {
      addressLine: finalBillingAddress,
      firstName: finalBillingFirstName,
      lastName: finalBillingLastName,
      city: finalBillingCity,
      country: finalBillingCountry,
      postalCode: finalBillingZipCode,
      state: finalBillingState,
      phone: finalBillingPhone,
    },
    shipping: {
      addressLine: shippingAddress,
      firstName: shippingFirstName,
      lastName: shippingLastName,
      city: shippingCity,
      country: shippingCountry,
      postalCode: shippingZipCode,
      state: shippingState,
      phone: shippingPhone,
    },
    total: totalDetails,
    email: email,
    phone: shippingPhone,
    ...automationDetails,
  };

  return { purchaseData };
};
