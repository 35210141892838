import React from "react";
// Components
import PageHeader from "components/CBPageHeader";
import ChaperoneContent from "./components/chaperoneContent";
import ChaperoneResponsibilities from "./components/chaperoneResponsibilites";
import ChaperoneScenarios from "./components/chaperoneScenarios";
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";
// Styled Components
import { StyledMainPageContainer } from "index.styles";
import { StyledSectionBackgroundWrapper } from "index.styles";
// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";

// Data
import adultChaperoneSchemaData from "./schema.organization.json";
import adultChaperoneArticleSchemaData from "./schema.article.json";
import seoData from "./seo.data.json";

const Chaperone = () => {
  const schemaData = constructSchemaData(adultChaperoneSchemaData.url, adultChaperoneSchemaData.breadcrumbs, adultChaperoneArticleSchemaData);
  return (
    <StyledMainPageContainer id="chaperone-container">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <StyledSectionBackgroundWrapper>
        <PageHeader
          section={"chaperone"}
          header="The Role of an Adult Chaperone \n In Ketamine Therapy"
          headerAccent="In Ketamine Therapy"
          lightText="true"
          br="true"
        />
      </StyledSectionBackgroundWrapper>
      <ChaperoneContent />
      <ChaperoneResponsibilities />
      <ChaperoneScenarios />
    </StyledMainPageContainer>
  );
};
// The Role of an Adult Chaperone in Ketamine Therapy
export default Chaperone;
