// Major
import React, { useRef, useState } from "react";
import { Container, Grid, Stack } from "@mui/material";

// Components
import { StyledSectionContainerWhiteWrapper, StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import MKTypography from "components/MKTypography";
import HeaderContainer from "styles/components/headerContainer";
import Pagination from "components/CBPagination";

// Style
import theme from "assets/theme";
import HorizontalInfoCard from "styles/components/horizontalInfoCard";

// Utils
import useMediaQueries from "utils/mediaQueries.utils";

// json data
import clinicalResearchData from "./clinicalResearch.data";

const ClinicalResearch = ({ ...props }) => {
  const { resources } = props;
  const [numRows, setNumRows] = useState(3);
  const containerRef = useRef(null);
  const { isMd } = useMediaQueries();

  const Cards = () => {
    const handleSearch = (sourceReference) => {
      window.open(sourceReference, "_blank");
    };

    const topComponent = (card) => {
      const maxCharLength = 75;
      const maxCharLengthAuthor = 25;
      if (card.title.length > maxCharLength) {
        return (
          <Container>
            <MKTypography
              variant="h4"
              fontWeight={700}
              sx={{
                color: `${theme.palette.primary.main} !important`,
              }}
            >
              {card.title.substring(0, maxCharLength)}...
            </MKTypography>
            <MKTypography
              variant="h6"
              fontWeight={700}
              sx={{
                color: `${theme.palette.secondary.main} !important`,
              }}
            >
              {card.author.substring(0, maxCharLengthAuthor)} {card.author.length > maxCharLengthAuthor ? "..." : ""}
            </MKTypography>
          </Container>
        );
      }
      return (
        <Container>
          <MKTypography
            variant="h4"
            fontWeight={700}
            sx={{
              color: `${theme.palette.primary.main} !important`,
            }}
          >
            {card.title}
          </MKTypography>
          <MKTypography
            variant="h6"
            fontWeight={700}
            sx={{
              color: `${theme.palette.secondary.main} !important`,
            }}
          >
            {card.author.substring(0, maxCharLengthAuthor)} {card.author.length > maxCharLengthAuthor ? "..." : ""}
          </MKTypography>
        </Container>
      );
    };

    const botComponent = (card) => {
      const maxCardLength = isMd ? 200 : 100;
      if (card.summary.length > maxCardLength) {
        return (
          <Container>
            <MKTypography
              variant="body2"
              component="body2"
              sx={{
                color: `${theme.palette.primary.main} !important`,
              }}
            >
              {card.summary.substring(0, maxCardLength)}...
            </MKTypography>
          </Container>
        );
      }
      return (
        <Container>
          <MKTypography
            variant="body2"
            fontWeight={300}
            sx={{
              color: `${theme.palette.primary.main} !important`,
            }}
          >
            {card.summary}
          </MKTypography>
        </Container>
      );
    };

    return (
      <Grid container spacing={2} ref={containerRef}>
        {resources.map((data, index) => {
          if (index >= numRows - 3 && index < numRows) {
            return (
              <Grid item xs={16} sm={16} md={16} key={index}>
                <HorizontalInfoCard
                  topComponent={topComponent(data)}
                  midComponent={null}
                  botComponent={botComponent(data)}
                  handleClick={() => handleSearch(data.sourceReference)}
                />
              </Grid>
            );
          }
        })}
      </Grid>
    );
  };


  return (
    <StyledSectionContainerWhiteWrapper>
      <StyledSectionContainer>
        <HeaderContainer
          title="Clinical Research"
          textColor={theme.palette.primary.main}
          component="h2"
          variant="h1"
          sx={{ marginTop: "0rem !important" }}
        />
        <Cards />
        
        <Stack direction="row" spacing={2} sx={{ justifyContent: "center", marginTop: "2rem" }}>
        <Pagination
          totalItems={resources.length}
          itemsPerPage={3}
          onPageChange={(pageNumber) => setNumRows(pageNumber * 3)}
          containerRef={containerRef}
        />
        </Stack>
      </StyledSectionContainer>
    </StyledSectionContainerWhiteWrapper>
  );
};

export default ClinicalResearch;
