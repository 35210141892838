import React from "react";
import { Container, Grid, Stack } from "@mui/material";

import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import HeaderContainer from "styles/components/headerContainer";
import { ImageContainer } from "styles/components/imageContainer";
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import { TwoColumnSection } from "styles/components/twoColumnSection";

import theme from "assets/theme";
import useMediaQueries from "utils/mediaQueries.utils";
import { Link } from "react-router-dom";
import { StyledIndexIcon } from "./index.styles";
const HowAtHomeWorks = ({content}) => {

    const { isLg, isMd, isSm, isSmUp } = useMediaQueries();
    if(!content) return null;

    const hexToRgba = (hex, alpha) => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
      
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };


    const Banner = () => {
        const tealOpaque = hexToRgba(theme.palette.accent.main, 1);
        const tealTransparent = hexToRgba(theme.palette.accent.main, 0);
        return (
            <Stack direction="row" sx={{
                background: `linear-gradient(to right, ${tealOpaque} 30%, ${tealTransparent} 90%)`,
                padding: "4rem 0rem 4rem 2rem", 
              }}>
                {isMd ? <MKBox sx={{width: "75px", marginTop: "-15px"}}>
                    <ImageContainer image="https://almadelic-assets.s3.us-east-2.amazonaws.com/logos/white_butterfly.webp" alt="Image of logo" />
                </MKBox> : null}
                <HeaderContainer title={content.titleH2} component={"h2"} variant={"h1"} textColor={theme.palette.white.main} cssProps={{margin: "0 !important"}}/>
            </Stack>
        )
    }

    const leftComponent = () => {
        return(
            <Grid item xs={12} md={10} lg={4} order={0}>
                <ImageContainer image={content.image.src} alt={content.image.alt} />
            </Grid>
        )
    }

    const rightComponent = () => {

        const numberIcon = (number) => {
            return(
                <StyledIndexIcon>
                    <MKTypography variant="h1" component="p" sx={{
                        color: `${theme.palette.accent.main} !important`, 
                        margin: 'auto'
                        }} 
                    >
                        {number}
                    </MKTypography>
                </StyledIndexIcon>
            )
        }

        return(
            <Grid container item xs={12} md={10} lg={6} order={1} direction="column" sx={{display: "flex", justifyContent:"center"}} rowGap={isLg ? 10 : 6}>
                {content.steps.map((step, index) => {
                    return(
                        <Grid item key={index} xs={12} sx={{display: "flex"}}>
                            <MKTypography sx={{color: `${theme.palette.white.main} !important`}} variant="h3" element="h3">{numberIcon(index+1)}</MKTypography>
                            <MKTypography sx={{color: `${theme.palette.white.main} !important`,display: "flex", justifyContent: "center", alignItems: "center"}} variant="h4" element="p">{step}</MKTypography>
                        </Grid>
                    )
                })}
            </Grid>
        )
    }

    return (
        <>
        <Banner />
        <StyledSectionContainer id='ketamine-therapy-state-how-it-works'>
            <TwoColumnSection columnSpacing={0} rowSpacing={0} columnGap={6} rowGap={4} leftComponent={leftComponent()} rightComponent={rightComponent()} />
            <Stack direction="column" sx={{marginTop: "4rem"}}>
                <MKTypography variant="h5" component="p" sx={{color: `${theme.palette.white.main} !important`, textAlign: "center", margin: "2rem 0rem"}}>{content.cta.text}</MKTypography>
                <MKButton sx={{display:"flex", justifyContent: "center", alignSelf: "center", }} variant="contained" color="secondary" size="large" component={Link} to={content.cta.link} >Our Program</MKButton>
            </Stack>
        </StyledSectionContainer>
        </>
    );
}

export default HowAtHomeWorks;