import React from "react";
// Styled Components
import { StyledPolicyContentSectionContainer } from "./index.styles";
// Components
import MKTypography from "components/MKTypography";
import { Link } from "@mui/material";
import theme from "assets/theme";
import { replaceKeywordWithLink } from "utils/helpers/replaceKeywordWithLink";
import SimpleDataTable from "components/CBSimpleDataTable";
import ListComponent from "components/CBList";

const renderFooterDescription = (footerDescription) => {
  if (typeof footerDescription === "string") {
    return (
      <MKTypography component={"p"} variant={"body2"} sx={{ mt: "1rem" }}>
        {footerDescription}
      </MKTypography>
    );
  } else if (typeof footerDescription === "object" && footerDescription !== null) {
    const { description, keywords } = footerDescription;
    const updateDescription = replaceKeywordWithLink(description, keywords);
    return (
      <div>
        <MKTypography component={"p"} variant={"body2"} sx={{ mt: "1rem" }}>
          {updateDescription}
        </MKTypography>
      </div>
    );
  } else {
    return null;
  }
};

const PolicyContent = ({ data, section }) => {
  return (
    <StyledPolicyContentSectionContainer id={`${section}-content`}>
      {data.map(({ title, sections, footerDescription, cta }, index) => (
        <div key={index}>
          {title && (
            <MKTypography
              variant="h3"
              component="h2"
              gutterBottom
              sx={{
                fontWeight: "medium",
                mt: "2rem",
              }}
            >
              {title}
            </MKTypography>
          )}
          {sections.map(({ description, descriptionTitle, keywords, list, table }, sectionIndex) => {
            const updateDescription = replaceKeywordWithLink(description, keywords);

            return (
              <div key={sectionIndex}>
                {descriptionTitle && (
                  <MKTypography component="h3" variant="h6" mt="2rem" mb="1rem">
                    {descriptionTitle}
                  </MKTypography>
                )}
                {description && (
                  <MKTypography component="p" variant="body2" my="1rem">
                    {updateDescription}
                  </MKTypography>
                )}
                {list && <ListComponent listItems={list} />}
                {table && <SimpleDataTable data={table}></SimpleDataTable>}
              </div>
            );
          })}
          {cta && (
            <Link sx={{ color: theme.palette.secondary.main }} rel="noopener" target="_blank" href={cta.link}>
              {cta.label}
            </Link>
          )}
          {renderFooterDescription(footerDescription)}
        </div>
      ))}
    </StyledPolicyContentSectionContainer>
  );
};

export default PolicyContent;
