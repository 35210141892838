import React from "react";
import { Link } from "react-router-dom";

import { Grid } from "@mui/material";
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import theme from "assets/theme";

import { TwoColumnSection } from "styles/components/twoColumnSection";
import MKButton from "components/MKButton";
import HeaderContainer from "styles/components/headerContainer";
import ListComponent from "components/CBList";
import { StyledAboutKetamineImageBox } from "./index.styles";

const ScienceBehind = ({ ...props }) => {
  const { leftColumn, rightColumn } = props;

  const ImageComponent = (item) => {
    const { image } = rightColumn;
    return (
      <Grid item xs={12} sm={10} lg={5} order={"1"}>
        <StyledAboutKetamineImageBox>
          <img scrolling="no" title={image.alt} src={image.imageUrl} frameBorder="0" className="giphy-embed" allowFullScreen alt={image.alt}></img>
        </StyledAboutKetamineImageBox>
      </Grid>
    );
  };

  const ListBox = (card) => {
    const { title, list, cta } = leftColumn;
    return (
      <Grid item xs={12} sm={10} lg={6} order="2">
        <HeaderContainer
          component={"h2"}
          variant={"h2"}
          title={title}
          textColor={theme.palette.primary.main}
          highlightColor={theme.palette.accent.main}
          highlightText={"Benefits Of Ketamine Therapy"}
        />
        <ListComponent listItems={list} />
        {cta ? <MKButton variant="contained" color="secondary" component={Link} to={cta.link} sx={{ marginTop: "1rem", padding: "0 2rem" }}>
          {cta.text}
        </MKButton> : null}
      </Grid>
    );
  };

  return (
    <StyledSectionContainer id="ketamine-therapy-hub-science-behind-section">
      <TwoColumnSection rightComponent={<ListBox />} leftComponent={<ImageComponent />} columnGap={6} rowGap={2} />
    </StyledSectionContainer>
  );
};

export default ScienceBehind;
