import React from "react";
import { StyledProductDetailsBreadCrumbsSectionContainer } from "./index.styles";
import { Link } from "react-router-dom";
import MKTypography from "components/MKTypography";
import { Stack } from "@mui/material";

const BreadCrumbs = ({ packageName }) => {
  return (
    <StyledProductDetailsBreadCrumbsSectionContainer>
      <Stack direction={"row"} spacing={1} sx={{ width: "100%" }}>
        <Link to="/ketamine-therapy/packages" style={{ color: "red !important", textDecoration: "none" }}>
          <MKTypography component="p" variant="body2" sx={{}}>
            Shop
          </MKTypography>
        </Link>{" "}
        <MKTypography component="p" variant="body2" sx={{}}>
          /
        </MKTypography>
        <MKTypography component="p" variant="body2" sx={{}}>
          {packageName}
        </MKTypography>
      </Stack>
    </StyledProductDetailsBreadCrumbsSectionContainer>
  );
};

export default BreadCrumbs;
