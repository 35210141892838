import React from "react";
// Components
import MKTypography from "components/MKTypography";
import HeaderContainer from "styles/components/headerContainer";
import { ImageContainer } from "styles/components/imageContainer";
import ThreeStackCard from "styles/components/threeStackCard";
// Styled Components
import { StyledProfileCards } from "./index.styles";
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import theme from "assets/theme";
import { Grid } from "@mui/material";
import MKBox from "components/MKBox";


const MeetOurTeam = ({data}) => {


    const TeamMemberCard = ({ image, name, title }) => {

        const imageComponent = () => {
            return (
                <ImageContainer cssProps={{borderRadius: 360, border: `3px solid ${theme.palette.accent.main}`}} image={image} alt={`${name}'s Member Partner Profile Image`} />
            )
        }

        const nameComponent = () => {
            return (
                <MKBox sx={{marginTop: "1rem"}}>
                    <MKTypography component="h2" variant="h6">
                        {name}
                    </MKTypography>
                    <MKTypography component="p" variant={"body2"}>
                        {title}
                    </MKTypography>
                </MKBox>
            )
        }

        const titleComponent = () => {
            return (
                <MKTypography component="p" variant={"body2"}>
                    {title}
                </MKTypography>
            )
        }


        return (
            <Grid item xs={12} md={6} lg={4} xl={3}>
                <ThreeStackCard topComponent={imageComponent()} midComponent={nameComponent()} />
            </Grid>
        );
    }


    return (
        <StyledSectionContainer>
            <HeaderContainer title={data.section} textColor={theme.palette.white.main} highlightText={"Team"} highlightColor={theme.palette.accent.main} variant="h1" component="h1"  />
            <Grid container rowSpacing={4} columnSpacing={4}>
                {data.content.map(({ image, name, title }, index) => {
                    
                    return <TeamMemberCard key={index} image={image} name={name} title={title} />;
                })}
            </Grid>
        </StyledSectionContainer>
      );
}

export default MeetOurTeam;