import React, { useState } from "react";
// Components
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
// Styled Components
import { StyledBenefitsCardsContainer, StyledBenefitsOfMusicAndMentalHealthSectionContainer } from "./index.styles";
import { StyledMusicBenefitCard } from "index.styles";
import { StyledButtonContainer } from "index.styles";
// Data
import benefitsData from "./benefitsData.config.json";
// theme
import theme from "assets/theme";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
import HeaderContainer from "styles/components/headerContainer";
const BenefitsOfMusicAndMentalHealth = () => {
  const { isLg } = useMediaQueries();
  const [showAll, setShowAll] = useState(false);

  const handleViewMoreClick = () => {
    setShowAll(!showAll);
  };
  const visibleBenefits = showAll ? benefitsData : benefitsData.slice(0, 4);
  return (
    <StyledBenefitsOfMusicAndMentalHealthSectionContainer id="benefits-of-music-and-mental-health-section" component="section">
      <HeaderContainer
        textColor={theme.palette.primary.main}
        highlightColor={theme.palette.accent.main}
        component="h3"
        variant="h3"
        title="Several reasons why music is considered\nA helpful and valuable component of ketamine therapy for patients"
        highlightText={["why music is ", "helpful and valuable", "for patients"]}
        cssProps={{
          textAlign: "center",
          borderBottom: `2px solid ${theme.palette.accent.main}`,
          height: "80px",
          marginBottom: "2rem",
        }}
      />
      <StyledBenefitsCardsContainer>
        {visibleBenefits.map(({ title, description }, index) => {
          return (
            <StyledMusicBenefitCard key={index}>
              <MKTypography component="h4" variant="h6" gutterBottom>
                {title}
              </MKTypography>
              <MKTypography component="p" variant={isLg ? "body1" : "body2"} gutterBottom>
                {description}
              </MKTypography>
            </StyledMusicBenefitCard>
          );
        })}
      </StyledBenefitsCardsContainer>
      <StyledButtonContainer sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <MKButton
          aria-label={showAll ? "View Less" : "View More"}
          onClick={handleViewMoreClick}
          color="secondary"
          variant="contained"
          sx={{ fontSize: ".875rem", minWidth: "150px" }}
        >
          {showAll ? "View Less" : "View More"}
        </MKButton>
      </StyledButtonContainer>
      <MKTypography
        component="p"
        sx={{
          borderTop: `2px solid ${theme.palette.accent.main}`,
          paddingTop: "1rem",
          marginTop: "2rem",
          textAlign: "center",
        }}
      >
        It's important to note that the role of music in ketamine therapy can vary based on the therapeutic goals, the preferences of the patient, and
        the therapeutic approach of the clinician. The careful integration of music into the session is part of the overall strategy to optimize the
        therapeutic benefits of ketamine-assisted therapy.
      </MKTypography>
    </StyledBenefitsOfMusicAndMentalHealthSectionContainer>
  );
};

export default BenefitsOfMusicAndMentalHealth;
