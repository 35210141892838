import styled from "@emotion/styled";
import { Container, Stack, Box } from "@mui/material";
import MKBox from "components/MKBox";


export const StyledHorizontalCardContainer = styled(Stack)(({ theme }) => ({
    alignItems: "center",
    justifyContent: "top",
    padding: "1.0rem", 
    width: "100%",
    height: "100%",
    margin: "0 !important",
    backgroundColor: `${theme.palette.white.main} !important`,
    borderRadius: "12px",
    borderLeft: `10px solid ${theme.palette.accent.main} !important`,
    boxShadow: "0 0 20px 0 rgba(0,0,0,0.2)",
}));