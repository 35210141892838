import React from "react";
import { Link } from "react-router-dom";
import theme from "assets/theme";
// Components
import { Grid, Stack } from "@mui/material";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import ListComponent from "components/CBList";
import HeaderContainer from "styles/components/headerContainer";
// Styled Components
import { StyledButtonContainer, StyledImage } from "index.styles";
import { StyledServicesSectionContainer } from "./index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Data
import ourServicesBulletPointData from "./ourServicesBulletPoint.data.json";

const OurServices = () => {
  const { isLg } = useMediaQueries();

  const RightSide = () => {
    return (
      <Stack direction="row" alignContent={"center"} spacing={isLg ? 2 : 0} justifyContent={"space-between"}>
        <StyledImage
          ourservices={"true"}
          src={"https://d3a0rs3velfrz.cloudfront.net/home/services/services3.webp"}
          alt="Man at a table using his laptop to view Almadelic services."
          width="238"
          height="355"
        ></StyledImage>
        <StyledImage
          ourservices={"true"}
          src={"https://d3a0rs3velfrz.cloudfront.net/home/services/servicesImg1.webp"}
          alt="Woman at a table using her laptop to view Almadelic services."
          width="238"
          height="355"
        ></StyledImage>
      </Stack>
    );
  };
  const LeftSide = () => (
    <Grid container>
      <Grid item xs={12}>
        <HeaderContainer
          title="Explore Our Services"
          highlightText={["Services"]}
          textColor={theme.palette.primary.main}
          highlightColor={theme.palette.accent.main}
          element="h2"
          variant="h2"
          cssProps={{ textAlign: { xs: "left" }, margin: "0rem 0rem 1rem 0rem !important" }}
        />
        <MKTypography component="p" variant={isLg ? "body1" : "body2"} gutterBottom>
          Whether you're seeking relief from depression, anxiety, or a desire for personal growth, our team is here to guide you every step of the
          way.
        </MKTypography>
      </Grid>
      <Grid item xs={12}>
        <ListComponent listItems={ourServicesBulletPointData} />
        <StyledButtonContainer>
          <MKButton
            aria-label="Learn more about our services"
            component={Link}
            to={"/ketamine-therapy"}
            variant="contained"
            color="secondary"
            size="large"
          >
            Learn more about our Services
          </MKButton>
        </StyledButtonContainer>
      </Grid>
    </Grid>
  );

  return (
    <MKBox id="our-services-wrapper">
      <StyledServicesSectionContainer component="section" id="our-services-section">
        <Grid container spacing={4}>
          {isLg ? (
            <>
              <Grid item xs={12} lg={6} justifyContent={"space-between"}>
                <LeftSide />
              </Grid>
              <Grid item sx={{ display: "Flex" }} alignItems={"center"} justifyContent={"center"} xs={12} lg={6}>
                <RightSide />
              </Grid>
            </>
          ) : (
            <>
              <Grid item sx={{ display: "Flex" }} alignItems={"center"} justifyContent={"center"} xs={12} lg={6}>
                <RightSide />
              </Grid>
              <Grid item xs={12} lg={6}>
                <LeftSide />
              </Grid>
            </>
          )}
        </Grid>
      </StyledServicesSectionContainer>
    </MKBox>
  );
};

export default OurServices;
