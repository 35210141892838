import React from "react";

// components
import HeaderContainer from "styles/components/headerContainer";

// styles
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import theme from "assets/theme";

// utils
import useMediaQueries from "utils/mediaQueries.utils";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const WaitList = () => {
    const {isSm, isMd} = useMediaQueries();


    return(
        <StyledSectionContainer id="Psilocybin-Wait-List-form">
            <HeaderContainer
                component="h2"
                variant="h1" 
                title="Join The Wait List" 
                cssProps={{textAlign: "center"}} 
                textColor={theme.palette.white.main} 
                highlightText={"Join"} 
                highlightColor={theme.palette.accent.main} 
            />
            <MKBox sx={{margin: "0rem 0rem 1rem"}}>
                <MKTypography variant="body1" component="h5" align="center" color="white">
                    Recieve updates on our progress and be the first to know when we launch.    
                </MKTypography>
            </MKBox>
            <iframe
                src="https://api.leadconnectorhq.com/widget/form/BK6gZXfqNcFCu2H2TJkG"
                style={{width: "100%",minHeight:isSm ? "875px" : "700px", backgroundColor: "rgb(0,0,0,0.0)", border: "none"}}
                id="inline-BK6gZXfqNcFCu2H2TJkG" 
                data-layout="{'id':'INLINE'}"
                data-trigger-type="alwaysShow"
                data-trigger-value=""
                data-activation-type="alwaysActivated"
                data-activation-value=""
                data-deactivation-type="neverDeactivate"
                data-deactivation-value=""
                data-form-name="Wait List Form"
                data-height="644"
                data-layout-iframe-id="inline-BK6gZXfqNcFCu2H2TJkG"
                data-form-id="BK6gZXfqNcFCu2H2TJkG"
                title="Wait List Form"
                    >
            </iframe>
            <script src="https://link.msgsndr.com/js/form_embed.js"></script>
        </StyledSectionContainer>
    )
}

export default WaitList;
