import CBLogo from "components/CBLogo";
import MKBox from "components/MKBox";
import React from "react";
import { Link } from "react-router-dom";

const NavLogo = ({ paddingLeft, setMobileNavToggle, mobileNavToggle }) => {
  const toggleMobileNav = () => setMobileNavToggle(false);
  return (
    <MKBox
      sx={{ height: "100%", marginTop: ".2rem" }}
      aria-label="Almadelic homepage"
      component={Link}
      to="/"
      onClick={toggleMobileNav}
      lineHeight={1}
      pl={paddingLeft ? paddingLeft : 0}
    >
      <CBLogo />
    </MKBox>
  );
};

export default NavLogo;
