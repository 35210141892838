import React from "react";
import { Link } from "react-router-dom";

// Components
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";

// Styled Components
import { StyledGridContainer, StyledGridItemLeft, StyledGridItemRight, StyledOurProgramSectionContainer } from "./index.styles";
import { StyledSectionBackgroundWrapper } from "index.styles";
import { StyledButtonContainer } from "index.styles";
import { StyledImage } from "index.styles";

// Utils
import useMediaQueries from "utils/mediaQueries.utils";
import { ghlLinkFreeConsultation } from "utils/helpers/ghlFunnelLinks";
// Theme
import theme from "assets/theme";

const OurProgram = () => {
  const { isMd, isLg } = useMediaQueries();

  return (
    <StyledSectionBackgroundWrapper id="our-program-wrapper">
      <StyledOurProgramSectionContainer component="section" id="our-program-section">
        <StyledGridContainer container columnSpacing={isLg ? 6 : 3} rowSpacing={isLg ? 0 : 6}>
          <StyledGridItemLeft item xs={12} lg={7}>
            <MKBox>
              <MKTypography variant="h1" gutterBottom sx={{ color: `${theme.palette.white.main} !important` }}>
                Our Personalized <br /> <span style={{ color: `${theme.palette.accent.main}` }}>Ketamine Therapy Program</span>
              </MKTypography>
              <MKTypography variant={isMd ? "body1" : "body2"} gutterBottom sx={{ color: theme.palette.white.main }}>
                At Almadelic we provide comprehensive and personalized psychedelic therapies tailored to your individual needs. When you choose us,
                you're not just selecting a service; you're opting for a transformative experience designed to guide you towards mental well-being and
                personal growth. Read on to learn more about what you can expect from our range of services.
              </MKTypography>
              <MKTypography variant={isMd ? "body1" : "body2"} margin={"auto"} sx={{ color: theme.palette.accent.main }}>
                <em>Still have questions? Talk to an expert care guide.</em>
              </MKTypography>
              <StyledButtonContainer>
                <MKButton
                  component={Link}
                  to={ghlLinkFreeConsultation}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="contained"
                  color="secondary"
                  size="large"
                  fullWidth={isMd ? false : true}
                  aria-label="Schedule A Free Consultation Today!"
                >
                  Schedule A Free Consultation Today!
                </MKButton>
              </StyledButtonContainer>
            </MKBox>
          </StyledGridItemLeft>
          <StyledGridItemRight item xs={12} lg={5}>
            <StyledImage
              height="427"
              width="374"
              src={"https://d3a0rs3velfrz.cloudfront.net/ourProgram/programImg1.webp"}
              alt="Lady sitting on a yoga mat with her legs crossed meditating"
            ></StyledImage>
          </StyledGridItemRight>
        </StyledGridContainer>
      </StyledOurProgramSectionContainer>
    </StyledSectionBackgroundWrapper>
  );
};

export default OurProgram;
