import React from "react";
import USAMap from "react-usa-map";
import { StyledMapContainer } from "./index.styles";

const TAUSAMap = ({ mapHandler = null, stateCustomConfig = null, width = null, height=null }) => {
  if (!mapHandler) {
    mapHandler = (event) => {
      alert("We currently are not available in " + event.target.dataset.name + ". Please check back later. Or select another State.");
    };
  }

  if (!width) {
    width = window.innerWidth - "2rem";
  }

  return (
    <StyledMapContainer>
      <USAMap onClick={mapHandler} customize={stateCustomConfig} width={width} height={height} />
    </StyledMapContainer>
  );
};

export default TAUSAMap;
