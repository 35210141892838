import React from "react";
// Components
import QuickTips from "./components/quickTips";
import PlanningContent from "./components/planningContent";
import Precautions from "./components/precautions";
import Technology from "./components/technology";
import CBDivider from "components/CBDivider";
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";
// Styled Components
import { StyledMainPageContainer } from "index.styles";
// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";
// Data
import planningAheadSchemaData from "./schema.organization.json";
import planningAheadArticleSchemaData from "./schema.article.json";
import seoData from "./seo.data.json";
import PageHeader from "components/CBPageHeader";
import { StyledSectionBackgroundWrapper } from "index.styles";

const PlanningAhead = () => {
  const schemaData = constructSchemaData(planningAheadSchemaData.url, planningAheadSchemaData.breadcrumbs, planningAheadArticleSchemaData);
  return (
    <StyledMainPageContainer id="planning-ahead-page-container">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <StyledSectionBackgroundWrapper>
        <PageHeader
          section="planning-ahead"
          header="Planning Ahead for \n Ketamine-Assisted Therapy"
          headerAccent={["Ketamine-Assisted Therapy"]}
          br="true"
          lightText="true"
        />
      </StyledSectionBackgroundWrapper>
      <PlanningContent />
      <QuickTips />
      <Precautions />
      <CBDivider sections={"precautions-and-technology"} />
      <Technology />
    </StyledMainPageContainer>
  );
};

export default PlanningAhead;
