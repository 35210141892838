import React, { useContext } from "react";
import { StyledMainPageContainer } from "index.styles";
import { Grid } from "@mui/material";
import CartItem from "./components/cart-item";
import CartSidebar from "./components/cart-sidebar";
import { StyledCartSectionContainer } from "./index.styles";
import { CartContext } from "utils/context/cart.context";
import EmptyCart from "./components/empty-cart";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useLocation } from "react-router-dom";
import theme from "assets/theme";
import MKTypography from "components/MKTypography";
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";
// Data
import seoData from "./seo.data.json";
import cartSchemaData from "./schema.organization.json";
import { constructSchemaData } from "utils/helpers/constructSchemaData";

const Cart = () => {
  const { cartItems, cartTotal } = useContext(CartContext);
  const schemaData = constructSchemaData(cartSchemaData.url, cartSchemaData.breadcrumbs);
  const location = useLocation();
  return (
    <StyledMainPageContainer id="cart-page">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <StyledCartSectionContainer>
        {cartItems.length <= 0 ? (
          <EmptyCart />
        ) : (
          <Grid container spacing={2} sx={{ justifyContent: "center" }}>
            <Grid item xs={12}>
              <Link
                to={location?.state?.previousUrl || "/ketamine-therapy/packages"}
                state={{ productId: location.state?.productId }}
                style={{ color: theme.palette.secondary.main, textDecoration: "none", display: "flex", alignItems: "center" }}
              >
                <ArrowBackIcon sx={{ marginRight: ".5rem", height: "18px", width: "18px" }} />
                <MKTypography sx={{ color: theme.palette.secondary.main, lineHeight: 1 }} component="p" variant="body2">
                  Continue Shopping
                </MKTypography>
              </Link>
            </Grid>
            <CartItem item xs={12} md={8} cartItems={cartItems} />
            <CartSidebar item xs={12} md={4} cartTotal={cartTotal} cartItems={cartItems} />
          </Grid>
        )}
      </StyledCartSectionContainer>
    </StyledMainPageContainer>
  );
};

export default Cart;
