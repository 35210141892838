// Major
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Container, Grid } from "@mui/material";

// Components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import HeaderContainer from "styles/components/headerContainer";

// Styles
import { ImageContainer } from "styles/components/imageContainer";
import ThreeStackCard from "styles/components/threeStackCard";
import theme from "assets/theme";
import { StyledSectionContainerWhiteWrapper, StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import { StyledCarasolContainer } from "./index.styles";

const ResourcesSlider = ({ ...props }) => {
  const { cards } = props;
  const [carouselWidth, setCarouselWidth] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  useEffect(() => {
    const updateWidth = () => {
      const cardWidth = 350;
      setCarouselWidth(cardWidth * 4.5);
      setWindowWidth(window.innerWidth - cardWidth);
    };
    updateWidth();
    window.addEventListener("resize", updateWidth);

    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  //   const cardInfo = [
  //     {
  //       image: "https://almadelic-assets.s3.us-east-2.amazonaws.com/planningAhead/planning-ahead-section/new+image.webp",
  //       alt: "Image of a person scheduling a consultation",
  //       info: "Planning Ahead",
  //       link: "/ketamine-therapy/planning-ahead",
  //     },
  //     {
  //       image: "https://d3a0rs3velfrz.cloudfront.net/preparation/pageHeader-section/pageHeaderOne.webp",
  //       alt: "Image of a person scheduling a consultation",
  //       info: "Session Prepartions",
  //       link: "/ketamine-therapy/session-preparation",
  //     },
  //     {
  //       image: "https://d3a0rs3velfrz.cloudfront.net/musicTherapy/musicTherapy-One.webp",
  //       alt: "Image of a person scheduling a consultation",
  //       info: "Music and Therapy",
  //       link: "/ketamine-therapy/music-and-therapy",
  //     },
  //     {
  //       image: "https://d3a0rs3velfrz.cloudfront.net/creatingYourSpace/creating-your-space-1.webp",
  //       alt: "Image of a person scheduling a consultation",
  //       info: "Creating Your Space",
  //       link: "/ketamine-therapy/creating-your-space",
  //     },
  //     {
  //       image: "https://almadelic-assets.s3.us-east-2.amazonaws.com/ketamineHub/pexels-julia-larson-6113422+(1).webp",
  //       alt: "Image of a person scheduling a consultation",
  //       info: "Role of Chaperone",
  //       link: "/ketamine-therapy/adult-chaperone",
  //     },
  //   ];

  const Cards = () => {
    const topComponent = (item) => {
      return (
        <Container sx={{ padding: "0rem !important", margin: "0rem !important" }}>
          <ImageContainer image={item.image} alt={item.alt} />
        </Container>
      );
    };

    const bottomComponent = (item) => {
      return (
        <MKBox sx={{ margin: "0.5rem" }}>
          <MKButton variant="contained" color="secondary" sx={{ width: "100%" }} component={Link} to={item.link}>
            {item.text}
          </MKButton>
        </MKBox>
      );
    };

    return (
      <StyledCarasolContainer>
        <motion.div
          drag="x"
          dragConstraints={{ left: -carouselWidth, right: 0 }}
          initial={{ x: windowWidth }}
          animate={{ x: -carouselWidth }}
          transition={{
            repeat: Infinity,
            repeatType: "reverse",
            duration: 25,
            ease: "linear",
          }}
          style={{ display: "flex", width: "100%" }}
        >
          {cards.map((card, index) => (
            <motion.div key={index} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <MKBox sx={{ minWidth: "300px !important", maxWidth: "400px !important", margin: "1rem 2rem" }}>
                <ThreeStackCard topComponent={topComponent(card)} midComponent={null} botComponent={bottomComponent(card)} />
              </MKBox>
            </motion.div>
          ))}
        </motion.div>
      </StyledCarasolContainer>
    );
  };

  return (
    <StyledSectionContainerWhiteWrapper id="ketamin-therapist-hub-ketamine-tools">
      <StyledSectionContainer>
        <HeaderContainer
          cssProps={{ textAlign: "center" }}
          componet={"h2"}
          variant={"h1"}
          title="At-Home Ketamine Assisted Therapy Resourses"
          textColor={theme.palette.primary.main}
          highlightText={"Resourses"}
          highlightColor={theme.palette.accent.main}
        />
      </StyledSectionContainer>
      <Cards />
    </StyledSectionContainerWhiteWrapper>
  );
};

export default ResourcesSlider;
