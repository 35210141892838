import React, { useContext } from "react";
// Components
import PageFooter from "components/CBPageFooter";
import SchemaComponent from "components/CBSchema";
import SEO from "components/CBSEO";
import CBLoading from "components/CBLoading";
import PageHeader from "components/CBPageHeader";
import FAQContent from "components/CBFAQ";
import SearchBarComponent from "components/CBSearchBar";
import { Container } from "@mui/material";
// Styled Components
import { StyledMainPageContainer } from "index.styles";
import { StyledSectionBackgroundWrapper } from "index.styles";
import { StyledFaqContainer } from "index.styles";
// Utils
import { faqFilterFunction } from "utils/helpers/searchBarFilters/faq.filter";
import { SearchBar } from "utils/context/searchBar.context";
import { constructSchemaData } from "utils/helpers/constructSchemaData";
import { SearchBarProvider } from "utils/context/searchBar.context";
// Data
import faqSchemaData from "./schema.organization.json";
import seoData from "./seo.data.json";
import faqData from "./faq.data.json";

const Faq = () => {
  return (
    <SearchBarProvider initialData={faqData} filterFunction={faqFilterFunction}>
      <FaqContent />
    </SearchBarProvider>
  );
};

const FaqContent = () => {
  const schemaData = constructSchemaData(faqSchemaData.url, faqSchemaData.breadcrumbs);
  const { filteredData } = useContext(SearchBar);

  return (
    <StyledMainPageContainer id="faq-page-container">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <StyledSectionBackgroundWrapper>
        <PageHeader
          header="Frequently Asked Questions \n About Psychedelic Therapy"
          headerAccent="About Psychedelic Therapy"
          headerTagline="Search our FAQ for answers to anything you might ask."
          lightText="true"
        />
      </StyledSectionBackgroundWrapper>

      <Container sx={{ marginTop: "2rem" }}>
        <SearchBarComponent placeholder="Search by billing, refunds, safety, treatments..." color="#000" />
      </Container>

      {filteredData ? (
        <StyledFaqContainer>
          <FAQContent items={filteredData} />
        </StyledFaqContainer>
      ) : (
        <CBLoading />
      )}
      <PageFooter content="Didn't Find What You Are Looking For?" to="/contact-us" buttonText={"Contact Our Team Today!"} internalLink="true" />
    </StyledMainPageContainer>
  );
};

export default Faq;
