import React from "react";
import { StyledCreatingYourSpaceMainPageContainer } from "index.styles";
import Hero from "./components/Hero";
import AllCourses from "./components/AllCourses";
import PageFooter from "./components/PageFooter";
import SEO from "components/CBSEO";
import SchemaComponent from "components/CBSchema";
// Data
import seoData from "./seo.data.json";
import educationalSchemaData from "./schema.data.json";

// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";

const EducationalCourses = () => {
  const schemaData = constructSchemaData(educationalSchemaData.url, educationalSchemaData.breadcrumbs);
  return (
    <StyledCreatingYourSpaceMainPageContainer>
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <Hero />
      <AllCourses />
      <PageFooter />
    </StyledCreatingYourSpaceMainPageContainer>
  );
};

export default EducationalCourses;
