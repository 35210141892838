// Major
import React from "react";
import { Grid } from "@mui/material";

// Components
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import MKTypography from "components/MKTypography";
import HeaderContainer from "styles/components/headerContainer";
import { ImageContainer } from "styles/components/imageContainer";
import { TwoColumnSection } from "styles/components/twoColumnSection";
import MKButton from "components/MKButton";

// Styles
import theme from "assets/theme";
import { Link } from "react-router-dom";
import { StyledButtonContainer } from "index.styles";

const GetStartedToday = ({ ...props }) => {
  const { leftColumn, rightColumn } = props;

  const rightContent = () => {
    const { title, highlightText, description, cta } = rightColumn;
    return (
      <Grid item xs={12} md={6}>
        <HeaderContainer
          title={title}
          variant="h2"
          component="h3"
          textColor={theme.palette.primary.main}
          highlightText={highlightText}
          highlightColor={theme.palette.accent.main}
        />
        <MKTypography variant="body1" fontWeight={400} gutterBottom>
          {description}
        </MKTypography>
        <StyledButtonContainer>
          <MKButton variant="contained" color="secondary" size="large" component={Link} to={cta.link}>
            {cta.text}
          </MKButton>
        </StyledButtonContainer>
        <MKTypography variant="body2" color="accent" fontWeight={400} gutterBottom sx={{ padding: "1rem 0rem !important" }}>
          (Just takes 2 minutes)
        </MKTypography>
      </Grid>
    );
  };

  const leftContent = () => {
    const { image } = leftColumn;
    return (
      <Grid item xs={12} md={6}>
        <ImageContainer image={image.imageUrl} alt={image.alt} />
      </Grid>
    );
  };

  return (
    <StyledSectionContainer id="ketamine-hub-get-started">
      <TwoColumnSection leftComponent={leftContent()} rightComponent={rightContent()} />
    </StyledSectionContainer>
  );
};

export default GetStartedToday;
