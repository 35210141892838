import React from "react";
import { Grid, Stack } from "@mui/material";
import theme from "assets/theme";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TAUSAMap from "components/TAUSAMap";
import { StyledButtonContainer } from "index.styles";
import { Link, useNavigate } from "react-router-dom";
import { StyledPageContainer } from "styles/components/PageContainer/index.styles";
import { StyledSectionContainer } from "styles/components";
import HeaderContainer from "styles/components/headerContainer";
import { TwoColumnSection } from "styles/components/twoColumnSection";
import useMediaQueries from "utils/mediaQueries.utils";

const LeftComponent = () => {
  const { isLg } = useMediaQueries();
  return (
    <Grid item xs={12} lg={6}>
      <Stack>
        <HeaderContainer
          textColor="#1D3A6D"
          highlightColor="#75CDC5"
          element="h1"
          variant="h1"
          title="Ketamine Therapy Is \n Unavailable in Your Area"
          highlightText={["Unavailable in Your Area"]}
          cssProps={{ margin: "0 0 1rem 0 !important" }}
        />
        <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
          We're sorry, but it looks like we don't currently offer ketamine therapy in your area. Here are some other states where we do offer ketamine
          therapy.
        </MKTypography>
        <StyledButtonContainer>
          <MKButton component={Link} to="/contact-us" variant="contained" color="secondary">
            Contact Us
          </MKButton>
        </StyledButtonContainer>
      </Stack>
    </Grid>
  );
};

const RightComponent = () => {
  const { isLg } = useMediaQueries();
  const navigate = useNavigate();
  const stateCustomConfig = {
    "CO": {
      fill: theme.palette.secondary.main,
      clickHandler: (event) => navigate("/ketamine-therapy/colorado"),
    },
    "FL": {
      fill: theme.palette.secondary.main,
      clickHandler: (event) => navigate("/ketamine-therapy/florida"),
    },
    "OH": {
      fill: theme.palette.secondary.main,
      clickHandler: (event) => navigate("/ketamine-therapy/ohio"),
    },
  };
  return (
    <Grid item xs={12} lg={5}>
      <TAUSAMap stateCustomConfig={stateCustomConfig} height={isLg ? "400px" : "300px"} />
    </Grid>
  );
};

const StateNotFound = () => {
  return (
    <StyledPageContainer id="no-state-found-container">
      <StyledSectionContainer id="no-state-found-section">
        <TwoColumnSection
          rowSpacing={0}
          columnSpacing={0}
          rowGap={6}
          columnGap={6}
          leftComponent={<LeftComponent />}
          rightComponent={<RightComponent />}
        />
      </StyledSectionContainer>
    </StyledPageContainer>
  );
};

export default StateNotFound;
