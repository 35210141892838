import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

const SimpleDataTable = (data) => {
  return (
    <TableContainer component={Paper} sx={{ marginTop: "1rem" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Type of Information</TableCell>
            <TableCell>How We Use It</TableCell>
            <TableCell>Who We Share It With</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.data.map(({ typeOfInformation, howWeUseIt, whoWeShareItWith }, index) => (
            <TableRow
              key={index}
              sx={{
                backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff", // Zebra striping
              }}
            >
              <TableCell component="th" scope="row">
                {typeOfInformation}
              </TableCell>
              <TableCell>{howWeUseIt}</TableCell>
              <TableCell>{whoWeShareItWith}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SimpleDataTable;
