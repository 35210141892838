import React, {useState, useEffect} from "react";
import { Grid, Stack, Container } from "@mui/material";

import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import HeaderContainer from "styles/components/headerContainer";
import { ImageContainer } from "styles/components/imageContainer";
import ThreeStackCard from "styles/components/threeStackCard";
import MKTypography from "components/MKTypography";

import {motion} from "framer-motion";
import MKBox from "components/MKBox";
import theme from "assets/theme";

const RealPeopleRealResults = ({content}) => {
    

    const [carouselWidth, setCarouselWidth] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    

    useEffect(() => {
        const updateWidth = () => {
            const cardWidth = 350;
            setCarouselWidth(cardWidth * 3.5);
            setWindowWidth(window.innerWidth - cardWidth);
        }
        updateWidth();
        window.addEventListener("resize", updateWidth);

        return () => window.removeEventListener("resize", updateWidth);
    }, []);
    
    if(!content) return null;

    const cardInfo = [
        {
            name: "Joey D.",
            title: "",
            review: "I had a transformative experience with the ketamine therapy service from this site. The guidance and support provided were exceptional, and I feel a positive shift in my well-being.",
        },
        {
            name: "Alice M.",
            title: "",
            review: "I decided to try ketamine therapy through this site, and it exceeded my expectations. The professional and compassionate approach of the therapists made me feel comfortable throughout the process. I highly recommend it.",
        },
        {
            name: "Bob S.",
            title: "",
            review: "The ketamine therapy service offered here made a significant difference in my life. The therapists were knowledgeable, and the online platform provided a convenient and secure way to access this transformative treatment.",
        },
        {
            name: "Emily P.",
            title: "",
            review: "I'm grateful for the ketamine therapy service available on this site. The user-friendly interface and the support from the staff made the entire process seamless. It's been a positive and beneficial journey for me.",
        }
    ]

    const Cards = () => {
        const reviewComponent = (item) => {
            return(
                <Container sx={{textAlign: "center"}}>
                    <MKTypography variant="body2" component="p" color="primary">{item.review}</MKTypography>
                </Container>
            )
        }
        const imageComponent = (item) => {
            return(
                <MKBox sx={{width: "75px", margin: "1rem 0rem"}}>
                    <ImageContainer image="https://almadelic-assets.s3.us-east-2.amazonaws.com/logos/almadelicButterFlyTransparent.webp" alt="butterfly image logo" />
                </MKBox>
            )
        }

        const nameComponent = (item) => {
            return(
                <Container sx={{textAlign: "center", margin: "0.5rem"}}>
                    <MKTypography variant="h6" component="h3" color="primary">{item.name}</MKTypography>
                    {item.title !== "" ? <MKTypography variant="body1" component="p" color="primary">{item.title}</MKTypography> : null }
                </Container>
            )
        }

        return(
            <motion.div
                drag="x"
                dragConstraints={{ left: -carouselWidth , right: 0 }}
                initial={{ x: windowWidth }}
                animate={{ x: -carouselWidth }}
                transition={{
                    repeat: Infinity,
                    repeatType: "reverse",
                    duration: 25,
                    ease: "linear"
                }}
                style={{display: "flex", width: "100%"}}
            >
                {cardInfo.map((item, index) => (
                    <motion.div
                        key={index}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        style={{minWidth: "350px", maxWidth: "400px", margin: "1rem 2rem",padding:"1rem", display: "flex", justifyContent: "center"}}
                    >
                        <ThreeStackCard topComponent={nameComponent(item)} midComponent={imageComponent(item)} botComponent={reviewComponent(item)} />    
                    </motion.div>
                ))}
            </motion.div>
        )
    }



    return (
        <StyledSectionContainer id="ketemaine-therapy-state-real-people-real-results-section">
            <HeaderContainer cssProps={{textAlign: "center"}} component="h2" variant="h1" title="Real People, Real Results" highlightColor={theme.palette.accent.main} highlightText={"Real Results"} />
            <MKTypography sx={{textAlign: "center"}} variant="body1" component="p" color="white">{content.description}</MKTypography>
            <Cards />
        </StyledSectionContainer>
    );  
}

export default RealPeopleRealResults;