export const faqFilterFunction = (data = [], searchTermsSet) => {
  if (searchTermsSet.size === 0) {
    return data;
  }

  const normalizeAndLowerCase = (value) => String(value).trim().toLowerCase();

  const searchTermString = normalizeAndLowerCase([...searchTermsSet].join(" "));

  return data
    .map((category) => {
      const faqsMatch = category?.FAQs?.filter((faq) => normalizeAndLowerCase(faq.question).includes(searchTermString));

      if (faqsMatch?.length > 0) {
        return {
          ...category,
          FAQs: faqsMatch,
        };
      }

      return null;
    })
    .filter(Boolean);
};
