import React from "react";

import { ImageContainer } from "styles/components/imageContainer";
import MKBox from "components/MKBox";
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import HeaderContainer from "styles/components/headerContainer";
import MKTypography from "components/MKTypography";

import theme from "assets/theme";
import MKButton from "components/MKButton";
import { Link } from "react-router-dom";

const JoinUs = () => {
  return (
    <StyledSectionContainer>
      <HeaderContainer
        cssProps={{ textAlign: "center" }}
        title={"Want To Join Us?"}
        textColor={theme.palette.white.main}
        highlightText={"Join"}
        highlightColor={theme.palette.accent.main}
        variant="h1"
        component="h2"
      />
      <MKBox sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
        <MKTypography component="h3" variant="h3" sx={{ color: "white !important", textAlign: "center" }}>
          Looking to join Almadelic? Contact us Today
        </MKTypography>
        <MKButton component={Link} to="/contact-us" variant="contained" color="secondary" size="large" sx={{ marginTop: "1rem" }}>
          Contact Us
        </MKButton>
      </MKBox>
    </StyledSectionContainer>
  );
};

export default JoinUs;
