import { useMediaQuery } from "@mui/material";
import theme from "assets/theme";

const useMediaQueries = () => {
  const isLg = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"), {
    defaultMatches: true,
  });

  

  const isXl = useMediaQuery(theme.breakpoints.up("xl"));

  const isXxl = useMediaQuery(theme.breakpoints.up("xxl"));

  const isNavMenu = useMediaQuery(theme.breakpoints.down("navMenu"), {
    defaultMatches: true,
  });
  return {
    isSm,
    isSmUp,
    isLg,
    isMd,
    isMdDown,
    isXl,
    isXxl,
    isNavMenu,
    //! still need to add the rest of the mediaqueries
  };
};

export default useMediaQueries;
