import * as Yup from "yup";
const stateOptions = [
  { value: "Alabama", label: "Alabama" },
  { value: "Alaska", label: "Alaska" },
  { value: "Arizona", label: "Arizona" },
  { value: "Arkansas", label: "Arkansas" },
  { value: "California", label: "California" },
  { value: "Colorado", label: "Colorado" },
  { value: "Connecticut", label: "Connecticut" },
  { value: "Delaware", label: "Delaware" },
  { value: "Florida", label: "Florida" },
  { value: "Georgia", label: "Georgia" },
  { value: "Hawaii", label: "Hawaii" },
  { value: "Idaho", label: "Idaho" },
  { value: "Illinois", label: "Illinois" },
  { value: "Indiana", label: "Indiana" },
  { value: "Iowa", label: "Iowa" },
  { value: "Kansas", label: "Kansas" },
  { value: "Kentucky", label: "Kentucky" },
  { value: "Louisiana", label: "Louisiana" },
  { value: "Maine", label: "Maine" },
  { value: "Maryland", label: "Maryland" },
  { value: "Massachusetts", label: "Massachusetts" },
  { value: "Michigan", label: "Michigan" },
  { value: "Minnesota", label: "Minnesota" },
  { value: "Mississippi", label: "Mississippi" },
  { value: "Missouri", label: "Missouri" },
  { value: "Montana", label: "Montana" },
  { value: "Nebraska", label: "Nebraska" },
  { value: "Nevada", label: "Nevada" },
  { value: "New Hampshire", label: "New Hampshire" },
  { value: "New Jersey", label: "New Jersey" },
  { value: "New Mexico", label: "New Mexico" },
  { value: "New York", label: "New York" },
  { value: "North Carolina", label: "North Carolina" },
  { value: "North Dakota", label: "North Dakota" },
  { value: "Ohio", label: "Ohio" },
  { value: "Oklahoma", label: "Oklahoma" },
  { value: "Oregon", label: "Oregon" },
  { value: "Pennsylvania", label: "Pennsylvania" },
  { value: "Rhode Island", label: "Rhode Island" },
  { value: "South Carolina", label: "South Carolina" },
  { value: "South Dakota", label: "South Dakota" },
  { value: "Tennessee", label: "Tennessee" },
  { value: "Texas", label: "Texas" },
  { value: "Utah", label: "Utah" },
  { value: "Vermont", label: "Vermont" },
  { value: "Virginia", label: "Virginia" },
  { value: "Washington", label: "Washington" },
  { value: "West Virginia", label: "West Virginia" },
  { value: "Wisconsin", label: "Wisconsin" },
  { value: "Wyoming", label: "Wyoming" },
];
const licenseOptions = [
  { value: "CAT", label: "Licensed Creative Arts Therapist" },
  { value: "Clinical Psychologist", label: "Clinical Psychologist" },
  { value: "LCPC", label: "Licensed Clinical Professional Counselor" },
  { value: "LCSW", label: "Licensed Clinical Social Worker" },
  { value: "LICSW", label: "Licensed Independent Clinical Social Worker" },
  { value: "LMFT", label: "Licensed Marriage and Family Therapist" },
  { value: "LMHC", label: "Licensed Mental Health Counselor" },
  { value: "LMSW", label: "Licensed Master Social Worker" },
  { value: "LPCC", label: "Licensed Professional Clinical Counselor" },
  { value: "LPC", label: "Licensed Professional Counselor" },
  { value: "State of Oregon Facilitator License", label: "State of Oregon Facilitator License" },

  { value: "Other", label: "Other" },
];
const specialtiesOptions = [
  { value: "Anxiety", label: "Anxiety" },
  { value: "PTSD", label: "PTSD" },
  { value: "Depression", label: "Depression" },
  { value: "OCD", label: "OCD" },
  { value: "Bi-polar", label: "Bi-polar" },
  { value: "Phobias", label: "Phobias" },
  { value: "Panic Disorders", label: "Panic Disorders" },
  { value: "Addictions", label: "Addictions" },
  { value: "Eating Disorders", label: "Eating Disorders" },
  { value: "Personality Disorders", label: "Personality Disorders" },
  { value: "Sexual Disorders", label: "Sexual Disorders" },
];
const therapiesOptions = [
  { value: "Humanistic", label: "Humanistic" },
  { value: "Psychodynamic", label: "Psychodynamic" },
  { value: "Cognitive", label: "Cognitive" },
  { value: "Integrative/Holistic", label: "Integrative/Holistic" },
  { value: "Group", label: "Group" },
  { value: "Mindfulness", label: "Mindfulness" },
  { value: "Existential", label: "Existential" },
  { value: "Christian-Based", label: "Christian-Based" },
  { value: "Adlerian", label: "Adlerian" },
  { value: "Biodynamic", label: "Biodynamic" },
  { value: "Mentalization", label: "Mentalization" },
  { value: "Interpersonal", label: "Interpersonal" },
  { value: "Acceptance and Commitment", label: "Acceptance and Commitment" },
  { value: "Family", label: "Family" },
  { value: "Couples", label: "Couples" },
  { value: "Attachment", label: "Attachment" },
  { value: "Exposure", label: "Exposure" },
  { value: "Coherence", label: "Coherence" },
  { value: "Eye Movement Desensitization", label: "Eye Movement Desensitization" },
  { value: "Art", label: "Art" },
  { value: "Behavioral", label: "Behavioral" },
  { value: "Brain Stimulation", label: "Brain Stimulation" },
  { value: "Psychoanalysis", label: "Psychoanalysis" },
  { value: "Bibliotherapy", label: "Bibliotherapy" },
  { value: "Play", label: "Play" },
  { value: "Culturally Sensitive", label: "Culturally Sensitive" },
];
const psychedelicTherapyTypes = [
  { value: "Breezing", label: "Breezing" },
  { value: "Ketamine", label: "Ketamine" },
  { value: "Psilocybin", label: "Psilocybin" },
  { value: "MDMA", label: "MDMA" },
  { value: "Other", label: "Other" },
];
const methodsOptions = [
  { value: "ACT", label: "ACT" },
  { value: "CBT", label: "CBT" },
  { value: "DBT", label: "DBT" },
  { value: "Ego State/Parts Work", label: "Ego State/Parts Work" },
  { value: "Supportive Psychotherapy", label: "Supportive Psychotherapy" },
  { value: "Psychodynamic Psychotherapy", label: "Psychodynamic Psychotherapy" },
  { value: "IFS", label: "IFS" },
  { value: "Other", label: "Other" },
];
const modalitiesOptions = [
  { value: "AEDP", label: "Accelerated Experiential Dynamic Psychotherapy" },
  { value: "Brainspotting", label: "Brainspotting" },
  { value: "EMDR", label: "EMDR" },
  { value: "Energy medicine", label: "Energy medicine" },
  { value: "Hakomi", label: "Hakomi" },
  { value: "Holotropic Breathwork", label: "Holotropic Breathwork" },
  { value: "IFS", label: "IFS" },
  // { value: "KAP", label: "Ketamine Assisted Psychotherapy" },
  { value: "Psychedelic", label: "Psychedelic" },
  { value: "Ayahuasca", label: "Ayahuasca" },
  { value: "Meditation", label: "Meditation" },
  { value: "Neuro Linguistic Programming", label: "Neuro Linguistic Programming" },
  { value: "Sensorimotor", label: "Sensorimotor" },
  { value: "Somatic Experiencing", label: "Somatic Experiencing" },
  { value: "Yoga", label: "Yoga" },
  { value: "Other", label: "Other" },
];
const settingsOptions = [
  { value: "Clinical/Medical", label: "Clinical/Medical" },
  { value: "Psychotherapeutic", label: "Psychotherapeutic" },
  { value: "Naturalistic", label: "Naturalistic" },
  { value: "Group", label: "Group" },
  { value: "Solo", label: "Solo" },
  { value: "Spiritual/Religious- General", label: "Spiritual/Religious- General" },
  { value: "Spiritual-Shamanic", label: "Spiritual-Shamanic" },
  { value: "Spiritual-Entheogenic", label: "Spiritual-Entheogenic" },
];
const educationOptions = [
  { value: "Undergraduate", label: "Undergraduate" },
  { value: "Masters Degree", label: "Masters Degree" },
  { value: "PhD", label: "PhD" },
  { value: "Other, Please Specify", label: "Other, Please Specify" },
];
const languageOptions = [
  { value: "English", label: "English" },
  { value: "Spanish", label: "Spanish" },
  { value: "Mandarin", label: "Mandarin" },
  { value: "French", label: "French" },
  { value: "German", label: "German" },
  { value: "Japanese", label: "Japanese" },
  { value: "Korean", label: "Korean" },
  { value: "Portuguese", label: "Portuguese" },
  { value: "Russian", label: "Russian" },
  { value: "Italian", label: "Italian" },
  { value: "Arabic", label: "Arabic" },
  { value: "Hindi", label: "Hindi" },
  { value: "Bengali", label: "Bengali" },
  { value: "Urdu", label: "Urdu" },
  { value: "Turkish", label: "Turkish" },
  { value: "Vietnamese", label: "Vietnamese" },
  { value: "Thai", label: "Thai" },
  { value: "Dutch", label: "Dutch" },
  { value: "Greek", label: "Greek" },
  { value: "Swedish", label: "Swedish" },
  { value: "Polish", label: "Polish" },
  { value: "Hebrew", label: "Hebrew" },
  { value: "Other", label: "Other" },
];
const professionalRoleOptions = [
  { value: "Facilitator", label: "Facilitator" },
  { value: "Therapist", label: "Therapist" },
  { value: "Therapist & Facilitator", label: "Therapist & Facilitator" },
];
export const steps = [
  {
    sectionName: "Create Your Profile",
    sectionTitle: "Start building your profile for our directory.",
    sectionSubTitle: "Provide essential information to help us connect you with the right clients and opportunities.",
    questions: [
      { name: "firstName", type: "text", label: "First Name" },
      { name: "lastName", type: "text", label: "Last Name" },
      { name: "email", type: "text", label: "Email" },
      { name: "phone", type: "tel", label: "Phone" },
      { name: "city", type: "text", label: "City" },
      {
        name: "state",
        type: "select",
        label: "State",
        options: stateOptions,
        multi: false,
      },
      { name: "password", type: "password", label: "Password" },
      { name: "confirmPassword", type: "confirmPassword", label: "Confirm Password" },
      { name: "profileImage", type: "file", label: "Upload Your Profile Image" },
      {
        name: "professionalRole",
        type: "select",
        label: "Select A Professional Role",
        options: professionalRoleOptions,
        multi: false,
      },
    ],
  },
  {
    sectionName: "Add Details About Your Practice",
    sectionTitle: "Please tell us more about your practice",
    sectionSubTitle: "Share details about your expertise and services to enhance your visibility and reach.",
    questions: [
      {
        name: "licensedStates",
        type: "select",
        // label: "What states are you licensed to practice in?",
        label: "States licensed to practice in",

        multi: true,
        options: stateOptions,
      },
      {
        name: "licenses",
        type: "select",
        // label: "What licenses do you have?",
        label: "Licenses held",

        multi: true,
        options: licenseOptions,
      },
      {
        name: "specialties",
        type: "select",
        label: "Specialties",
        multi: true,
        options: specialtiesOptions,
      },
      {
        name: "therapyTypes",
        type: "select",
        label: "Types of psychedelic treatment offered",
        multi: true,
        options: psychedelicTherapyTypes,
      },
      { name: "psychedelicEducation", type: "text", label: "Specific psychedelic education" },
      {
        name: "therapyTypesOffered",
        type: "select",
        label: "Types of treatments offered",
        multi: true,
        options: therapiesOptions,
      },
      {
        name: "treatmentMethods",
        type: "select",
        label: "Treatment methods practiced",
        multi: true,
        options: methodsOptions,
      },
      {
        name: "treatmentModalities",
        type: "select",
        label: "Treatment modalities practiced",
        multi: true,
        options: modalitiesOptions,
      },
      { name: "yearsOfExperience", type: "number", label: "Years of experience" },
      {
        name: "practiceSettings",
        type: "select",
        label: "Types of settings practiced in or offered",
        multi: true,
        options: settingsOptions,
      },
    ],
  },
  {
    sectionName: "Join Our Community",
    sectionTitle: "Let’s gather details For your profile",
    sectionSubTitle: "Complete your profile with accurate information to ensure you stand out in our directory.",
    questions: [
      { name: "linkedinProfile", type: "text", label: "LinkedIn profile URL" },
      { name: "website", type: "text", label: "Website URL" },
      {
        name: "professionalTitle",
        type: "text",
        label: "Professional title and/or credentials",
      },
      {
        name: "highestEducation",
        type: "select",
        label: "Highest level of education",
        multi: false,
        options: educationOptions,
      },
      {
        name: "languagesSpoken",
        type: "select",
        label: "Languages spoken",
        options: languageOptions,
        multi: true,
      },
      {
        name: "visitTypes",
        type: "select",
        label: "Visit types offered",
        multi: true,

        options: [
          { value: "In Person", label: "In person" },
          { value: "Virtual", label: "Virtual " },
          // Add more options as needed
        ],
      },
      {
        name: "acceptInsurance",
        type: "select",
        label: "Insurance acceptance",
        multi: false,
        options: [
          { value: "Yes", label: "Yes" },
          { value: "No", label: "No" },
        ],
      },
      { name: "hourlyRate", type: "number", label: "Hourly rate" },
      { name: "bio", type: "text", label: "Personal Bio" },
    ],
  },
];

// Define the validation schemas for each step
export const validationSchemas = [
  Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone number is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    password: Yup.string().required("Password is required").min(8, "Password must be at least 8 characters"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
    profileImage: Yup.mixed().required("Profile image is required"),
    "profile cropped image": Yup.mixed().required("Profile image is required"),
    professionalRole: Yup.mixed().required("Professional Role is required"),
  }),
  Yup.object({
    licensedStates: Yup.string(),
    licenses: Yup.string(),
    specialties: Yup.string(),
    therapyTypes: Yup.string(),
    psychedelicEducation: Yup.string(),
    therapyTypesOffered: Yup.string(),
    treatmentMethods: Yup.string(),
    treatmentModalities: Yup.string(),
    yearsOfExperience: Yup.string(),
    practiceSettings: Yup.string(),
  }),
  Yup.object({
    linkedinProfile: Yup.string().url("Invalid URL format. Use format: https://www.example.com"),
    website: Yup.string().url("Invalid URL format. Use format: https://www.example.com"),
    professionalTitle: Yup.string(),
    highestEducation: Yup.string(),
    languagesSpoken: Yup.string(),
    visitTypes: Yup.string(),
    acceptInsurance: Yup.string(),
    hourlyRate: Yup.string(),
    bio: Yup.string(),
  }),
];

// Define the initial values for the form
export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  city: "",
  state: "",
  password: "",
  confirmPassword: "",
  profileImage: null,
  licensedStates: "",
  licenses: "",
  specialties: "",
  therapyTypes: "",
  psychedelicEducation: "",
  therapyTypesOffered: "",
  treatmentMethods: "",
  treatmentModalities: "",
  yearsOfExperience: "",
  practiceSettings: "",
  linkedinProfile: "",
  website: "",
  professionalTitle: "",
  highestEducation: "",
  languagesSpoken: "",
  visitTypes: "",
  acceptInsurance: "",
  hourlyRate: "",
  bio: "",
  professionalRole: "",
};
