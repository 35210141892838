import React from "react";

// Components
import MKButton from "components/MKButton";
import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import { TwoColumnSection } from "styles/components/twoColumnSection";
import { ImageContainer } from "styles/components/imageContainer";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
import { StyledButtonContainer } from "index.styles";
import { useNavigate } from "react-router-dom";
// import { StyledSectionContainer } from "styles/components";
import HeaderContainer from "styles/components/headerContainer";
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";

//! - Suggestion/Thoughts - Not sure about the design/layout of this section, but might make sense to include an image in a two column layout

const LeftComponent = () => {
  const { isLg, isMd } = useMediaQueries();

  return (
    <Grid item xs={12} lg={5} order={isLg ? 1 : 0}>
      <ImageContainer
        cssProps={{ height: isMd ? "381px" : "100%" }}
        image="https://almadelic-assets.s3.us-east-2.amazonaws.com/faq/psychedelic-therapy.webp"
        alt="Ketamine Therapy"
      />
    </Grid>
  );
};

const RightComponent = () => {
  const { isLg } = useMediaQueries();
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/faq");
  };
  return (
    <Grid item xs={12} lg={6} order={isLg ? 0 : 1}>
      <HeaderContainer
        textColor="#1D3A6D"
        highlightColor="#75CDC5"
        component="h2"
        variant="h2"
        title="More FAQs About \n Psychedelic Therapy & Almadelic "
        highlightText="Psychedelic Therapy & Almadelic"
        cssProps={{ margin: "0 0 1rem 0" }}
      />
      <MKTypography component="p" variant={isLg ? "body1" : "body2"}>
        Looking for more information about psilocybin therapy, ketamine therapy or psychedelic therapy in general? Check out the FAQs page to learn more about these
        modalities, and how Almadelic connects patients with licensed mental health professionals across the U.S.
      </MKTypography>
      <StyledButtonContainer>
        <MKButton onClick={handleNavigate} variant="contained" color="secondary">
          Almadelic FAQs
        </MKButton>
      </StyledButtonContainer>
    </Grid>
  );
};

const MoreAboutFaq = () => {
  return (
    <StyledSectionContainer id="more-about-faq-section">
      <TwoColumnSection
        rowSpacing={0}
        columnSpacing={0}
        rowGap={6}
        columnGap={6}
        leftComponent={<LeftComponent />}
        rightComponent={<RightComponent />}
      />
    </StyledSectionContainer>
  );
};

export default MoreAboutFaq;
