import { createContext, useState } from "react";

export const ProfileBioContext = createContext({
  open: false,
  handleOpen: () => {},
  handleClose: () => {},
  currentUserData: null,
  setCurrentUserData: () => {},
});

export const ProfileBioProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [currentUserData, setCurrentUserData] = useState();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setCurrentUserData(null);
  };

  const value = { open, handleOpen, handleClose, currentUserData, setCurrentUserData };

  return <ProfileBioContext.Provider value={value}>{children}</ProfileBioContext.Provider>;
};
