/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Faq from "pages/Faq";
import WhyChooseUs from "pages/WhyChooseUs";
import TherapistProgram from "pages/TherapistProgram";
import FindATherapist from "pages/FindATherapist";
import OurTeam from "pages/OurTeam";
import Shop from "pages/Shop";
import TherapistBioEditable from "pages/TherapistBioEditable";
import Blog from "pages/Blog";
import KetamineTherapyHub from "pages/KetamineTherapyHub";
import AboutUs from "pages/AboutUs";
import TherapyHub from "pages/TherapyHub";
import AuthPage from "pages/Auth";

const routes = [
  {
    name: "About Us",
    route: "/about-us",
    component: <AboutUs />,
    key: "about-us",
  },

  {
    name: "Our Packages",
    collapse: [
      {
        name: "Ketamine Packages",
        route: "https://shop.almadelic.com/",
        // route: "/ketamine-therapy/packages",
        component: <Shop />,
        key: "our-packages",
      },
    ],
  },
  {
    name: "Our Services",
    collapse: [
      {
        name: "Ketamine Therapy",
        route: "/ketamine-therapy",
        // component: <KetamineTherapyHub />,
        component: <TherapyHub />,
        key: "ketamine-therapy",
      },
      {
        name: "Psilocybin Therapy",
        route: "/psilocybin-therapy",
        component: <TherapyHub />,
        key: "psilocybin-therapy",
      },
    ],
  },
  {
    name: "For Patients",
    collapse: [
      {
        name: "Find A Therapist",
        route: "/partner-providers",
        component: <FindATherapist />,
        key: "find-a-ketamine-therapist",
      },
      {
        name: "Account Order Information",
        route: "https://billing.stripe.com/p/login/test_9AQ3cO1rU1kwgfKeUU",
        key: "order-info",
      },
    ],
  },
  {
    name: "For Therapists",
    collapse: [
      {
        name: "Our Therapist Referral Program",
        route: "/partners",
        component: <TherapistProgram />,
        key: "partners",
      },
      {
        name: "Therapist Profile",
        route: "/therapist-bio-editable",
        component: <TherapistBioEditable />,
        key: "therapist-program",
        protected: true,
        requiredRole: "partnered-therapist",
      },
      {
        name: "Therapist Profile Sign In",
        route: "/therapist-auth/sign-in",
        component: <AuthPage />,
        key: "auth-page",
      },
    ],
  },
  {
    name: "FAQ's",
    collapse: [
      {
        name: "Search FAQ's",
        route: "/faq",
        component: <Faq />,
        key: "faq",
      },
    ],
  },
  {
    name: "Blog",
    route: "/blog",
    component: <Blog />,
    key: "blog",
  },
];

export default routes;
