import styled from "@emotion/styled";
import { Container, Stack } from "@mui/material";

export const StyledMenuContainer = styled(Container)(({ theme }) => ({
  padding: "2rem",
  backgroundColor: theme.palette.background.paper,
  borderRadius: "1rem",
  width: "100%",
  boxShadow: "0 10px 10px 0 rgba(0,0,0,0.1)",
  marginTop: "1rem",
  background: "rgba(255, 255, 255, .3)",
  boxShadow: `rgba(0, 0, 0, .1) 0px 6px 12px`,
  backdropFilter: "blur(8px)",
}));

export const StyledOptionStack = styled(Stack)(({ theme }) => ({
  padding: "1rem 0rem",
  borderRadius: "1rem",
  fontSize: "1.0rem",
  width: "100%",
}));
