import React, { useState } from "react";
// Styled Components
import { StyledDayOfSessionSectionContainer, StyledPreparationText, StyledDayOfSessionCardContainer } from "./index.styles";
import { StyledButtonContainer } from "index.styles";
import { StyledPreparationCard } from "index.styles";
import { StyledImage } from "index.styles";
// Components
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { Stack } from "@mui/material";
import HeaderContainer from "styles/components/headerContainer";

// Data
import dayOfSession from "./dayOfSession.config.json";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Theme
import theme from "assets/theme";
const DayOfSession = () => {
  const { isLg } = useMediaQueries();

  // Maintain an array of states for each section
  const [showSecondParagraph, setShowSecondParagraph] = useState(new Array(dayOfSession.length).fill(false));

  const toggleSecondParagraph = (index) => {
    const newShowStates = [...showSecondParagraph];
    newShowStates[index] = !newShowStates[index];
    setShowSecondParagraph(newShowStates);
  };

  return (
    <StyledDayOfSessionSectionContainer component="section" id="day-of-session-section">
      <HeaderContainer
        textColor={theme.palette.primary.main}
        highlightColor={theme.palette.accent.main}
        component="h2"
        variant="h2"
        title="The Day of Your Session"
        highlightText="Your Session"
        cssProps={{ margin: "0 0 2rem 0" }}
      />
      <StyledDayOfSessionCardContainer>
        {dayOfSession.map(({ title, description, imageUrl }, index) => {
          return (
            <StyledPreparationCard key={index}>
              <StyledImage dayofsession={true} height="275" width="268" src={imageUrl} alt={`Day of session image for - ${title}`}></StyledImage>
              <StyledPreparationText>
                <Stack direction={"row-reverse"} alignItems={"center"} justifyContent={"start"} spacing={".5rem"}>
                  <MKTypography component="h3" variant="h6" gutterBottom>
                    {title}
                  </MKTypography>
                  <img
                    src={"https://d3a0rs3velfrz.cloudfront.net/check.svg"}
                    alt="Check mark icon"
                    width={isLg ? "40px" : "25px"}
                    height={isLg ? "40px" : "25px"}
                  />
                </Stack>
                <>
                  {Array.isArray(description) ? (
                    <>
                      {description.slice(0, 1).map((desc, descIndex) => (
                        <MKTypography key={descIndex} component="p" variant={isLg ? "body1" : "body2"} gutterBottom>
                          {desc}
                        </MKTypography>
                      ))}
                      {description.length > 1 && (
                        <>
                          <MKTypography
                            component="p"
                            variant={isLg ? "body1" : "body2"}
                            gutterBottom
                            style={{
                              display: showSecondParagraph[index] ? "block" : "none",
                            }}
                          >
                            {description.slice(1).join(" ")}
                          </MKTypography>
                          <StyledButtonContainer>
                            <MKButton
                              color="secondary"
                              size={"small"}
                              onClick={() => toggleSecondParagraph(index)}
                              sx={{ fontSize: ".875rem", minWidth: "150px" }}
                              aria-label="View More"
                            >
                              {showSecondParagraph[index] ? "View Less" : "View More"}
                            </MKButton>
                          </StyledButtonContainer>
                        </>
                      )}
                    </>
                  ) : (
                    <MKTypography component="p" variant={isLg ? "body1" : "body2"} gutterBottom>
                      {description}
                    </MKTypography>
                  )}
                </>
              </StyledPreparationText>
            </StyledPreparationCard>
          );
        })}
      </StyledDayOfSessionCardContainer>
    </StyledDayOfSessionSectionContainer>
  );
};

export default DayOfSession;
