import React from "react";
// Components
import MKTypography from "components/MKTypography";
import ListComponent from "components/CBList";

// Styled Components
import { StyledSectionBackgroundWrapper } from "index.styles";
import { StyledQuickTipsSectionContainer } from "./index.styles";
import HeaderContainer from "styles/components/headerContainer";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";
// Data
import tipsData from "./tips.data.json";
// theme
import theme from "assets/theme";
const QuickTips = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledSectionBackgroundWrapper id="quick-tips-wrapper">
      <StyledQuickTipsSectionContainer component="section" id="quick-tips-section">
        <HeaderContainer
          textColor={theme.palette.white.main}
          highlightColor={theme.palette.accent.main}
          component="h2"
          variant="h2"
          title="Quick Tips"
          highlightText="Tips"
          cssProps={{
            margin: "0 0 1rem 0",
            borderBottom: "1px solid #fff",
            minHeight: "60px",
          }}
        />
        <ListComponent listItems={tipsData} color="#fff" listItemSpacing="1rem" />
      </StyledQuickTipsSectionContainer>
    </StyledSectionBackgroundWrapper>
  );
};

export default QuickTips;
