import styled from "@emotion/styled";
import { Container } from "@mui/material";

export const StyledMapContainer = styled(Container)(({ theme }) => ({
    
    padding: "0rem !important",
    margin: "0rem !important",
    "& path": {
        pointerEvents: "all",
        

    },
    "& path:hover": {
        opacity: 0.8,
        cursor: "pointer",
    },
}));