import React from "react";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { ImageContainer } from "styles/components/imageContainer";


const ImageSection = ({ imageURL, imageAlt, caption, cssProps }) => {
    return(
      <MKBox sx={{...cssProps}}>
        <ImageContainer image={imageURL} alt={imageAlt} />
        <MKTypography variant="body1" component="p" sx={{ marginBottom: "1rem" }}>
          {caption}
        </MKTypography>
      </MKBox>
    )
}

export default ImageSection;