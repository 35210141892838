import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Stack } from "@mui/material";
import { StyledLoadingSectionContainer } from "./index.styles";

export default function CBLoading({ loadingItem }) {
  return (
    <StyledLoadingSectionContainer>
      <Stack spacing="2rem" justifyContent={"center"} alignItems={"center"}>
        <MKBox component="img" src="https://d3a0rs3velfrz.cloudfront.net/home/hero/HeroImgSm.webp" sx={{ width: "400px" }}></MKBox>
        <Stack spacing="1rem" alignItems={"center"}>
          <CircularProgress />
          <MKTypography component="p" variant="h3">
            Loading {loadingItem || "data"}...
          </MKTypography>
        </Stack>
      </Stack>
    </StyledLoadingSectionContainer>
  );
}
