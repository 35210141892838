import React from "react";
import theme from "assets/theme";
// Components
import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
// Styled Components
import { StyledInsuranceCoverageContainer } from "./index.styles";
import { StyledImage } from "index.styles";
// Utils
import useMediaQueries from "utils/mediaQueries.utils";

const InsuranceCoverage = () => {
  const { isLg } = useMediaQueries();
  return (
    <StyledInsuranceCoverageContainer component="section" id="insurance-coverage-section">
      <Grid container spacing={isLg ? 8 : 4}>
        {/* Grid item - why insurance wont cover section */}
        <Grid item xs={12}>
          <Grid container spacing={isLg ? 8 : 4}>
            <Grid item xs={12} lg={6}>
              <StyledImage
                insurance={"true"}
                height="512"
                width="604"
                src={"https://d3a0rs3velfrz.cloudfront.net/insurance/insurance2.webp"}
                alt="Medical professional meeting with a patient over a virtual call. 
              "
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <MKTypography component={"h2"} variant={"h2"} gutterBottom>
                {/* Why is Ketamine Not Always Covered by Insurance? */}
                <span style={{ color: theme.palette.accent.main }}>Why Insurance</span> Does Not Always Cover Ketamine
              </MKTypography>
              <MKTypography component={"p"} variant={isLg ? "body1" : "body2"}>
                The absence of insurance coverage for ketamine therapy can be attributed to various factors. Insurance companies may refrain from
                covering it due to its "off-label" prescription status, as ketamine was initially FDA-approved in 1970 as an anesthetic. Although
                Esketamine, a derivative spray known as Spravato, received FDA approval in 2019 for treating treatment-resistant depression, its
                coverage varies depending on insurance type.{" "}
              </MKTypography>
              <MKTypography component={"p"} variant={isLg ? "body1" : "body2"} gutterBottom>
                The lack of FDA approval for generic ketamine specifically in depression and anxiety treatment leads some insurers to opt out.
                Encouragingly, ongoing research is progressing toward FDA approval for various psychedelic medicines, including the application of
                generic ketamine in treating a range of conditions.
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}></Grid>
        {/* Grid item - am i covered section */}
        <Grid item xs={12} textAlign={isLg && "center"}>
          <MKTypography component={"h3"} variant={"h3"} gutterBottom>
            Am I <span style={{ color: theme.palette.accent.main }}>Covered?</span>
          </MKTypography>
          <MKTypography component={"p"} variant={isLg ? "body1" : "body2"} gutterBottom>
            For individuals grappling with challenging mental health conditions, ketamine therapy emerges as a promising breakthrough in treatment.
            Despite its growing recognition as a game-changer for those seeking novel mental health solutions, a majority of insurance companies in
            the United States currently do not officially cover this innovative therapy.
          </MKTypography>
          <MKTypography component={"p"} variant={isLg ? "body1" : "body2"}>
            Amidst a significant mental health crisis in the U.S., with one in five adult Americans experiencing mental illness in 2020, millions are
            living with conditions like anxiety and depression. Facilitating healthcare coverage for emerging mental health treatments, such as
            ketamine therapy, through programs like Medicare and Medicaid is a crucial step in providing broader access to those in need.
          </MKTypography>
        </Grid>
      </Grid>
    </StyledInsuranceCoverageContainer>
  );
};

export default InsuranceCoverage;
