import React from "react";
import PropTypes from "prop-types";
import { StyledYTVideoContainer } from "./index.styles";


const TAEmbeddedYT = ({youtubeURL}) => {
    const urlObj = new URL(youtubeURL);
    const params = new URLSearchParams(urlObj.search);
    
    const embedId = params.get("v") ? params.get("v") : urlObj.pathname.split("/").pop();
    
    return(
        <StyledYTVideoContainer id="vyoutube-container">
            <iframe
                src={`https://www.youtube.com/embed/${embedId}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </StyledYTVideoContainer>
    )
};

TAEmbeddedYT.propTypes = {
    embedId: PropTypes.string.isRequired
};

export default TAEmbeddedYT;