import React from "react";
import { Helmet } from "react-helmet";

// This component is used to dynamically update the page title, meta description, and canonical URL for each page using react helmet
const SEO = ({ title, description, canonicalUrl, prerenderStatusCode }) => {
  const fullTitle = `${title}`;

  return (
    <Helmet>
      <title>{fullTitle}</title>
      {description && <meta name="description" content={description} />}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {prerenderStatusCode && <meta name="prerender-status-code" content={prerenderStatusCode}></meta>}
    </Helmet>
  );
};

export default SEO;
