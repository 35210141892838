import React from "react";
// Components
import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import HeaderContainer from "styles/components/headerContainer";
// Styled Components
import { StyledImportanceOfSettingSectionContainer } from "./index.style";
import { StyledSectionBackgroundWrapper, StyledImage } from "index.styles";
// Utils
import theme from "assets/theme";
import useMediaQueries from "utils/mediaQueries.utils";

export default function ImportanceOfSetting() {
  const { isMd, isLg } = useMediaQueries();
  return (
    <StyledSectionBackgroundWrapper id={"importance-of-setting-section-wrapper"}>
      <StyledImportanceOfSettingSectionContainer component={"section"} id={"importance-of-setting-section"}>
        <Grid container columnSpacing={isLg ? 6 : 3} rowSpacing={isLg ? 0 : 6} alignItems={"center"}>
          <Grid item xs={12} lg={6}>
            <StyledImage
              large={true}
              height="500"
              width="636"
              src={"https://d3a0rs3velfrz.cloudfront.net/creatingYourSpace/creating-your-space-1.webp"}
              alt="A cozy and well-lit living room featuring a mid-century modern aesthetic."
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox>
              <HeaderContainer
                textColor={theme.palette.accent.main}
                highlightColor={theme.palette.white.main}
                component="h1"
                variant="h1"
                title="Creating The Perfect Space\nFor Ketamine Therapy"
                highlightText="For Ketamine Therapy"
                cssProps={{ margin: "0 0 1rem 0" }}
              />
              <MKTypography variant={isMd ? "body1" : "body2"} gutterBottom sx={{ color: theme.palette.white.main }}>
                The landscape of ketamine therapy has broadened with emerging research indicating the safety of supervised ketamine use outside
                clinical settings.
              </MKTypography>
              <MKTypography variant={isMd ? "body1" : "body2"} gutterBottom sx={{ color: theme.palette.white.main }}>
                Some studies even suggest that a home environment might be more conducive to ketamine therapy. Consequently, at-home ketamine
                treatments have become a viable option, offering a safe and comfortable setting that can positively impact outcomes. Despite the
                inherent comfort of one's home, there are ways to enhance the environment for an optimal ketamine therapy experience.
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
      </StyledImportanceOfSettingSectionContainer>
    </StyledSectionBackgroundWrapper>
  );
}
