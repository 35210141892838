import React, { useContext } from "react";
import theme from "assets/theme";
import MKTypography from "components/MKTypography";
import useMediaQueries from "utils/mediaQueries.utils";

import { Link } from "react-router-dom";
import HeaderContainer from "styles/components/headerContainer";
import SearchBar from "components/CBSearchBar";
import { SearchBar as SearchBarContext } from "utils/context/searchBar.context";

const DirectoryHeader = ({ lightText, header, headerAccent, headerContent, br, link }) => {
  const { isLg } = useMediaQueries();
  const { searchTerms, setSearchBarTerms } = useContext(SearchBarContext);

  const handleOnSearchChange = (event) => {
    const searchField = event.target.value.toLocaleLowerCase();
    setSearchBarTerms(searchField);
  };

  return (
    <>
      <HeaderContainer
        textColor="#fff"
        highlightColor="#75CDC5"
        element="h1"
        variant="h1"
        title={header + headerAccent}
        highlightText={headerAccent}
        // newLine={true}
        cssProps={{ margin: "0rem 0rem 1rem 0rem !important" }}
      />
      <MKTypography
        component="p"
        variant={isLg ? "body1" : "body2"}
        sx={{ color: lightText ? "#fff !important" : `${theme.palette.primary.main} !important` }}
        gutterBottom
      >
        {headerContent}
      </MKTypography>

      <SearchBar
        filter={true}
        minusPadding="true"
        searchTerms={searchTerms}
        onChangeHandler={handleOnSearchChange}
        placeholder="Search by name, location, preferred language, or job title. "
      />
      {link && (
        <Link aria-label={link.linkAriaLabel} to={link.linkUrl} style={{ color: theme.palette.accent.main, fontSize: "18px" }}>
          <>
            <br />
          </>
          {link.linkText}
        </Link>
      )}
    </>
  );
};

export default DirectoryHeader;
