// Major
import React from "react";
import { Grid } from "@mui/material";

// Styles
import { StyledSectionContainer } from "styles/components/SectionContainer/index.styles";
import theme from "assets/theme";

// components
import { ImageContainer } from "styles/components/imageContainer";
import HeaderContainer from "styles/components/headerContainer";
import MKTypography from "components/MKTypography";
import FourSquareComponent from "styles/components/fourSquareComponent";

const KeyMechanics = ({ ...props }) => {
  const { title, cards } = props;

  const gridSection = (item) => {
    return (
      <Grid item xs={12} lg={5} sx={{ padding: "0rem 1rem" }}>
        <ImageContainer
          image={item.imageUrl}
          alt={item.title}
          cssProps={{
            width: "4.5rem",
            height: "auto",
          }}
        />
        <HeaderContainer title={item.title} component="h3" variant="h3" textColor={theme.palette.white.main} cssProps={{ textAlign: "center" }} />
        <MKTypography component="p" sx={{ textAlign: "center" }} color="white">
          {item.description}
        </MKTypography>
      </Grid>
    );
  };

  return (
    <StyledSectionContainer id="ketamin-therapist-hub-key-mechanics">
      <Grid container>
        <HeaderContainer
          cssProps={{ textAlign: "center", justifyContent: "center", marginTop: "0rem !important" }}
          highlightText={"Works"}
          highlightColor={theme.palette.accent.main}
          title={title}
          component={"h2"}
          variant={"h1"}
          textColor={theme.palette.white.main}
        />
        <FourSquareComponent
          component1={gridSection(cards[0])}
          component2={gridSection(cards[1])}
          component3={gridSection(cards[2])}
          component4={gridSection(cards[3])}
          dividerColor={theme.palette.white.main}
          imageURL={"https://almadelic-assets.s3.us-east-2.amazonaws.com/almadelicButterflies/alma_butterfly_gold.svg"}
        />
      </Grid>
    </StyledSectionContainer>
  );
};

export default KeyMechanics;
