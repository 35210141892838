import React, { useContext } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { SearchBar } from "utils/context/searchBar.context";
import { StyledSearchBarContainer, StyledSearchBarIconContainer, StyledSearchBarInputField } from "./index.styles";
import SearchFilter from "./components/searchFilter";
import SearchResultsSummary from "./components/searchResultsSummary";

const SearchBarComponent = ({ placeholder = "Enter search here...", filter = false, color }) => {
  const { inputRef, setSearchTerms } = useContext(SearchBar);

  // Handlers
  const handleOnSearchChange = (event) => {
    const searchField = event.target.value.toLocaleLowerCase();
    setSearchTerms(searchField);
  };
  return (
    <StyledSearchBarContainer>
      <StyledSearchBarIconContainer>
        <SearchIcon sx={{ color: "#fff" }} />
      </StyledSearchBarIconContainer>
      <StyledSearchBarInputField type="search" placeholder={placeholder} onChange={handleOnSearchChange} ref={inputRef} />
      <SearchResultsSummary color={color} />
      {filter && <SearchFilter />}
    </StyledSearchBarContainer>
  );
};

export default SearchBarComponent;
