// Major
import React from "react";

// Components
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Styles
import theme from "assets/theme";
import { Stack } from "@mui/material";
import { StyledProfileCardImage, StyledHeaderContainer, StyledEllipsisTypographyLink } from "./index.styles";
import { StyledSectionBackgroundWrapper } from "index.styles";

// Utils
import { findCustomFieldValue } from "utils/helpers/findCustomFieldValue";
import { convertState } from "utils/helpers/stateConverter";
import { formatPhoneNumber } from "utils/helpers/reformatPhone";
import useMediaQueries from "utils/mediaQueries.utils";

// Icons
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TAIconImage from "components/TAIconImage";

const Header = ({ currentUserData }) => {
  const { isLg, isSmUp, isMd } = useMediaQueries();
  const { email, phone, state, city, name, customFields } = currentUserData || [];
  const websiteURL = findCustomFieldValue("Website", customFields);
  const profileURL = findCustomFieldValue("Profile URL", customFields);
  const yearsPracticed = findCustomFieldValue("Years Practiced", customFields);
  const linkedIn = findCustomFieldValue("LinkedIn", customFields);
  const specialties = findCustomFieldValue("Specialties", customFields);

  const handleEmailClick = () => {
    if (email) {
      window.location.href = `mailto:${email}`;
    }
  };
  const handlePhoneClick = () => {
    if (phone) {
      window.location.href = `tel:+${phone}`;
    }
  };
  const buttonInfo = [
    {
      iconURL: "https://d3a0rs3velfrz.cloudfront.net/therapistBio/bioIcons/telephone-handle-silhouette.webp",
      alt: "Phone Icon",
      size: "sm",
    },
    {
      iconURL: "https://d3a0rs3velfrz.cloudfront.net/therapistBio/bioIcons/envelope.webp",
      alt: "Email Icon",
      size: "sm",
    },
  ];

  // Mappping through the buttonInfo array to render the buttons
  const ButtonOptions = () => {
    return (
      <Stack direction={isMd ? "column" : isSmUp ? "row" : "column"} spacing={2} sx={{ width: "100%", maxWidth: isLg ? "200px" : "100%" }}>
        {buttonInfo.map((button, index) => {
          return (
            <MKButton
              startIcon={<TAIconImage alt={button.alt} size={button.size} iconURL={button.iconURL} />}
              onClick={index === 0 ? handlePhoneClick : handleEmailClick}
              disabled={index === 0 ? !phone : !email}
              color="secondary"
              variant="outlined"
              sx={{ color: "#fff !important", width: isMd ? "100%" : isSmUp ? "50%" : "100%" }}
            >
              {index === 0 ? formatPhoneNumber(phone) || "Not Available" : "Email Me"}
            </MKButton>
          );
        })}
      </Stack>
    );
  };

  return (
    <StyledSectionBackgroundWrapper id="therapist-bio-header">
      <StyledHeaderContainer
        sx={{
          flexDirection: isSmUp ? "row" : "column",
          flexWrap: isMd ? "no-wrap" : "wrap",
          paddingLeft: isSmUp ? "4rem" : "2rem",
          paddingRight: isSmUp ? "4rem" : "2rem",
        }}
      >
        <StyledProfileCardImage
          component="img"
          src={profileURL || "https://d3a0rs3velfrz.cloudfront.net/logos/almadelicButterFlyTransparentNew.webp"}
          alt={`${name} profile image`}
          sx={{ margin: isLg ? "0" : "auto" }}
        />
        <Stack sx={{ flexGrow: 1, maxWidth: isMd ? "50%" : "100%", alignItems: isLg || isMd ? "" : "center" }} spacing={1.0}>
          <MKTypography sx={{ color: `${theme.palette.light.main} !important` }} component="h1" variant="h6" gutterBottom>
            {name} &nbsp;
          </MKTypography>
          <MKTypography component="p" variant="body2" sx={{ color: `${theme.palette.light.main} !important` }} gutterBottom>
            {specialties?.join(", ")}
          </MKTypography>
          {yearsPracticed && (
            <Stack direction={"row"} alignItems={"center"} spacing={1.4}>
              <TAIconImage alt="Star Icon" size="md" iconURL={"https://d3a0rs3velfrz.cloudfront.net/therapistBio/bioIcons/star.webp"} />
              <MKTypography component="p" variant="body2" sx={{ color: `${theme.palette.light.main} !important` }}>
                {yearsPracticed} Years Exp
              </MKTypography>
            </Stack>
          )}
          <Stack direction={"row"} alignItems={"center"} spacing={1.4}>
            <TAIconImage alt="Pin Icon" size="md" iconURL={"https://d3a0rs3velfrz.cloudfront.net/therapistBio/bioIcons/map-marker.webp"} />
            <MKTypography component="p" variant="body2" sx={{ color: `${theme.palette.light.main} !important` }}>
              {city && `${city.charAt(0).toUpperCase() + city.slice(1)}, `}
              {convertState(state)}
            </MKTypography>
          </Stack>
          {websiteURL && (
            <Stack direction={"row"} alignItems={"center"} spacing={1.4}>
              <TAIconImage alt="World Icon" size="md" iconURL={"https://d3a0rs3velfrz.cloudfront.net/therapistBio/bioIcons/earth-globe.webp"} />
              <StyledEllipsisTypographyLink target="_blank" href={websiteURL} sx={{ fontSize: "14px", color: theme.palette.accent.main }}>
                {websiteURL}
              </StyledEllipsisTypographyLink>
            </Stack>
          )}
          {linkedIn && (
            <Stack direction={"row"} alignItems={"center"} spacing={0.4}>
              <LinkedInIcon
                style={{
                  padding: "0",
                  marginLeft: "-0.1rem",
                  marginRight: "0.4rem",
                  width: "24px",
                  height: "24px",
                  fontSize: "18px",
                  color: theme.palette.accent.main,
                }}
              />
              <StyledEllipsisTypographyLink target="_blank" href={linkedIn} sx={{ fontSize: "14px", color: theme.palette.accent.main }}>
                {linkedIn}
              </StyledEllipsisTypographyLink>
            </Stack>
          )}
        </Stack>
        <ButtonOptions />
      </StyledHeaderContainer>
    </StyledSectionBackgroundWrapper>
  );
};

export default Header;
