import React, { useRef, useState, useEffect } from "react";
// Styled Components
import { StyledPaginationSectionContainer, StyledProfileCardsSectionContainer } from "./index.styles";
// Components
import ProfileCard from "../profileCard";
import { Container } from "@mui/material";
import MKTypography from "components/MKTypography";
import Pagination from "components/CBPagination";
import CBLoading from "components/CBLoading";
// Theme
import theme from "assets/theme";

const ProfileCardContainer = ({ filteredResults, isLoading, isError, error }) => {
  const containerRef = useRef(null);
  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (isLoading) return;
    setCurrentPage(1);
  }, [filteredResults, isLoading]);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const currentData = filteredResults?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <>
      {isError ? (
        <Container sx={{ marginBlock: "4rem" }}>
          <MKTypography component="h3" variant="h3" sx={{ color: `${theme.palette.error.main} !important`, textAlign: "center" }}>
            There was an error fetching the data: {error?.message}. Please try again later
          </MKTypography>
        </Container>
      ) : (
        <>
          {isLoading ? (
            <Container sx={{ marginBlock: "4rem" }}>
              <CBLoading loadingItem={"therapist profiles"} />
            </Container>
          ) : (
            <>
              <StyledProfileCardsSectionContainer ref={containerRef} component="section" id="profile-cards-section">
                {filteredResults?.length === 0 ? (
                  <MKTypography>No results found</MKTypography>
                ) : (
                  currentData?.map((item) => <ProfileCard key={item.id} profileData={item} />)
                )}
              </StyledProfileCardsSectionContainer>
              <StyledPaginationSectionContainer sx={{ display: "flex", justifyContent: "center" }}>
                <Pagination
                  totalItems={filteredResults?.length}
                  itemsPerPage={itemsPerPage}
                  onPageChange={onPageChange}
                  containerRef={containerRef}
                />
              </StyledPaginationSectionContainer>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProfileCardContainer;
