import React from "react";
// Components
import InsuranceCoverage from "./components/insuranceCoverage";
import SchemaComponent from "components/CBSchema";
import PageHeader from "components/CBPageHeader";
import SEO from "components/CBSEO";
import InsuranceFaqContainer from "./components/InsuranceFaqContainer";
// Styled Components
import { StyledMainPageContainer } from "index.styles";
import { StyledSectionBackgroundWrapper } from "index.styles";
// Utils
import { constructSchemaData } from "utils/helpers/constructSchemaData";
// Data
import insuranceOptionsSchemaData from "./schema.organization.json";
import seoData from "./seo.data.json";

const InsuranceOptions = () => {
  const schemaData = constructSchemaData(insuranceOptionsSchemaData.url, insuranceOptionsSchemaData.breadcrumbs);
  return (
    <StyledMainPageContainer id="insurance-options-page">
      <SchemaComponent schemas={schemaData} />
      <SEO title={seoData.title} description={seoData.description} canonicalUrl={seoData.canonicalUrl} />
      <StyledSectionBackgroundWrapper>
        <PageHeader
          header="Insurance Options for \n Ketamine-Assisted Therapy"
          headerAccent="Ketamine-Assisted Therapy"
          lightText="true"
          section="insurance-options"
        />
      </StyledSectionBackgroundWrapper>
      <InsuranceCoverage />
      <InsuranceFaqContainer />
    </StyledMainPageContainer>
  );
};

export default InsuranceOptions;
