// Major
import React, { useState } from "react";

// Components
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import EditForm from "../EditForm";
import CBLoading from "components/CBLoading";

// Styles
import theme from "assets/theme";
import { Stack } from "@mui/material";
import { StyledProfileCardImage, StyledHeaderContainer, StyledEllipsisTypographyLink } from "./index.styles";
import { StyledSectionBackgroundWrapper } from "index.styles";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";

// Utils
import { findCustomFieldValue } from "utils/helpers/findCustomFieldValue";
import { convertState } from "utils/helpers/stateConverter";
import { formatPhoneNumber } from "utils/helpers/reformatPhone";
import useMediaQueries from "utils/mediaQueries.utils";
import usePutTherapistByID from "utils/hooks/usePutTherapistByID";
import useImageUpload from "pages/PartnerProspectSurvey/components/newSurvey/hooks/useUploadImage.hook";
import filterBadWords from "utils/helpers/filterBadWords";

// Icons
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TAIconImage from "components/TAIconImage";

const HeaderEdit = ({ userData, handleUpdateUserData }) => {
  const { isLg, isSmUp, isMd } = useMediaQueries();
  const { email, phone, state, city, name, customFields } = userData || [];
  const websiteURL = findCustomFieldValue("Website", customFields);
  const profileURL = findCustomFieldValue("Profile URL", customFields);
  const yearsPracticed = findCustomFieldValue("Years Practiced", customFields);
  const linkedIn = findCustomFieldValue("LinkedIn", customFields);
  const specialties = findCustomFieldValue("Specialties", customFields);
  const [showForm, setShowForm] = useState(false);
  const { updateContactInfo, data, isLoading, isError, error } = usePutTherapistByID();
  const { uploadImage } = useImageUpload();
  const [imageUploading, setImageUploading] = useState(false);
  // console.log("userData", userData);

  const handleEmailClick = () => {
    if (email) {
      window.location.href = `mailto:${email}`;
    }
  };
  const handlePhoneClick = () => {
    if (phone) {
      window.location.href = `tel:+${phone}`;
    }
  };
  const buttonInfo = [
    {
      iconURL: "https://d3a0rs3velfrz.cloudfront.net/therapistBio/bioIcons/telephone-handle-silhouette.webp",
      alt: "Phone Icon",
      size: "sm",
    },
    {
      iconURL: "https://d3a0rs3velfrz.cloudfront.net/therapistBio/bioIcons/envelope.webp",
      alt: "Email Icon",
      size: "sm",
    },
  ];

  // Mappping through the buttonInfo array to render the buttons
  const ButtonOptions = () => {
    return (
      <Stack direction={isMd ? "column" : isSmUp ? "row" : "column"} spacing={2} sx={{ width: "100%", maxWidth: isLg ? "200px" : "100%" }}>
        {buttonInfo.map((button, index) => {
          return (
            <MKButton
              startIcon={<TAIconImage alt={button.alt} size={button.size} iconURL={button.iconURL} />}
              onClick={index === 0 ? handlePhoneClick : handleEmailClick}
              disabled={index === 0 ? !phone : !email}
              color="secondary"
              variant="outlined"
              sx={{ color: "#fff !important", width: isMd ? "100%" : isSmUp ? "50%" : "100%" }}
            >
              {index === 0 ? formatPhoneNumber(phone) || "Not Available" : "Email Me"}
            </MKButton>
          );
        })}
      </Stack>
    );
  };

  const HeaderBody = () => {
    return (
      <StyledHeaderContainer
        sx={{
          flexDirection: isSmUp ? "row" : "column",
          flexWrap: isMd ? "no-wrap" : "wrap",
          paddingLeft: isSmUp ? "4rem" : "2rem",
          paddingRight: isSmUp ? "4rem" : "2rem",
          paddingBottom: isLg ? "2rem" : "3rem",
        }}
      >
        <StyledProfileCardImage
          component="img"
          src={profileURL || "https://d3a0rs3velfrz.cloudfront.net/logos/almadelicButterFlyTransparentNew.webp"}
          alt={`${name} profile image`}
          sx={{ margin: isLg ? "0" : "auto" }}
        />
        <Stack sx={{ flexGrow: 1, maxWidth: isMd ? "50%" : "100%", alignItems: isLg || isMd ? "" : "center" }} spacing={1.0}>
          <MKTypography sx={{ color: `${theme.palette.light.main} !important` }} component="h1" variant="h6" gutterBottom>
            {name} &nbsp;
          </MKTypography>
          <MKTypography component="p" variant="body2" sx={{ color: `${theme.palette.light.main} !important` }} gutterBottom>
            {specialties?.join(", ")}
          </MKTypography>
          {yearsPracticed && (
            <Stack direction={"row"} alignItems={"center"} spacing={1.4}>
              <TAIconImage alt="Star Icon" size="md" iconURL={"https://d3a0rs3velfrz.cloudfront.net/therapistBio/bioIcons/star.webp"} />
              <MKTypography component="p" variant="body2" sx={{ color: `${theme.palette.light.main} !important` }}>
                {yearsPracticed} Years Exp
              </MKTypography>
            </Stack>
          )}
          <Stack direction={"row"} alignItems={"center"} spacing={1.4}>
            <TAIconImage alt="Pin Icon" size="md" iconURL={"https://d3a0rs3velfrz.cloudfront.net/therapistBio/bioIcons/map-marker.webp"} />
            <MKTypography component="p" variant="body2" sx={{ color: `${theme.palette.light.main} !important` }}>
              {city && `${city.charAt(0).toUpperCase() + city.slice(1)}, `}

              {convertState(state)}
            </MKTypography>
          </Stack>
          {websiteURL && (
            <Stack direction={"row"} alignItems={"center"} spacing={1.4}>
              <TAIconImage alt="World Icon" size="md" iconURL={"https://d3a0rs3velfrz.cloudfront.net/therapistBio/bioIcons/earth-globe.webp"} />
              <StyledEllipsisTypographyLink target="_blank" href={websiteURL} sx={{ fontSize: "14px", color: theme.palette.accent.main }}>
                {websiteURL}
              </StyledEllipsisTypographyLink>
            </Stack>
          )}
          {linkedIn && (
            <Stack direction={"row"} alignItems={"center"} spacing={0.4}>
              <LinkedInIcon
                style={{
                  padding: "0",
                  marginLeft: "-0.1rem",
                  marginRight: "0.4rem",
                  width: "24px",
                  height: "24px",
                  fontSize: "18px",
                  color: theme.palette.accent.main,
                }}
              />
              <StyledEllipsisTypographyLink target="_blank" href={linkedIn} sx={{ fontSize: "14px", color: theme.palette.accent.main }}>
                {linkedIn}
              </StyledEllipsisTypographyLink>
            </Stack>
          )}
        </Stack>
        <ButtonOptions />
        <EditButton showForm={showForm} setShowForm={setShowForm} />
      </StyledHeaderContainer>
    );
  };

  const questions = [
    {
      name: "name",
      answer: name ? name : "",
      placeholder: "Name",
      type: "outline",
      variant: "outlined",
      rows: 1,
    },
    {
      name: "city",
      answer: city ? city : "",
      type: "outline",
      variant: "outlined",
      rows: 1,
    },
    {
      name: "state",
      answer: state ? state : "",
      placeholder: "State",
      type: "select",
      variant: "outlined",
      rows: 1,
      options: [
        { value: "Alabama", label: "Alabama" },
        { value: "Alaska", label: "Alaska" },
        { value: "Arizona", label: "Arizona" },
        { value: "Arkansas", label: "Arkansas" },
        { value: "California", label: "California" },
        { value: "Colorado", label: "Colorado" },
        { value: "Connecticut", label: "Connecticut" },
        { value: "Delaware", label: "Delaware" },
        { value: "Florida", label: "Florida" },
        { value: "Georgia", label: "Georgia" },
        { value: "Hawaii", label: "Hawaii" },
        { value: "Idaho", label: "Idaho" },
        { value: "Illinois", label: "Illinois" },
        { value: "Indiana", label: "Indiana" },
        { value: "Iowa", label: "Iowa" },
        { value: "Kansas", label: "Kansas" },
        { value: "Kentucky", label: "Kentucky" },
        { value: "Louisiana", label: "Louisiana" },
        { value: "Maine", label: "Maine" },
        { value: "Maryland", label: "Maryland" },
        { value: "Massachusetts", label: "Massachusetts" },
        { value: "Michigan", label: "Michigan" },
        { value: "Minnesota", label: "Minnesota" },
        { value: "Mississippi", label: "Mississippi" },
        { value: "Missouri", label: "Missouri" },
        { value: "Montana", label: "Montana" },
        { value: "Nebraska", label: "Nebraska" },
        { value: "Nevada", label: "Nevada" },
        { value: "New Hampshire", label: "New Hampshire" },
        { value: "New Jersey", label: "New Jersey" },
        { value: "New Mexico", label: "New Mexico" },
        { value: "New York", label: "New York" },
        { value: "North Carolina", label: "North Carolina" },
        { value: "North Dakota", label: "North Dakota" },
        { value: "Ohio", label: "Ohio" },
        { value: "Oklahoma", label: "Oklahoma" },
        { value: "Oregon", label: "Oregon" },
        { value: "Pennsylvania", label: "Pennsylvania" },
        { value: "Rhode Island", label: "Rhode Island" },
        { value: "South Carolina", label: "South Carolina" },
        { value: "South Dakota", label: "South Dakota" },
        { value: "Tennessee", label: "Tennessee" },
        { value: "Texas", label: "Texas" },
        { value: "Utah", label: "Utah" },
        { value: "Vermont", label: "Vermont" },
        { value: "Virginia", label: "Virginia" },
        { value: "Washington", label: "Washington" },
        { value: "West Virginia", label: "West Virginia" },
        { value: "Wisconsin", label: "Wisconsin" },
        { value: "Wyoming", label: "Wyoming" },
      ],
    },
    {
      name: "phone",
      answer: phone,
      placeholder: "Phone",
      type: "tel",
      variant: "outlined",
      rows: 1,
    },
    {
      name: "LinkedIn",
      answer: linkedIn ? linkedIn : "",
      placeholder: "LinkedIn",
      type: "outline",
      variant: "outlined",
      rows: 1,
    },
    {
      name: "Website",
      answer: websiteURL ? websiteURL : "",
      placeholder: "Website",
      type: "outline",
      variant: "outlined",
      rows: 1,
    },
    {
      name: "Years Practiced",
      answer: yearsPracticed,
      placeholder: "Years Practiced",
      type: "number",
      variant: "outlined",
      rows: 1,
    },
    {
      name: "Profile Image",
      answer: profileURL,
      placeholder: "Profile Image",
      type: "file",
      variant: "outlined",
      rows: 1,
    },
  ];

  const EditButton = ({ showForm, setShowForm }) => {
    return (
      <MKButton color="secondary" size="small" sx={{ position: "absolute", top: "1.5rem", right: "1.5rem" }} onClick={() => setShowForm(!showForm)}>
        {showForm ? <EditOffIcon sx={{ height: "24px", width: "24px" }} /> : <EditIcon sx={{ height: "24px", width: "24px" }} />}
      </MKButton>
    );
  };

  const handleSubmit = async (values) => {
    let updatedImage = false;
    let imageUrl = "";
    const fileName = values["name"].replace(/\s+/g, "_").toLowerCase() + "_profile_image.jpg";

    // uploading image if it exists
    if (values["profile cropped image"]) {
      setImageUploading(true);
      const uploadResponse = await uploadImage(values["profile cropped image"], fileName);
      if (!uploadResponse) {
        throw new Error("Image upload failed");
      }
      imageUrl = uploadResponse;
      updatedImage = true;
    } else {
      imageUrl = profileURL;
    }
    setImageUploading(false);

    const payload = {
      ...values,
      "Profile URL": imageUrl,
    };

    // filtering out bad words
    const filterPayload = (payload) => {
      const filteredPayload = { ...payload };
      const keysToFilter = ["city", "name"];

      Object.keys(filteredPayload).forEach((key) => {
        if (typeof filteredPayload[key] === "string" && keysToFilter.includes(key)) {
          filteredPayload[key] = filterBadWords(filteredPayload[key]);
        }
      });

      return filteredPayload;
    };

    const filteredPayload = filterPayload(payload);

    const response = await updateContactInfo(filteredPayload, userData.id);
    if (response) {
      handleUpdateUserData(response);
    }
    if (updatedImage) {
      alert("Profile Updated Successfully, Profile Image may take a few minutes to update");
    }
  };

  return (
    <>
      {isLoading || imageUploading ? (
        <CBLoading />
      ) : (
        <>
          <StyledSectionBackgroundWrapper id="therapist-bio-header">
            <HeaderBody />
          </StyledSectionBackgroundWrapper>
          {showForm && <EditForm questions={questions} handleSubmit={handleSubmit} />}
        </>
      )}
    </>
  );
};

export default HeaderEdit;
