// useState;
import React, { useContext } from "react";
import { StyledAddonsSectionContainer } from "./index.styles";
// List, ListItem,
import { Grid, Stack } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import theme from "assets/theme";
// import EllipsisText from "components/CBEllipsisText";
import { StyledAboutKetamineCards } from "index.styles";
import MKButton from "components/MKButton";
// import { StyledButtonContainer } from "index.styles";
import { useLocation, useNavigate } from "react-router-dom";
import { CartContext } from "utils/context/cart.context";
import useMediaQueries from "utils/mediaQueries.utils";
import { scrollToTop } from "utils/helpers/scrollToTop";
import { formatTitleForURL } from "utils/helpers/formatTitleForUrl";

const Addons = ({ header, headerAccent, addons }) => {
  // const addonsData = [additionalSessionsAddOnData, clinicalTherapyAddOnData, guidedSessionsAddOnData, integrationAddOnData];
  // const [showMore, setShowMore] = useState({});
  const { isLg } = useMediaQueries();
  const navigate = useNavigate();
  const { addItemToCart } = useContext(CartContext);
  const location = useLocation();

  // const handleToggle = (index) => {
  //   setShowMore((prevShowMore) => ({
  //     ...prevShowMore,
  //     [index]: !prevShowMore[index],
  //   }));
  // };

  const handleNavigateTo = (e, name) => {
    navigate(`/ketamine-therapy/packages/${formatTitleForURL(name)}`, { state: { productId: e.currentTarget.id } });
    scrollToTop();
  };

  const handleAddToCart = (e, index) => {
    e.stopPropagation();
    addItemToCart(addons[index]);
    // navigate("/cart");
    navigate("/cart", { state: { previousUrl: `${location.pathname}`, productId: e.currentTarget.id } });
  };

  return (
    <StyledAddonsSectionContainer>
      <MKTypography component="h2" variant="h2" gutterBottom mb="2rem">
        {header ? (
          <>
            {header}
            <span style={{ color: theme.palette.accent.main }}> {headerAccent}</span>
          </>
        ) : (
          <>
            <span style={{ color: theme.palette.accent.main }}>Customized Plans </span> With Our Add-ons
          </>
        )}
      </MKTypography>
      <Grid container spacing={4}>
        {/* description, */}
        {addons?.map(({ name, price, images, id }, index) => (
          <Grid key={index} item xs={12} lg={6}>
            <StyledAboutKetamineCards sx={{ padding: "1rem 1.5rem !important" }} onClick={(e) => handleNavigateTo(e, name)} id={id}>
              {/* Image */}
              <Stack direction={"row"} spacing={4}>
                <MKBox component="img" src={images[0]} sx={{ width: isLg ? "125px" : "100px", objectFit: "cover" }}></MKBox>
                {/* Content */}
                <Stack direction={"column"} mb="1rem">
                  <MKTypography component="h6" variant="h6" gutterBottom>
                    {name}
                  </MKTypography>
                  <MKTypography component="small" variant="body2" sx={{ color: theme.palette.accent.main, fontWeight: "bold" }}>
                    ${(price / 100).toFixed(2)}
                  </MKTypography>
                  {/* <StyledButtonContainer> */}
                  <MKButton id={id} onClick={(e) => handleAddToCart(e, index)} size="small" color="secondary" sx={{ width: "125px", mt: "1rem" }}>
                    Add To Cart
                  </MKButton>
                  {/* </StyledButtonContainer> */}
                </Stack>
              </Stack>
              {/* <List component="p" variant="body2">
                  {description?.slice(0, showMore[index] ? undefined : 1).map((item, itemIndex) => (
                    <ListItem key={itemIndex}>
                      <Stack direction="row" spacing={2} sx={{ minHeight: "76.78px" }}>
                        <MKBox
                          component="img"
                          sx={{ width: "18px", height: "14px", marginTop: ".3rem !important" }}
                          src="https://shop.almadelic.com/wp-content/uploads/2024/03/wbsite-icon.png"
                          alt="Almadelic butterfly bullet point icon"
                        />
                        {description.length > 1 ? (
                          <EllipsisText
                            text={item}
                            productCards={"true"}
                            onReadMore={() => handleToggle(index)}
                            showMore={showMore[index]}
                            index={itemIndex}
                            arrayLength={description.length}
                          ></EllipsisText>
                        ) : (
                          <MKTypography component="p" variant="body2">
                            {item}
                          </MKTypography>
                        )}
                      </Stack>
                    </ListItem>
                  ))}
                </List> */}
              {/* </Stack> */}
              {/* Button */}
            </StyledAboutKetamineCards>
          </Grid>
        ))}
      </Grid>
    </StyledAddonsSectionContainer>
  );
};

export default Addons;
