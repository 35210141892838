import React from "react";
import { useTheme } from "@mui/material/styles";

import { StyledHeroImage, StyledHeroImageGridItem, StyledHeroSectionContainer, StyledHeroTextGridItem } from "./index.styles";

import useMediaQueries from "utils/mediaQueries.utils";

const HeroTreeImageComponent = () => {
    const theme = useTheme();
    const { isMd, isLg } = useMediaQueries();

    return(
        <StyledHeroImageGridItem item order={isMd ? "1" : "1"} xs={12} md={1} lg={5}>
          <StyledHeroImage
            component={"img"}
            height={1}
            width={1}
            src={"https://d3a0rs3velfrz.cloudfront.net/home/hero/HeroImgSm.webp"}
            alt="Almadelic Home Page Image - an colorful abstract portrait of a womans face depicted as a tree."
          />
        </StyledHeroImageGridItem>
    )
}

export default HeroTreeImageComponent;